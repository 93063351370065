
<template>
  <SingleKavel />
</template>

<script>
import SingleKavel from "@/components/kavels/SingleKavel";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    SingleKavel,
  },
  metaInfo: {
    title: "Kavels bewerken | Dashboard",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>