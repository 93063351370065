<template>
  <v-container fluid>
    <div data-v-step="1">
      <!-- Inhoud voor stap 1 -->
    </div>
    <div style="margin-bottom: 5px; display: flex; margin: 4px">
      <OrderTour />
    </div>
    <div>
      <v-row>
        <v-col>
          <v-card class="pa-4">
            <v-card-title>Filter mogelijkheden:</v-card-title>
            <v-row>
              <v-col cols="2">
                <div data-v-step="2">
                  <v-autocomplete
                    v-model="filter"
                    label="Filter op status"
                    :items="filterOptions"
                    multiple
                    dense
                    clearable
                    outlined
                    class="mb-3"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col cols="3">
                <div data-v-step="3">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Opzoeken"
                    hide-details
                    dense
                    outlined
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="2">
                <div data-v-step="4">
                  <v-text-field
                    v-model="searchKavelnummer"
                    append-icon="mdi-magnify"
                    label="Kavelnummer"
                    hide-details
                    dense
                    outlined
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="3">
                <div data-v-step="5">
                  <v-text-field
                    v-model="searchDate"
                    label="Zoek op datum (DD/MM/YYYY)"
                    hide-details
                    dense
                    outlined
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="2">
                <div data-v-step="6">
                  <v-btn color="primary" @click="resetFilters">
                    Herstel filters
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              Bestellingen
              <v-spacer></v-spacer>
            </v-card-title>
            <div class="table-container">
              <v-data-table
                :headers="headers"
                :items="filteredOrders"
                :search="search"
                disable-pagination
                :hide-default-footer="true"
                class="elevation-1"
              >
                <template v-slot:[`item.price`]="{ item }">
                  € {{ item.price }}
                </template>
                <template v-slot:[`item.Actions`]="{ item }">
                  <v-icon small color="green" @click="openOrder(item.id)">
                    mdi-eye
                  </v-icon>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import apiOrders from "../api/apiOrders";
import moment from "moment";
import OrderTour from "../tour/OrderTour.vue";

export default {
  components: {
    OrderTour,
  },
  data() {
    return {
      filterOptions: [
        "Afgehandeld",
        "Verzonden",
        "Nog niet verwerkt",
        "Verkocht",
        "Nog niet betaald maar wel verzonden",
      ],
      filter: [],
      orders: [],
      search: "",
      searchKavelnummer: "",
      searchDate: "",
      headers: [
        { text: "Bestelnummer", value: "orderNumber", sortable: true },
        { text: "Klant", value: "name", sortable: true },
        { text: "Postcode", value: "postCode", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Betaalstatus", value: "betaalStatus", sortable: true },
        { text: "Bedrag", value: "price", sortable: true },
        { text: "Besteldatum", value: "createDate", sortable: true },
        { text: "Acties", value: "Actions", sortable: false },
      ],
    };
  },
  computed: {
    filteredOrders() {
      return this.orders.filter((order) => {
        const statusMatches =
          this.filter.length === 0 || this.filter.includes(order.status);
        const searchMatches =
          !this.search ||
          Object.values(order).some((val) =>
            String(val).toLowerCase().includes(this.search.toLowerCase())
          );
        const kavelnummerMatches =
          !this.searchKavelnummer ||
          order.orderItems.some((item) =>
            String(item.kavelNumber)
              .toLowerCase()
              .includes(this.searchKavelnummer.toLowerCase())
          );
        const dateMatches =
          !this.searchDate ||
          moment(order.createDate, "DD/MM/YYYY").isSameOrBefore(
            moment(this.searchDate, "DD/MM/YYYY")
          );
        return (
          statusMatches && searchMatches && kavelnummerMatches && dateMatches
        );
      });
    },
  },
  async mounted() {
    apiOrders.getorder().then((data) => {
      this.orders = data;
    });
  },
  methods: {
    openOrder(id) {
      this.$router.push(`/Bestelling/bekijken/${id}`);
    },
    resetFilters() {
      this.filter = [];
      this.search = "";
      this.searchKavelnummer = "";
      this.searchDate = "";
    },
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.flex-item {
  margin-right: 10px; /* Laatste item heeft geen margin nodig */
}

.flex-item:last-child {
  margin-right: 0;
}
</style>
