import { API } from "./api.js";
export default {
  async createAndDownload(data) {
    try {
      const response = await API.post("/api/rest/v2/invoice/controller", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async downloadInvoice(orderNumber) {
    try {
      const response = await API.get(`/api/rest/v2/invoice/controller/${orderNumber}`, {
        responseType: "blob",
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
