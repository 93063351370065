
export const printTable = (id) => {
  const frame = window.frames["print_frame"];
  frame.document.body.innerHTML = document.getElementById(id).innerHTML;

  // const head = window.frames["print_frame"].document.head;
  // const links = [...document.getElementsByTagName("link")];
  // const styles = [...document.getElementsByTagName("style")];
  // [...links, ...styles].forEach(link => head.append(link));
  // head.lastChild.addEventListener(
  //   "load",
  //   function(_) {
  // In IE, you have to focus() the Iframe prior to printing
  // or else the top-level page will print instead
  frame.document.body.innerHTML += `
  <style>
    @media print {
      .table-header-group {
        display: table-header-group;
      }
      table {
        width: 100%;
      }
      body { margin: 0; padding: 0; }
      th, td {
        padding: 0.5rem; text-align: left; 
      }
      th { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
      td { border-bottom: 1px black solid; border-right: 1px black solid;}
      td:last-child { border-right: none; }
    }
  </style>
`;
  frame.window.focus();
  frame.window.print();
  //   },
  //   0
  // );
};
