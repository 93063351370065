<template>
  <v-container fluid>
    
    
    <div class="row">
    

      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div class="link-box">
            <h2>Meerdere handelaren rapport maken</h2>
            <p class="link-box-title">Uitdraai van handelar(en) en status</p>
            <p class="link-box-subtitle">
              Maak een selectie van de mogelijkheden en draai een rapport uit.
              De begin en einddatum betreft de aanmaak datum van de kavel
            </p>
            <v-chip class="ma-2" color="brown" outlined> Handelaar </v-chip>
            <div style="display: flex">
              <MultipleTradersReport />
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import MultipleTradersReport from "../reportKavels/MultipleTradersReport.vue";

export default {
  components: {


    MultipleTradersReport,
  },
  data() {
    return {};
  },
  methods: {
    openInstructions() {
      this.$router.push("/instructies");
    },
  },
};
</script>

<style scoped>
.router-link {
  color: black;
  text-decoration: none;
}
.link-box {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 5px 8px 0 rgba(0, 0, 0, 0.05);
  padding: 20px;
  font-size: 16px;
  text-align: center;
  border-top: 2px solid #90caf9;

  /* min-height:100%; */
}
.link-box p {
  margin: 0px 10px 10px 0px;
}
.link-box img {
  height: 40px;
  margin-bottom: 10px;
}
.link-box-title {
  color: black;
  font-size: 16px;
}
.link-box-subtitle {
  color: rgb(63, 63, 63);
  font-size: 14px;
  margin-bottom: 10px;
}
h2 {
  font-size: 18px;
  font-weight: bold;
}
</style>
