
<template>
  <SingleCustomer />
</template>

<script>
import SingleCustomer from "@/components/customers/SingleCustomer";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    SingleCustomer,
  },
  metaInfo: {
 title: "Klant bewerken",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>