import { API } from './api.js'
export default {
  async createAsset(data) {
    try {
      const response = await API.post("/api/rest/v2/kavelfolder", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getDirectories() {
    try {
      const response = await API.get("/api/rest/v2/kavelfolder");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getDirectoriesFiles(folderName) {
    try {
      const response = await API.get(`/api/rest/v2/kavelfolder?location=${folderName}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async downloadDocument(subfolder, filename) {
    try {
      const response = await API.get(`/api/rest/v2/kavelfolder/${subfolder}/${filename}`, {
        responseType: 'blob',
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async delete (subfolder, filename) {
    try {
      const response = await API.delete(`/api/rest/v2/kavelfolder/${subfolder}/${filename}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async fetchImages(folderName) {
    try {
      const response = await API.get(
        `/api/rest/v2/kavelfolder/?location=${folderName}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
}