
<template>
    <AddKavel />
  </template>
  
  <script>
  import AddKavel from "@/components/kavels/AddKavel";
  export default {
    data() {
      return {
        data: [],
      };
    },
    components: {
        AddKavel,
    },
    metaInfo: {
      title: "Kavels toevoegen | Dashboard",
      htmlAttrs: {
        lang: "EN",
        amp: true,
      },
    },
  };
  </script>