<template>
  <v-container>
    <v-btn @click="testCron()">Voorbeeld rapport ontvangen></v-btn>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <span class="headline">
              Rapport aanmaken die automatisch verstuurd moet worden (gratis versie)
            </span>
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="addReport">
              <v-text-field
                v-model="report.name"
                label="Naam rapport"
                required
              ></v-text-field>
              <v-autocomplete
                dense
                :items="traders"
                label="Handelaar selecteren"
                item-text="name"
                item-value="id"
                v-model="report.trader"
              ></v-autocomplete>
              <v-autocomplete
                dense
                :items="statussen"
                label="Status selecteren"
                v-model="report.status"
                item-text="name"
                item-value="id"
              ></v-autocomplete>
              <v-autocomplete
                dense
                :items="embcodes"
                label="Embcode selecteren"
                v-model="report.embcode"
                item-text="nameNL"
                item-value="embNed"
              ></v-autocomplete>
              <v-select
                label="Wanneer wil je het rapport ontvangen"
                :items="receivedOptions"
                v-model="report.daypart"
              ></v-select>
              <v-select
                label="Van wanneer wilt u informatie ontvangen"
                :items="dateRangeOptions"
                v-model="report.dateRange"
              ></v-select>
              <v-text-field
                v-model="report.email"
                label="Op welke email wilt u het rapport ontvangen?"
                required
              ></v-text-field>
              
                <v-btn color="primary" type="submit">Aanmaken</v-btn>
                <v-btn  style="margin-left:2px" color="warning" @click="resetForm">Reset</v-btn>
      
              
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title> Aangemaakte rapporten </v-card-title>
          <v-card-text>
            <v-expansion-panels>
              <v-expansion-panel v-for="report in reports" :key="report.id">
                <v-expansion-panel-header>
                  {{ report.name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Handelaar:</v-list-item-title>
                        <v-list-item-subtitle>{{
                          report.trader
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Status:</v-list-item-title>
                        <v-list-item-subtitle>{{
                          report.status
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>EMB Code:</v-list-item-title>
                        <v-list-item-subtitle>{{
                          report.embCode || "N/A"
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Email:</v-list-item-title>
                        <v-list-item-subtitle>{{
                          report.email || "N/A"
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Dagdeel:</v-list-item-title>
                        <v-list-item-subtitle>{{
                          report.daypart
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Aanmaakdatum:</v-list-item-title>
                        <v-list-item-subtitle>{{
                          new Date(report.createDate).toLocaleString()
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiGenerateReport from "../api/apiGenerateReport";
import apiType from "../api/apiEmbCode";
import apiStatus from "../api/apiStatus";
import apiTrader from "../api/apiTrader";

export default {
  data() {
    return {
      traders: [],
      embcodes: [],
      statussen: [],
      reports: [],
      receivedOptions: ["daily", "weekly", "monthly"],
      dateRangeOptions: ["previousMonth", "currentMonth", "lastSixMonths"],
      report: {
        name: "",
        trader: "",
        status: "",
        embcode: "",
        email: "",
        daypart: "",
        dateRange: "",
      },
    };
  },
  mounted() {
    this.fetchEmbcodes();
    this.fetchKavelStatus();
    this.fetchTraders();
    this.fetchReports();
  },
  methods: {
    async testCron() {
      await apiGenerateReport.testCron();
    },
    async fetchEmbcodes() {
      try {
        const data = await apiType.getTypes();
        this.embcodes = data;
      } catch (error) {
        console.log("Foutmelding: " + error);
      }
    },
    async fetchReports() {
      try {
        const data = await apiGenerateReport.getReports();
        this.reports = data;
      } catch (error) {
        console.log("Foutmelding: " + error);
      }
    },
    async fetchKavelStatus() {
      try {
        const data = await apiStatus.getStatus();
        this.statussen = data;
      } catch (error) {
        console.error("Error fetching statuses:", error);
      }
    },
    async fetchTraders() {
      try {
        const data = await apiTrader.getTraders();
        this.traders = data;
      } catch (error) {
        console.error("Error fetching traders:", error);
      }
    },
    async addReport() {
      try {
        await apiGenerateReport.createReport(this.report);
        this.$swal.fire({
          title: "Yes!",
          html: `Het aanmaken is gelukt!`,
          icon: "success",
          confirmButtonText: "Sluiten",
        });
        this.resetForm();
        this.fetchReports();
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
    resetForm() {
      this.report = {
        name: "",
        trader: "",
        status: "",
        embcode: "",
        email: "",
        daypart: "",
        dateRange: "",
      };
    },
  },
};
</script>

<style scoped>
.headline {
  font-weight: bold;
}
</style>
