<template>
  <v-container style="padding-top: 2rem">
    <v-row>
      <v-text-field
        class="custom-search-bar"
        v-model="search"
        append-icon="mdi-magnify"
        label="Zoeken op kavelnummer en koopjes"
        hide-details
      ></v-text-field>
    </v-row>
    <v-row>
      <v-col
        sm="5"
        lg="3"
        md="3"
        class="d-flex flex-column"
        v-for="item in filteredItems"
        :key="item.kavelnr"
      >
        <v-card rounded class="pb-3 flex d-flex flex-column">
          <v-card-title style="font-size: 14px"
            >Kavelnummer {{ item.kavelnr }}<br />
            <template v-if="!item.promotionPrice">
              Bedrag € {{ item.price }} <br />
            </template>
            <template v-else>
              Bedrag € {{ item.promotionPrice }}
              <span class="strike">{{ item.price }}</span> <br />
            </template>
            <span style="color: red" v-if="item.name && item.name !== 'null'">
              {{ item.name }}
            </span>
          </v-card-title>

          <v-card-actions>
            <v-icon class="btn" color="white" @click="addToCart(item)"
              >mdi-cart-plus</v-icon
            >

            <v-dialog transition="dialog-bottom-transition" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="btn"
                  color="white"
                  v-bind="attrs"
                  v-on="on"
                  @click="openSingleKavel(item.kavelnr)"
                  >mdi-pencil</v-icon
                >
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="primary" dark>Bedrag aanpassen</v-toolbar>
                  <v-card-text>
                    <div class="text-h2 pa-12">
                      <v-text-field
                        label="Huidig bedrag"
                        outlined
                        prefix="Euro"
                        v-model="item.price"
                        disabled
                      >
                      </v-text-field>
                      <v-text-field
                        v-model="updatedPrice"
                        outlined
                        type="number"
                        label="Nieuw hier invoeren"
                      >
                      </v-text-field>
                    </div>
                  </v-card-text>

                  <v-card-actions class="justify-end">
                    <v-btn
                      color="primary"
                      @click="updateKavel(selectedItem.kavelnr, updatedPrice)"
                      >Bijwerken</v-btn
                    >
                    <v-btn color="red" text @click="dialog.value = false"
                      >Afsluiten</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiKavel from "../api/apiKavel";
export default {
  data() {
    return {
      search: "",
      cartItems: [],
      items: [],
      updatedPrice: "",
      item: [],
      selectedItem: null,
    };
  },
  computed: {
    filteredItems() {
      if (!this.search) {
        return this.items;
      }

      const searchText = this.search.toLowerCase();
      return this.items.filter((item) => {
        return (
          item.kavelnr?.toString().toLowerCase().includes(searchText) ||
          item.name?.toLowerCase().includes(searchText)
        );
      });
    },
  },
  async mounted() {
    this.fetchKassaKavels();
  },

  methods: {
    fetchKassaKavels() {
      apiKavel.getKassaKavels().then((data) => {
        const updatedData = data.map((item) => ({
          ...item,
          kavelnr: String(item.kavelnr),
        }));
        this.items = updatedData;
      });
    },
    openSingleKavel(kavelnr) {
      apiKavel.getSingleKavel(kavelnr).then((data) => {
        this.selectedItem = data;
      });
    },
    updateItem(updatedItem) {
      const index = this.items.findIndex(
        (item) => item.kavelnr === updatedItem.kavelnr
      );
      if (index !== -1) {
        this.items.splice(index, 1, updatedItem);
      }
    },
    addToCart(item) {
      this.$emit("add-to-cart", item);
    },
    async updateKavel(kavelnr, price) {
      if (!this.selectedItem) {
        console.error("selectedItem is undefined");
        this.$swal.fire("Foutmelding!", "Geen kavel geselecteerd.", "error");
        return;
      }
      try {
        const response = await apiKavel.updateKavel(kavelnr, { price: price });
        console.log(response);
        this.fetchKassaKavels();
        this.$swal.fire("Gelukt!", "Kavel is bijgewerkt!", "success");
      } catch (error) {
        console.error("Fout bij het bijwerken van het kavel:", error);
        this.$swal.fire(
          "Foutmelding!",
          "Het bijwerken is niet gelukt!",
          "error"
        );
      }
    },
  },
};
</script>

<style scoped>
.btn {
  background-color: #007bff;
  margin: 2px;
  float: right;
}
.strike {
  text-decoration: line-through;
}
.custom-search-bar {
  font-size: 1.3rem;
  border-radius: 8px;
  transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out;
  border: 2px solid #1976d2;
}

.custom-search-bar:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.custom-search-bar  .v-input__control {
  display: flex;
  justify-content: center;
}

.custom-search-bar  .v-label {
  color: #1976d2;
  font-size: 1.2rem;
  text-align: center;
  width: 100%;
}

.custom-search-bar  .v-text-field__slot {
  text-align: center;
}

.custom-search-bar input {
  font-size: 1.2rem;
  text-align: center;
}
</style>
