<template>
  <v-container>
    <v-card>
      <v-card-title>
        Handelaar totaal bedrag per status overzicht
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="exportToPdf">Exporteer naar PDF</v-btn>
      </v-card-title>
      <v-card-text ref="pdfContent">
        <v-row
          v-for="(trader, index) in groupedReport"
          :key="index"
          class="mb-4"
        >
          <v-col cols="12">
            <h5>Handelaarnaam: {{ trader.traderName }}</h5>
          </v-col>
          <v-col
            v-for="status in trader.statuses"
            :key="status.statusName"
            cols="12"
            md="6"
          >
            <strong>{{ status.statusName }}</strong
            >:
            <span>{{ formatCurrency(status.totalAmount) }}</span>
          </v-col>
          <v-divider></v-divider>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import apiReport from "../api/apiReport";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default {
  data() {
    return {
      report: [],
    };
  },
  computed: {
    groupedReport() {
      const grouped = this.report.reduce((acc, item) => {
        const trader = acc.find((t) => t.traderName === item.traderName);
        if (trader) {
          trader.statuses.push({
            statusName: item.statusName,
            totalAmount: item.totalAmount,
          });
        } else {
          acc.push({
            traderName: item.traderName,
            statuses: [
              { statusName: item.statusName, totalAmount: item.totalAmount },
            ],
          });
        }
        return acc;
      }, []);
      return grouped;
    },
  },
  mounted() {
    this.fetchReport();
  },
  methods: {
    async fetchReport() {
      try {
        const response = await apiReport.traderReportCost();
        this.report = response.report;
        console.log(this.report); // Controleer de gegevens in de console
      } catch (error) {
        console.error("Error fetching report:", error);
      }
    },
    formatCurrency(value) {
      return new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      }).format(value);
    },
    async exportToPdf() {
      const pdfContent = this.$refs.pdfContent;
      const canvas = await html2canvas(pdfContent, { scale: 2 });
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "landscape",
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("trader-report.pdf");
    },
  },
};
</script>

<style scoped>
.v-row {
  margin-bottom: 20px;
}
h3 {
  margin-bottom: 10px;
}
</style>
