<template>
  <v-dialog
    transition="dialog-top-transition"
    max-width="600"
    v-model="kavelPriceDialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        rounded
        color="primary"
        block
        v-bind="attrs"
        v-on="on"
        style="margin-left: 5px"
      >
        Kavelprijs selectie rapport
      </v-btn>
    </template>
    <template v-slot:default="kavelPriceDialog">
      <v-card>
        <v-toolbar color="primary" dark style="justify-content: center">
          Start en eind kavelnummers
        </v-toolbar>
        <v-card-text>
          <p style="color: red">
            Hier kun je prijzen tussen bepaalde bedragen selecteren
          </p>
          <v-col cols="12">
            <p><b>Selecteer een handelaar</b></p>
            <v-autocomplete
              dense
              outlined
              :items="traders"
              label="Handelaar selecteren"
              item-text="name"
              item-value="id"
              v-model="trader"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <label> <b> Kavel Status</b> </label>
            <v-autocomplete
              dense
              outlined
              :items="statussen.flat()"
              label="Status selecteren"
              v-model="status"
              item-text="name"
              item-value="id"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-text-field
              dense
              type="number"
              v-model="startPrice"
              label="Start bedrag"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              dense
              type="number"
              v-model="endPrice"
              label="Eind bedrag"
            ></v-text-field>
          </v-col>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="generateRangeReport()"
            >Rapport uitdraaien</v-btn
          >
          <v-btn text @click="kavelPriceDialog.value = false">Afsluiten</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import apiTrader from "../api/apiTrader";
import apiStatus from "../api/apiStatus";
import jsPDF from "jspdf";
import apiKavel from "../api/apiKavel";
export default {
  data() {
    return {
      startPrice: null,
      endPrice: null,
      status: null,
      statussen: [],
      kavelPriceDialog: false,
      totalPrice: 0,
      traders: [],
      trader: null,
    };
  },

  mounted() {
    this.fetchKavelStatus();
    this.fetchTraders();
  },

  methods: {
    async fetchTraders() {
      try {
        const data = await apiTrader.getTraders();
        this.traders = data;
      } catch (error) {
        console.error("Error fetching traders:", error);
      }
    },
    async fetchKavelStatus() {
      try {
        const data = await apiStatus.getStatus();
        this.statussen = data;
      } catch (error) {
        console.error("Error fetching statuses:", error);
      }
    },
    resetInfo() {
      this.startPrice = "";
      this.endPrice = "";
      this.totalPrice = 0;
    },

    formatDate(dateString) {
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },

    async generateRangeReport() {
      const data = {
        startPrice: this.startPrice,
        endPrice: this.endPrice,
        traderId: this.trader,
        statusId: this.status,
      };
      try {
        const response = await apiKavel.generateKavelPriceRangeReport(data);
        if (response && Array.isArray(response.kavelResponse)) {
          const kavelsData = [...response.kavelResponse];
          kavelsData.push({
            kavelnr: "Totaal",
            inhoudNL: "",
            embNed: "",
            landName: "",
            statusName: "",
            kenmerk:"",
            price: response.totalPrice,
            createDate: "",
          });

          console.log("kavelsData", kavelsData);

          const doc = new jsPDF('landscape');
          const currentDate = new Date().toLocaleDateString();

          // Add report title and date
          doc.setFontSize(18);
          doc.text("Rapport kavelprijs", 14, 16);
          doc.setFontSize(12);
          doc.text(`Datum uitdraaien: ${currentDate}`, 14, 23);
          doc.text(`Totaalbedrag: €${response.totalPrice}`, 14, 30);

          // Add the table
          doc.autoTable({
            startY: 40,
            head: [
              [
                "Kavel",
                "Inhoud",
                "Embcode",
                "Land",
                "Status",
                "kenmerk",
                "Prijs",
                "Create Date",
              ],
            ],
            body: kavelsData.map((item) => [
              item.kavelnr,
              item.inhoudNL,
              item.embNed,
              item.landName,
              item.statusName,
              item.kenmerk,
              item.price,
              this.formatDate(item.createDate),
            ]),
          });

          const reportFilename = `Embcode-${new Date().toLocaleDateString()}.pdf`;
          doc.save(reportFilename);
        } else {
          console.error("Received data is not properly formatted", response);
        }
      } catch (error) {
        console.error("Error generating the report:", error);
      }
    },
  },
};
</script>
