
<template>
  <Inhoud />
</template>

<script>
import Inhoud from "@/components/instellingen/Inhoud";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Inhoud,
  },
  metaInfo: {
    title: "Inhoud  | Postzegels ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>