<template>
  <v-container>
    <v-card>
      <v-card-title>
        Handelaar totaal bedrag per status overzicht
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="exportToPdf">Exporteer naar PDF</v-btn>
      </v-card-title>
      <v-card-text ref="pdfContent">
        <v-data-table
          :headers="headers"
          :items="groupedReport"
          class="elevation-1"
        >
          <template v-slot:item.traderName="{ item }">
            {{ item.traderName }}
          </template>
          <template v-slot:item.totalSoldAmount="{ item }">
            {{ formatCurrency(item.totalSoldAmount) }}
          </template>
          <template v-slot:item.totalNotSoldAmount="{ item }">
            {{ formatCurrency(item.totalNotSoldAmount) }}
          </template>
          <template v-slot:item.totalSoldPurchasePrice="{ item }">
            {{ formatCurrency(item.totalSoldPurchasePrice) }}
          </template>
          <template v-slot:item.totalNotSoldPurchasePrice="{ item }">
            {{ formatCurrency(item.totalNotSoldPurchasePrice) }}
          </template>
          <template v-slot:item.totalProfit="{ item }">
            {{ formatCurrency(item.totalProfit) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>


<script>
import apiReport from "../api/apiReport";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default {
  data() {
    return {
      report: [],
      headers: [
        { text: "Handelaar Naam", value: "traderName" },
        { text: "Verkocht Bedrag", value: "totalSoldAmount" },
        { text: "Niet Verkocht Bedrag", value: "totalNotSoldAmount" },
        { text: "Verkocht Inkoopprijs", value: "totalSoldPurchasePrice" },
        { text: "Niet Verkocht Inkoopprijs", value: "totalNotSoldPurchasePrice" },
        { text: "Winst", value: "totalProfit" }, // Nieuwe kolom voor winst
      ],
    };
  },
  computed: {
    groupedReport() {
      return this.report.map((item) => ({
        traderName: `${item.traderFirstName} ${item.traderName}`,
        totalSoldAmount: item.totalSoldAmount,
        totalNotSoldAmount: item.totalNotSoldAmount,
        totalSoldPurchasePrice: item.totalSoldPurchasePrice,
        totalNotSoldPurchasePrice: item.totalNotSoldPurchasePrice,
        totalProfit: item.totalProfit, // Winst toevoegen aan het rapport
      }));
    },
  },
  mounted() {
    this.fetchReport();
  },
  methods: {
    async fetchReport() {
      try {
        const response = await apiReport.traderTableReportCost();
        this.report = response.report;
        console.log(this.report); // Controleer de gegevens in de console
      } catch (error) {
        console.error("Error fetching report:", error);
      }
    },
    formatCurrency(value) {
      return new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      }).format(value);
    },
    async exportToPdf() {
      const pdfContent = this.$refs.pdfContent;
      const canvas = await html2canvas(pdfContent, { scale: 2 });
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "landscape",
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("trader-report.pdf");
    },
  },
};
</script>
