<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-container fluid>
            <v-card>
              <v-card-title>
                Klanten
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Opzoeken"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-row>
                <v-col v-for="letter in letters" :key="letter" cols="auto">
                  <v-btn
                    :color="selectedLetter === letter ? 'primary' : ''"
                    @click="selectLetter(letter)"
                  >
                    {{ letter }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-container fluid>
                <v-row>
                  <v-col
                    v-for="customer in filteredCustomers"
                    :key="customer.id"
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-card>
                      <v-card-title>{{ customer.name }}</v-card-title>
                      <v-card-text>
                        <div>Title: {{ customer.title }}</div>
                        <div>Initialen: {{ customer.initials }}</div>
                        <div>Naam: {{ customer.name }}</div>
                        <div>Email: {{ customer.email }}</div>
                        <div>Telefoonnummer: {{ customer.phoneNumber }}</div>
                        <div>Bankrekening: {{ customer.bankNumber }}</div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import apiCustomer from "../api/apiCustomer";

function debounce(func, delay) {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
}

export default {
  data() {
    return {
      page: 1,
      totalPages: 1,
      customers: [],
      search: "",
      selectedLetter: "",
      letters: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
    };
  },

  async mounted() {
    await this.getCustomers(this.page);
    await this.getPageNumber();
  },

  computed: {
    filteredCustomers() {
      if (this.selectedLetter) {
        return this.customers.filter((customer) => {
          return customer.name.charAt(0).toUpperCase() === this.selectedLetter;
        });
      }
      return this.customers;
    },
  },

  watch: {
    search(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.queryHandler();
      }
    },
  },

  methods: {
    async openCustomer(id) {
      this.$router.push(`/customer/bewerken/${id}`);
    },

    async deleteCustomer(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze klant wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiCustomer.deleteCustomer(id);
        await this.getCustomers(this.page);
        this.$swal.fire(
          "Verwijderd",
          "De klant is succesvol verwijderd",
          "success"
        );
      }
    },

    queryHandler: debounce(function () {
      this.page = 1;
      this.getCustomers(1);
    }, 500),

    async getCustomers(pageNumber) {
      const data = await apiCustomer.getCustomers(pageNumber, this.search);
      this.customers = data.customers;
      this.totalPages = data.totalPages;
    },

    getPageNumber() {
      // Define your implementation of the getPageNumber method here
    },

    getName(name) {
      return name;
    },

    getEmail(email) {
      return email;
    },

    selectLetter(letter) {
      this.selectedLetter = letter;
    },
  },
};
</script>
