import { API } from "./api.js";

export default {
  async createState(data) {
    try {
      const response = await API.post("/api/rest/v2/state", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getStates() {
    try {
      const response = await API.get("/api/rest/v2/state");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteState(id) {
    try {
      const response = await API.delete(`/api/rest/v2/state/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateState(id, form) {
    try {
      const response = await API.put(`/api/rest/v2/state/${id}`, form);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getSingleState(id) {
    try {
      const response = await API.get(`/api/rest/v2/state/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
