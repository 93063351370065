<template>
  <v-container fluid style="padding-top: 4rem">
    <div>
      <v-row>
        <v-col cols="8">
          <v-card>
            <v-card-title>
              Verzendkostentabel
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Opzoeken"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <div class="table-container">
              <v-data-table
                :headers="headers"
                :items="shippingcost"
                :search="search"
                disable-pagination
                :hide-default-footer="true"
                class="elevation-1"
              >
                <template v-slot:item.amount="{ item }">
                  € {{ item.amount }}
                </template>
                <template v-slot:[`item.Actions`]="{ item }">
                  <v-icon small color="blue" @click="openEditDialog(item)">
                    mdi-pencil
                  </v-icon>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <!-- Edit Dialog -->
      <v-dialog v-model="editDialog" max-width="500px">
        <v-card>
          <v-card-title>Update Verzendkosten</v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-text-field
                v-model="form.name"
                label="Naam"
                readonly
              ></v-text-field>
              <v-text-field
                v-model="form.amount"
                label="Bedrag (€)"
                type="number"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="editDialog = false">
              Annuleren
            </v-btn>
            <v-btn color="blue darken-1" text @click="updateAmount">
              Opslaan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import apiShippingCost from "../api/apiShippingCost";
export default {
  data: () => ({
    search: "",
    shippingcost: [],
    headers: [
      { text: "Naam", value: "name", sortable: true },
      { text: "Bedrag", value: "amount", sortable: true },
      { text: "Acties", value: "Actions", sortable: false },
    ],
    editDialog: false,
    form: { name: "", amount: null }, // Form data for editing
  }),

  async mounted() {
    this.loadShippingCosts();
  },

  methods: {
    loadShippingCosts() {
      apiShippingCost.getShippingcostLands().then((data) => {
        this.shippingcost = data;
      });
    },
    openEditDialog(item) {
      this.form = { ...item }; // Load selected item data into form
      this.editDialog = true;
    },
    async updateAmount() {
  try {
    if (!this.form.name || this.form.amount == null) {
      this.$swal.fire("Foutmelding", "Vul alle velden in", "error");
      return;
    }
    const response = await apiShippingCost.updateShippingcostLand(this.form.id, this.form);
    console.log(response);
    this.$swal.fire("Yes!", "Het updaten is gelukt!", "success");
    this.editDialog = false;
    this.loadShippingCosts(); // Reload updated data
  } catch (error) {
    console.error(error);
    this.$swal.fire("Er is een foutmelding!", "Probeer het nogmaals!", "error");
  }
}

  },
};
</script>
