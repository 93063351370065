
<template>
  <Embcode />
</template>

<script>
import Embcode from "@/components/instellingen/Embcode";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Embcode,
  },
  metaInfo: {
    title: "Inhoud  | Postzegels ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>