<template>
  <v-container fluid>
    <form ref="form" @submit.prevent="submitForm">
      <v-stepper v-model="e13" vertical>
        <v-stepper-step step="1">
          Handelaar toevoegen
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-card>
            <v-card-title> Handelaar </v-card-title>

            <v-row>
              <v-col cols="4">
                <label> Bedrijfsnaam</label>
                <v-text-field
                dense
                  outlined
                  placeholder="Bedrijfsnaam"
                  v-model="form.companyName"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <label> Naam</label>
                <v-text-field dense outlined v-model="form.name"> </v-text-field>
              </v-col>
              <v-col cols="4">
                <label> Land handelaar</label>

                <v-select
                dense
                  outlined
                  v-model="form.Land"
                  :items="countries"
                  label="Kies een land"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <label> Adres </label>
                <v-text-field dense outlined v-model="form.Adres"> </v-text-field>
              </v-col>
              <v-col cols="4">
                <label> Postcode </label>
                <v-text-field dense outlined v-model="form.postCode"> </v-text-field>
              </v-col>
              <v-col cols="4">
                <label> Stad </label>
                <v-text-field dense outlined v-model="form.City"> </v-text-field>
              </v-col>

              <v-col cols="4">
                <label> Bankrekeningnummer </label>
                <v-text-field dense outlined v-model="form.bankNumer"> </v-text-field>
              </v-col>

              <v-col cols="4">
                <label> BTW </label>
                <v-text-field  dense outlined v-model="form.BTW"> </v-text-field>
              </v-col>
              <v-col cols="4">
                <label> Email </label>
                <v-text-field dense outlined v-model="form.Email"> </v-text-field>
              </v-col>

              <v-col cols="4">
                <label> Type</label>

                <v-select
                dense
                  outlined
                  v-model="form.type"
                  :items="types"
                ></v-select>
              </v-col>
            </v-row>
            <div style="margin-top:5px">
              <v-btn color="primary" @click="e13 = 2">
                Volgende
              </v-btn>
              <v-btn color="error" @click="$emit('cancel')" text>
                Annuleer
              </v-btn>
            </div>
          </v-card>
        </v-stepper-content>

        <v-stepper-step step="2">
          Media & Omschrijving
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-card>
            <v-row>
              <v-col cols="12">
                <label> Notitie </label>
                <textarea
                  class="form-control"
                  rows="4"
                  placeholder="Omschrijving"
                  data-counter="350"
                  name="description"
                  cols="50"
                  id="description"
                  v-model="form.notes"
                ></textarea>
              </v-col>
            </v-row>
            <div style="margin-top:5px">
              <v-btn color="primary" @click="addTrader()">
                Handelaar toevoegen
              </v-btn>

              <v-btn color="error" @click="$emit('cancel')" text>
                Annuleer
              </v-btn>
            </div>
          </v-card>
        </v-stepper-content>
      </v-stepper>
    </form>
  </v-container>
</template>

<script>
import apitrader from "../api/apiTrader";
import axios from "axios";
export default {
  data() {
    return {
      types: ["VIP", "NVT"],
      e13: 1,
      form: {
        companyName: "",
        name: "",
        Adres: "",
        postCode: "",
        City: "",
        Land: "",
        bankNumer: "",
        BTW: "",
        Type: "",
        email: "",
        notes: "",
      },
      countries: [],
    };
  },
  mounted() {
    this.getCountries();
  },
  methods: {
    resetForm() {
      this.form.companyName = "";
      this.form.name = "";
      this.form.Adres = "";
      this.form.postCode = "";
      this.form.City = "";
      this.form.Land = "";
      this.form.bankNumer = "";
      this.form.BTW = "";
      this.form.Type = "";
      this.form.email = "";
      this.form.notes = "";
    },
    getCountries() {
      axios
        .get("https://restcountries.com/v3.1/all")
        .then((response) => {
          this.countries = response.data.map((country) => country.name.common);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async addTrader() {
      try {
        if (!this.form.name) {
          this.$swal.fire("Foutmelding", "Vul het veld in", "error");
          return;
        }

        const response = await apitrader.createTrader(this.form);

        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.resetForm();
        const data = await apitrader.getTraders();
        this.states = data;
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>
