
<template>
  <Kavels />
</template>

<script>
import Kavels from "@/components/kavels/Kavels";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Kavels,
  },
  metaInfo: {
    title: "Kavels beheren | Dashboard",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>