<template>
  <div>
    <v-card>
      <v-dialog transition="dialog-top-transition" max-width="600">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on"> Lege mail</v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="primary" dark>Mail versturen</v-toolbar>
            <v-card-text>
              <vue-editor id="editor1" v-model="content"></vue-editor>
              <div class="text-center">
                <v-btn
                  color="primary"
                  style="margin-right:10px"
                  @click="sendEmail()"
                >
                  Mail versturen
                </v-btn>
                <v-btn color="error" @click="dialog.value = false">
                  Afsluiten
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
      <v-container fluid></v-container>
    </v-card>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import apiMessage from "../api/apiLogs";
export default {
  props: {
    name: {
      type: String,
      required: true,
      default: "Klant",
    },
    email: {
      type: String,
      required: true,
    },
  },
  components: {
    VueEditor,
  },
  data() {
    return {
      subject: "Bericht van postzegels",
      content: "",
    };
  },
  created() {
    this.setRecipientName(this.name);
    this.setRecipientEmail(this.email);
  },
  methods: {
    setRecipientName(name) {
      this.content = `Geachte ${name},


Met vriendelijke groet,

Postzegels`;
    },

    async sendEmail() {
      try {
        const data = {
          subject: this.subject,
          content: this.content,
          email: this.email,
        };
        const response = await apiMessage.sendMessage(data);
        console.log(response);
        this.$swal.fire("Ja!", "Het verzenden is gelukt!", "success");
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een fout opgetreden!",
          "Probeer het opnieuw!",
          "error"
        );
      }
    },
  },
};
</script>

<style scoped>
.text-center {
  padding-top: 1%;
  text-align: center;
}

#editor1 {
  height: 350px;
  width: 100%;
}

.projectButton {
  margin: 10px;
}
</style>../api/apiLogs
