import { API } from "./api.js";
export default {
  async createReport(data) {
    try {
      const response = await API.post("/api/rest/v2/generatereport", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getReports() {
    try {
      const response = await API.get("/api/rest/v2/generatereport");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteReport(id) {
    try {
      const response = await API.delete(`/api/rest/v2/generatereport/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateReport(id, form) {
    try {
      const response = await API.put(`/api/rest/v2/generatereport/${id}`, form);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getSingleReport(id) {
    try {
      const response = await API.get(`/api/rest/v2/generatereport/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async testCron() {
    try {
      const response = await API.post(`/api/rest/v2/generatereport/test-cron`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  
};
