<template>
  <v-container>
    <div style="float: right;">
      <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
            track and trace
          </v-btn>
        </template>

        <v-card>
          <v-card-title text-center>Track and trace code invullen</v-card-title>

          <v-card-text>
            <v-text-field
              v-model="localTrackAndTrace"
              label="Track and trace invullen"
              outlined
            ></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="dialog = false">
              Afsluiten
            </v-btn>
            <v-btn color="primary" @click="updateTrackAndTrace">
              Toevoegen en verzenden
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    trackAndtrace: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      dialog: false,
      localTrackAndTrace: this.trackAndtrace
    }
  },
  watch: {
    trackAndTrace(newVal) {
      this.localTrackAndTrace = newVal;
    }
  },
  methods: {
    updateTrackAndTrace() {
      this.$emit('update-track-and-trace', this.localTrackAndTrace);
      this.dialog = false;
    }
  }
}
</script>
