<template>
  <v-container class="mt-4">
    <h5>Order informatie</h5>
    <v-btn
      small
      color="error"
      @click="handleDownloadInvoice()"
      style="margin-right: 5px"
    >
      Factuur downloaden
    </v-btn>
    <EditInvoice
      :orderId="this.$route.params.id"
      @order-updated="fetchSingleOrder"
    />

    <AddOrderLine
      :orderNumber="this.orderId"
      @order-updated="fetchSingleOrder"
    />

    <v-dialog transition="dialog-top-transition" max-width="600">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          style="margin-left: 3px; color: white"
          color="#43A047"
          v-bind="attrs"
          v-on="on"
          small
          >Korting toevoegen</v-btn
        >
      </template>
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="primary" dark>Korting toevoegen</v-toolbar>

          <v-card-text>
            <p>
              Selecteer of je een korting in procenten of bedrag wil toevoegen
            </p>
            <v-radio-group v-model="discountType">
              <v-radio label="Korting in procenten" value="Percentage" />
              <v-radio label="Korting in bedrag" value="Bedrag" />
            </v-radio-group>

            <v-text-field
              v-if="discountType == 'Percentage'"
              v-model.number="discountPercentage"
              label="Procent toevoegen"
              type="number"
              min="0"
              max="100"
              clearable
            ></v-text-field>

            <v-text-field
              v-if="discountType == 'Bedrag'"
              v-model="discount"
              label="Bedrag invoeren"
              type="number"
              min="0"
              max="100"
              clearable
            ></v-text-field>
            <v-btn
              v-if="discountType == 'Bedrag'"
              @click="applyDiscountAmount"
              color="primary"
              >Korting toepassen</v-btn
            >

            <v-btn
              v-if="discountType == 'Percentage'"
              @click="applyDiscount"
              color="primary"
              >Korting toepassen</v-btn
            >
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialog.value = false">Sluiten</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <v-expansion-panels v-model="panel" multiple style="margin-top: 10px">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <template v-slot:actions>
            <v-icon color="primary"> $expand </v-icon>
          </template>
          <b> Klant informatie</b>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="4">
              <label>Naam klant</label>
              <v-text-field
                dense
                outlined
                v-model="form.name"
                disabled
              ></v-text-field>

              <label>Telefoonnummer</label>
              <v-text-field
                dense
                outlined
                v-model="form.phoneNumber"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <label>Email</label>
              <v-text-field
                dense
                outlined
                disabled
                v-model="form.email"
              ></v-text-field>

              <label>Adres</label>
              <v-text-field
                dense
                outlined
                v-model="form.adres"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="4">
              <label>Postcode</label>
              <v-text-field
                dense
                outlined
                v-model="form.postCode"
                disabled
              ></v-text-field>

              <label>Stad</label>
              <v-text-field
                dense
                outlined
                v-model="form.city"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <template v-slot:actions>
            <v-icon color="primary"> $expand </v-icon>
          </template>
          <b> Bestelling informatie</b>
          <v-spacer></v-spacer>
          <TrackAndTraceVue
            v-if="form.status == 'Verzonden'"
            :trackAndtrace="form.trackAndtrace"
            @update-track-and-trace="updateTrackAndTrace"
          />
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="4">
              <label>Bestelnummer</label>
              <v-text-field
                dense
                outlined
                disabled
                v-model="form.orderNumber"
              ></v-text-field>

              <label>Betaal status</label>

              <v-autocomplete
                dense
                outlined
                :items="betaalstatussen.flat()"
                label="Betaalstatus selecteren"
                item-text="name"
                item-value="name"
                v-model="form.betaalStatus"
              ></v-autocomplete>
              <v-col>
                <label>Totaalbedrag</label>
                <div>
                  <span v-html="formattedTotalAmount"></span>
                </div>
                <div
                  v-if="form.shippingCost"
                  style="display: flex; align-items: center"
                >
                  <v-text-field
                    label="Verzendkosten"
                    prefix="€"
                    v-model="form.shippingCost"
                    disabled
                    style="flex: 1"
                  ></v-text-field>
                  <v-icon
                    small
                    color="red"
                    @click="deleteShippingCost"
                    style="cursor: pointer; margin-left: 8px"
                  >
                    mdi-delete
                  </v-icon>
                </div>

                <div v-if="form.shippingCost">
                  <span v-html="formattedTotalWithShippingAmount"></span>
                  *Bedrag inclusief verzendkosten*
                </div>
              </v-col>
            </v-col>
            <v-col cols="4">
              <label>Order status</label>
              <v-autocomplete
                dense
                outlined
                :items="orderstatussen.flat()"
                label="Orderstatus selecteren"
                item-text="name"
                item-value="name"
                v-model="form.status"
              ></v-autocomplete>

              <label>Besteldatum</label>
              <v-tooltip v-model="showOrderDate" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon color="warning"> mdi-information </v-icon>
                  </v-btn>
                </template>
                <span>Besteldatum. De datum dat er is besteld.</span>
              </v-tooltip>
              <v-text-field
                dense
                v-if="!editDate"
                outlined
                v-model="form.createDate"
                disabled
              ></v-text-field>
              <v-btn @click="editDate = !editDate">Besteldatum aanpassen</v-btn>

              <v-dialog
                v-if="editDate"
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Picker in dialog"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="updateCreateDate()">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col cols="4">
              <label>BetaalMethode</label>
              <v-tooltip v-model="showPaymentMethod" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon color="warning"> mdi-information </v-icon>
                  </v-btn>
                </template>
                <span
                  >BetaalMethode die online bij het afrekenen is
                  geselecteerd.</span
                >
              </v-tooltip>
              <v-text-field
                dense
                outlined
                v-model="form.paymentMethod"
                disabled
              ></v-text-field>

              <label>Verzenddatum</label>
              <v-tooltip v-model="show" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon color="warning"> mdi-information </v-icon>
                  </v-btn>
                </template>
                <span>De datum dat de status is gewijzigd naar verzonden.</span>
              </v-tooltip>
              <v-text-field
                dense
                outlined
                v-model="form.sendDate"
                disabled
              ></v-text-field>
            </v-col>

            <v-col
              cols="4"
              v-if="
                this.form && this.form.discount && this.form.discount.length > 0
              "
            >
              <label>
                Korting bedrag
                <v-tooltip v-model="showdiscount" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon color="warning">mdi-information</v-icon>
                    </v-btn>
                  </template>
                  <span>Korting bedrag als deze is aangemaakt.</span>
                </v-tooltip>
              </label>
              <v-text-field
                outlined
                v-model="form.discount"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Bestellingen gekoppeld aan ordernummer
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
          </v-card-title>
          <div class="table-container">
            <v-data-table
              :headers="headers"
              :items="form.orderItems"
              disable-pagination
              :hide-default-footer="true"
              class="elevation-1"
            >
              <template v-slot:[`item.price`]="{ item }">
                € {{ item.price }}
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <div style="display: flex">
                  <KavelInformation :kavelNumber="item.kavelNumber" />

                  <v-icon
                    style="margin-left: 15px"
                    small
                    color="red"
                    @click="deleteOrderLine(item)"
                  >
                    mdi-delete
                  </v-icon>
                </div>
              </template>
              <template v-slot:[`item.payment`]="{ item }">
                <v-btn
                  v-if="form.status == 'Nog niet verwerkt'"
                  color="warning"
                  small
                  @click="paySingleOrderByPin(item)"
                >
                  Bestelling per pin afrekenen</v-btn
                >
                <v-text-field
                  v-if="form.status != 'Nog niet verwerkt'"
                  disabled
                  dense
                  label="Bestelling is al betaald"
                ></v-text-field>
              </template>
              <template v-slot:footer>
                <div style="text-align: center; margin-bottom: 1rem">
                  <v-btn
                    color="primary"
                    @click="updateOrder"
                    style="margin-right: 5px"
                    >Bestelling bijwerken</v-btn
                  >
                  <v-btn color="warning" @click="goBack()" text>Ga terug</v-btn>
                  <v-btn color="error" @click="deleteOrder()"
                    >Order verwijderen</v-btn
                  >
                </div>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AddOrderLine from "./AddOrderLine.vue";
import KavelInformation from "./KavelInformation";
import apiInvoice from "../api/apiInvoice";
import apiOrders from "../api/apiOrders";
import apiOrderstatus from "../api/apiOrderstatus";
import apiPaymentstatus from "../api/apiPaymentstatus";
import EditInvoice from "./EditInvoice.vue";
import apiShop from "../api/apiShop";
import TrackAndTraceVue from "./TrackAndTrace.vue";
export default {
  components: {
    KavelInformation,
    EditInvoice,
    TrackAndTraceVue,
    AddOrderLine,
  },
  data() {
    return {
      //
      editDate: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      //
      panel: [1],
      discountType: null,
      showdiscount: false,
      discountPercentage: 0,
      showOrderDate: false,
      show: false,
      showPaymentMethod: false,
      discount: "",
      betaalstatussen: [],
      orderstatussen: [],
      search: "",
      orders: [],
      form: [],
      headers: [
        {
          text: "Order ID",
          value: "id",
          sortable: true,
        },
        {
          text: "Kavelnummer",
          value: "kavelNumber",
          sortable: true,
        },
        {
          text: "Land",
          value: "land",
          sortable: true,
        },
        {
          text: "Prijs",
          value: "price",
          sortable: true,
        },

        {
          text: "Verzendkosten",
          value: "shippingCost	9",
          sortable: true,
        },
        {
          text: "Acties",
          value: "action",
          sortable: true,
        },
        {
          text: "Afrekenen",
          value: "payment",
          sortable: true,
        },
      ],
    };
  },

  mounted() {
    this.fetchSingleOrder();
    apiOrderstatus.getStatussen().then((data) => {
      this.orderstatussen = data;
    });
    apiPaymentstatus.getStatussen().then((data) => {
      this.betaalstatussen = data;
    });
  },
  computed: {
    totalAmount() {
      if (this.form && this.form.price) {
        return this.form.orderItems.reduce(
          (total, item) => total + item.price,
          0
        );
      }
      return 0;
    },
    formattedTotalAmount() {
      return `<strong>€ ${this.totalAmount}</strong>`;
    },
    formattedTotalWithShippingAmount() {
      return `<strong>€ ${this.form.price}</strong>`;
    },
    orderId() {
      const order = this.$route.params.id;
      return order;
    },
  },
  methods: {
    async updateTrackAndTrace(newTrackAndTrace) {
      const id = this.$route.params.id;
      try {
        const payload = {
          trackAndtrace: newTrackAndTrace,
        };
        const response = await apiOrders.updateOrder(id, payload);
        console.log(response);
        this.$swal.fire(
          "Yes!",
          "Track and trace code is bijgewerkt!",
          "success"
        );
        this.fetchSingleOrder();
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
    async fetchSingleOrder() {
      await apiOrders.getSingleOrder(this.$route.params.id).then((data) => {
        this.form = data;
      });
    },
    calculateDiscount() {
      const totalAmount = this.totalAmount;
      const discountValue =
        this.discountType === "Percentage"
          ? totalAmount * (this.discountPercentage / 100)
          : this.discount;

      const discountedTotal = discountValue;
      return discountedTotal.toFixed(2);
    },
    applyDiscount() {
      const discountedTotal = this.calculateDiscount();
      const discountValue =
        this.discountType === "Percentage"
          ? `${this.discountPercentage}% korting`
          : `${this.discount} € korting`;

      this.$swal
        .fire({
          title: "Totaalbedrag met korting:",
          text: `€ -${discountedTotal}\n\nToegepaste korting: ${discountValue}`,
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "Bevestigen",
          cancelButtonText: "Annuleren",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const discountAmount = discountedTotal;
            const discountObject = {
              discount: -1 * discountAmount,
              description: "Korting",
            };

            const id = this.$route.params.id;
            apiOrders
              .addDiscount(id, discountObject)
              .then(() => {
                this.$swal.fire({
                  title: "Korting toegepast",
                  text: `Toegepaste korting is ${discountValue} is toegepast op de bestelling.`,
                  icon: "success",
                });
                this.fetchSingleOrder();
              })
              .catch((error) => {
                console.error("Error applying discount:", error);
                this.$swal.fire({
                  title: "Fout",
                  text: "Er is een fout opgetreden bij het toepassen van de korting.",
                  icon: "error",
                });
              });
          }
        });
    },
    //Bedrag
    applyDiscountAmount() {
      this.$swal
        .fire({
          title: "Totaalbedrag met korting:",
          text: ` Toegepaste korting: € ${this.discount}`,
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "Bevestigen",
          cancelButtonText: "Annuleren",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const id = this.$route.params.id;
            const discountObject = {
              discount: -1 * this.discount,
              description: "Korting",
            };

            apiOrders
              .addDiscount(id, discountObject)
              .then(() => {
                this.$swal.fire({
                  title: "Korting toegepast",
                  text: `Toegepaste korting is ${this.discount} is toegepast op de bestelling.`,
                  icon: "success",
                });
                this.fetchSingleOrder();
              })
              .catch((error) => {
                console.error("Error applying discount:", error);
                this.$swal.fire({
                  title: "Fout",
                  text: "Er is een fout opgetreden bij het toepassen van de korting.",
                  icon: "error",
                });
              });
          }
        });
    },

    async updateOrder() {
      const id = this.$route.params.id;
      try {
        const payload = {
          betaalStatus: this.form.betaalStatus,
          status: this.form.status,
        };
        const response = await apiOrders.updateOrder(id, payload);
        console.log(response);
        this.$swal.fire("Yes!", "Het bijwerken is gelukt!", "success");
        this.fetchSingleOrder();
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },

    async updateCreateDate() {
      const id = this.$route.params.id;
      try {
        const payload = {
          createDate: this.date,
        };
        const response = await apiOrders.updateCreateDate(id, payload);
        console.log(response);
        this.$swal.fire("Yes!", "Het Aanpassen is gelukt!", "success");
        this.fetchSingleOrder();
        this.editDate = false;
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },

    async paySingleOrderByPin() {
      try {
        const orderId = this.$route.params.id;
        const totalPrice = this.totalAmount;
        const payload = {
          data: {
            totalPrice: totalPrice,
            orderNumber: this.form.orderNumber,
            orderId: orderId,
          },
        };
        console.log(payload);
        const response = await apiShop.paySingleOrder(payload);
        console.log(response);
        this.$swal.fire("Yes!", "Het bijwerken is gelukt!", "success");
        this.fetchSingleOrder();
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
    goBack() {
      this.$router.push("../../WebshopBestellingen");
    },
    async downloadInvoice() {
      try {
        const response = await apiInvoice.downloadInvoice(
          this.form.orderNumber,
          {
            responseType: "blob",
          }
        );
        return response;
      } catch (error) {
        this.$swal.fire(
          "Foutmelding!",
          "Controleer of de klant heeft betaald",
          "error"
        );
        console.error(
          "Er is een fout opgetreden bij het downloaden van de factuur:",
          error
        );
        throw error;
      }
    },
    async handleDownloadInvoice() {
      const orderNumber = this.form.orderNumber;
      try {
        const pdfData = await this.downloadInvoice();
        const url = window.URL.createObjectURL(pdfData);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${orderNumber}.pdf`);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        this.$swal.fire(
          "Foutmelding!",
          "Controleer of de klant heeft betaald",
          "error"
        );
        console.error(
          "Er is een fout opgetreden bij het downloaden van de factuur:",
          error
        );
      }
    },

    async deleteOrder() {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze kavel wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        try {
          const id = this.$route.params.id;
          await apiOrders.deleteOrder(id);

          this.$swal.fire(
            "Verwijderd",
            "De Bestelling is succesvol verwijderd",
            "success"
          );
          this.goBack();
        } catch (error) {
          console.error("Fout bij het verwijderen van de bestelling:", error);
        }
      }
    },

    async deleteOrderLine(item) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze kavel wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        try {
          await apiOrders.deleteOrderLine(item.id, this.form.id);

          this.$swal.fire(
            "Verwijderd",
            "De Bestelling is succesvol verwijderd",
            "success"
          );
          this.fetchSingleOrder();
        } catch (error) {
          console.error("Fout bij het verwijderen van de bestelling:", error);
        }
      }
    },
    async deleteShippingCost() {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je de verzendkosten wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        try {
          const id = this.$route.params.id;
          const payload = { shippingCost: 0 }; // Set shipping cost to 0 or remove it as needed
          await apiOrders.updateOrder(id, payload);
          await apiOrders.deleteInvoiceAndNewCreate(id);
          this.$swal.fire(
            "Verwijderd",
            "De verzendkosten zijn succesvol verwijderd",
            "success"
          );
          this.fetchSingleOrder();
        } catch (error) {
          console.error(
            "Fout bij het verwijderen van de verzendkosten:",
            error
          );
        }
      }
    },
  },
};
</script>

<style>
.table-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.action-buttons {
  display: flex;
  justify-content: space-between;
}
</style>
