<template>
  <v-dialog
    transition="dialog-top-transition"
    max-width="600"
    v-model="emcodeDialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn small rounded color="primary" block v-bind="attrs" v-on="on">
        Embcode met selecties
      </v-btn>
    </template>
    <template>
      <v-card>
        <v-toolbar color="primary" dark>
          Rapport status rapport met datum selectie
        </v-toolbar>
        <v-card-text>
          <p style="color: red; font-weight: bold">
            Niet alle velden zijn verplicht.
          </p>
          <v-row>
            <v-col cols="12">
              <label><b>Selecteer Emcode</b></label>
              <v-autocomplete
                dense
                outlined
                :items="embcodes"
                label="Embcode selecteren"
                v-model="report.embCode"
                item-text="nameNL"
                item-value="embNed"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <label><b>Kavel Status</b></label>
              <v-autocomplete
                dense
                outlined
                :items="statussen"
                label="Status selecteren"
                v-model="report.status"
                item-text="name"
                item-value="id"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <p><b>Selecteer een handelaar</b></p>
              <v-autocomplete
                dense
                outlined
                :items="traders"
                label="Handelaar selecteren"
                item-text="name"
                item-value="id"
                v-model="report.trader"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="selectedStartDate"
                    label="Startdatum"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="selectedStartDate"
                  @input="startDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="selectedEndDate"
                    label="Einddatum"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="selectedEndDate"
                  @input="endDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="generateReport()"
            >Rapport uitdraaien</v-btn
          >
          <v-btn text @click="closeDialog()">Afsluiten</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import apiStatus from "../api/apiStatus";
import jsPDF from "jspdf";
import "jspdf-autotable";
import apiType from "../api/apiEmbCode";
import apiKavel from "../api/apiKavel";
import apiTrader from "../api/apiTrader";

export default {
  data() {
    return {
      traders: [],
      embcodes: [],
      statussen: [],
      emcodeDialog: false,
      selectedStartDate: "",
      selectedEndDate: "",
      startDateMenu: false,
      endDateMenu: false,
      report: {
        embCode: "",
        status: "",
        trader: null,
      },
    };
  },
  mounted() {
    this.fetchEmbcodes();
    this.fetchKavelStatus();
    this.fetchTraders();
  },
  methods: {
    closeDialog() {
      this.emcodeDialog = false;
    },
    async fetchEmbcodes() {
      try {
        const data = await apiType.getTypes();
        this.embcodes = data;
      } catch (error) {
        console.log("Foutmelding: " + error);
      }
    },
    async fetchKavelStatus() {
      try {
        const data = await apiStatus.getStatus();
        this.statussen = data;
      } catch (error) {
        console.error("Error fetching statuses:", error);
      }
    },
    async fetchTraders() {
      try {
        const data = await apiTrader.getTraders();
        this.traders = data;
      } catch (error) {
        console.error("Error fetching traders:", error);
      }
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    async generateReport() {
      const data = {
        embCode: this.report.embCode,
        status: this.report.status,
        startDate: this.selectedStartDate,
        endDate: this.selectedEndDate,
        trader: this.report.trader,
      };
      try {
        const response = await apiKavel.generateEmbcodeReport(data);
        if (response && Array.isArray(response.kavelResponse)) {
          const kavelsData = [...response.kavelResponse];
          kavelsData.push({
            kavelnr: "Totaal",
            inhoudNL: "",
            embNed: "",
            landName: "",
            statusName: "",
            kenmerk: "",
            price: response.totalPrice,
            createDate: "",
          });

          console.log("kavelsData", kavelsData);

          const doc = new jsPDF('landscape');
          const currentDate = new Date().toLocaleDateString();

          // Add report title and date
          doc.setFontSize(18);
          doc.text("Rapport status rapport met datum selectie", 14, 16);
          doc.setFontSize(12);
          doc.text(`Datum uitdraaien: ${currentDate}`, 14, 23);
          doc.text(`Totaalbedrag: €${response.totalPrice}`, 14, 30);

          // Add the table
          doc.autoTable({
            startY: 40,
            head: [
              [
                "Kavel",
                "Inhoud",
                "Embcode",
                "Land",
                "Status",
                "Kenmerk",
                "Prijs",
                "Create Date",
              ],
            ],
            body: kavelsData.map((item) => [
              item.kavelnr,
              item.inhoudNL,
              item.embNed,
              item.landName,
              item.statusName,
              item.kenmerk,
              item.price,
              this.formatDate(item.createDate),
            ]),
          });

          const reportFilename = `Embcode-${new Date().toLocaleDateString()}.pdf`;
          doc.save(reportFilename);
        } else {
          console.error("Received data is not properly formatted", response);
        }
      } catch (error) {
        console.error("Error generating the report:", error);
      }
    },
  },
};
</script>
