<template>
  <v-container>
    <v-card>
      <v-card-title>
        Kavels op Ebay overzicht
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="exportToPdf">Exporteer naar PDF</v-btn>
      </v-card-title>
      <v-card-text ref="pdfContent">
        <template>
          <v-card>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  ><b> Aantal kavels: </b>
                  {{ report.kavels.length }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  ><b> Totaalbedrag: </b> Euro
                  {{ report.totalPrice }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  ><b>Inkoopprijs:</b> Euro
                  {{ report.totalPurchasePrice }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  ><b>Winst:</b> Euro
                  {{
                    (report.totalPrice - report.totalPurchasePrice).toFixed(2)
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </template>
        <v-data-table
          :headers="headers"
          :items="report.kavels"
          :items-per-page="-1"
        >
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import apiReport from "../api/apiReport";
import jsPDF from "jspdf";
import "jspdf-autotable";
export default {
  data() {
    return {
      report: [],
      headers: [
        { text: "Handelaar Naam", value: "traderName" },
        { text: "Kavelnummer", value: "kavelnr" },
        { text: "Kenmerk", value: "kenmerk" },
        { text: "Inhoud", value: "inhoudNL" },
        { text: "Embcode", value: "embNed" },

        { text: "Kavelprijs", value: "price" },
        { text: "Inkoopprijs", value: "purchasePrice" },
        { text: "Status", value: "statusName" },
        { text: "Datum plaatsting Ebay", value: "ebaydatum" },
      ],
    };
  },

  mounted() {
    this.fetchReport();
  },
  methods: {
    formatDate(dateString) {
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    async fetchReport() {
      try {
        const response = await apiReport.getKavelEbayRapport();
        this.report = response;
        console.log(this.report); // Controleer de gegevens in de console
      } catch (error) {
        console.error("Error fetching report:", error);
      }
    },

    async exportToPdf() {
      try {
        const response = await apiReport.getKavelEbayRapport();
        if (response && Array.isArray(response.kavels)) {
          const kavelsData = [...response.kavels];
          kavelsData.push({
            kavelnr: "Totaal",
            inhoudNL: "",
            embNed: "",
            landName: "",
            statusName: "",
            kenmerk: "",
            price: response.totalPrice,
            createDate: "",
          });

          console.log("kavelsData", kavelsData);

          const doc = new jsPDF("landscape");
          const currentDate = new Date().toLocaleDateString();

          // Add report title and date
          doc.setFontSize(18);
          doc.text("Rapport status rapport met datum selectie", 14, 16);
          doc.setFontSize(12);
          doc.text(`Datum uitdraaien: ${currentDate}`, 14, 23);
          doc.text(`Totaalbedrag: €${response.totalPrice}`, 14, 30);
          doc.text(`Aantal kavels: ${response.kavels.length}`, 14, 36);

          // Add the table
          doc.autoTable({
            startY: 40,
            head: [
              [
                "Kavel",
                "Inhoud",
                "Embcode",
                "Land",
                "Status",
                "Kenmerk",
                "Prijs",
                "Invoerdatum",
              ],
            ],
            body: kavelsData.map((item) => [
              item.kavelnr,
              item.inhoudNL,
              item.embNed,
              item.landName,
              item.statusName,
              item.kenmerk,
              item.price,
              this.formatDate(item.createDate),
            ]),
          });

          const reportFilename = `ebayrapport-${new Date().toLocaleDateString()}.pdf`;
          doc.save(reportFilename);
        } else {
          console.error("Received data is not properly formatted", response);
        }
      } catch (error) {
        console.error("Error generating the report:", error);
      }
    },
  },
};
</script>
