import { API } from "./api.js";
export default {
      async updatekaveloftheday(kavelnr,data) {
        try {
          const response = await API.post(`/api/rest/v2/kaveloftheday/${kavelnr}`, data);
          return response.data;
        } catch (error) {
          console.error(error);
        }
      },
    
      async getkaveloftheday() {
        try {
          const response = await API.get("/api/rest/v2/kaveloftheday");
          return response.data;
        } catch (error) {
          console.error(error);
        }
      },
};
