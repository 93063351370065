
<template>
  <Kassa />
</template>

<script>
import Kassa from "@/components/cashier/Kassa";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Kassa,
  },
  metaInfo: {
 title: "Kassa",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>