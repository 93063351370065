import { API } from "./api.js";

export default {
  async createOrder(data) {
    try {
      const response = await API.post("/api/rest/v2/order/webshop", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getorder() {
    try {
      const response = await API.get("/api/rest/v2/order/webshop");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getCustomerR() {
    try {
      const response = await API.get("/api/rest/v2/order/webshop");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteOrder(id) {
    try {
      const response = await API.delete(`/api/rest/v2/order/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateOrder(id, form) {
    try {
      const response = await API.put(`/api/rest/v2/order/webshop/${id}`, form);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getSingleOrder(id) {
    try {
      const response = await API.get(`/api/rest/v2/order/webshop/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getCustomerReport(data) {
    try {
      const response = await API.post(
        "/api/rest/v2/order/webshop/report",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async addDiscount(id, discountedTotal) {
    try {
      const response = await API.post(
        `/api/rest/v2/discount/${id}`,
        discountedTotal
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getOrderHistory() {
    try {
      const response = await API.get("/api/rest/v2/order/names/orderhistory");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getPersonInfo(data) {
    try {
      const response = await API.post(`/api/rest/v2/order/names/orderhistory/personinfo`, data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async updateCreateDate(id , createDate) {
    try {
      const response = await API.put(`/api/rest/v2/order/orderDate/${id}`, createDate);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },


  async deleteOrderLine(id, orderId) {
    try {
      const response = await API.delete(`/api/rest/v2/discount/orderline/delete/${id}/${orderId}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async addOrderLine(orderId, kavelnr ) {
    try {
      const response = await API.post(`/api/rest/v2/discount/orderline/add/${orderId}/${kavelnr}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },


  async deleteInvoiceAndNewCreate(id) {
    try {
      const response = await API.post(`/api/rest/v2/discount/generateinvoice/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },



};
