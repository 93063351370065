
<template>
    <Continents />
  </template>
  
  <script>
  import Continents from "@/components/instellingen/Continents";
  export default {
    data() {
      return {
        data: [],
      };
    },
    components: {
        Continents,
    },
    metaInfo: {
      title: "Continenten | Postzegels ",
      htmlAttrs: {
        lang: "EN",
        amp: true,
      },
    },
  };
  </script>