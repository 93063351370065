import { API } from "./api.js";

export default {
  async createKavel(form) {
    try {
      const response = await API.post("/api/rest/v2/kavel", form);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },


  async getKavels(pageNumber, q = "") {
    try {
      const response = await API.get(
        `/api/rest/v2/kavel`,
        {
          params:{q,page:pageNumber}
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },


  async StatusOrNumber(term) {
    try {
      const response = await API.get(`/api/rest/v2/kavel/search/${term}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getKassaKavels() {
    try {
      const response = await API.get("/api/rest/v2/kavel/kassa/data");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getOnlineKavels() {
    try {
      const response = await API.get("/api/rest/v2/kavel/webshop/NL/data");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteKavel(id) {
    try {
      const response = await API.delete(`/api/rest/v2/kavel/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateKavel(id, form) {
    try {
      const response = await API.put(`/api/rest/v2/kavel/${id}`, form);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getSingleKavel(id) {
    try {
      const response = await API.get(`/api/rest/v2/kavel/admin/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async updateLand(id, land) {
    try {
      const response = await API.put(`/api/rest/v2/kavel/${id}`, {land});
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async updatePrice(id, data) {
    try {
      const response = await API.put(`/api/rest/v2/kavel/${id}`, data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async updateTrader(id, traderId) {
    try {
      const response = await API.put(`/api/rest/v2/kavel/${id}`, traderId);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },


  async updateText(id, data) {
    try {
      const response = await API.put(`/api/rest/v2/kavel/${id}`, data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async updateAddToIb(id, ebay) {
    try {
      const response = await API.put(`/api/rest/v2/kavel/${id}`, {ebay});
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateStatus(id, status) {
    try {
      const response = await API.put(`/api/rest/v2/kavel/${id}`, {status});
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async updateWeight(id, weight) {
    try {
      const response = await API.put(`/api/rest/v2/kavel/${id}`, {weight});
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateKenmerk(id, kenmerk) {
    try {
      const response = await API.put(`/api/rest/v2/kavel/${id}`, {kenmerk});
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async generateReport(data) {
    try {
      const response = await API.post(
        "/api/rest/v2/kavel/report/data/kavels",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },


  async generateKavelReport(data) {
    try {
      const response = await API.post(
        "/api/rest/v2/invoice/kavelreporting",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async generateKavelStatusReport(data) {
    try {
      const response = await API.post(
        "/api/rest/v2/kavel/report/data/status",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async generateKavelRangeReport(data) {
    try {
      const response = await API.post(
        "/api/rest/v2/invoice/kavelrange/report",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async generateKavelPriceRangeReport(data) {
    try {
      const response = await API.post(
        "/api/rest/v2/invoice/pricerange/report",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },


  async getKavelSoldReport(data) {
    try {
      const response = await API.post(
        "/api/rest/v2/invoice/solddate/report",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async generateEmbcodeReport(data) {
    try {
      const response = await API.post(
        "/api/rest/v2/invoice/embcode/report",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async generateMultitTraderReport(data) {
    try {
      const response = await API.post(
        "/api/rest/v2/invoice/multipletraders/report",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getAllImages(kavelNummber) {
    try {
      const response = await API.get(
        `/api/rest/v2/kavel/images/${kavelNummber}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
