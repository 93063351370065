<template>
   
      <v-dialog v-model="dialogPayment" persistent max-width="400">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="success" v-bind="attrs" v-on="on">Betaling toevoegen</v-btn>
        </template>
        <v-card>
          <v-card-title style="text-align: center;" >Betaling toevoegen</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="form.amountpaid"
              prefix="Euro"
            type="number"

              outlined
              dense
              label="Bedrag"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialogPayment = false">
              Afsluiten
            </v-btn>
            <v-btn color="primary" @click="addPayment()"> Betaling toevoegen </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

  </template>
  
  <script>
  import apiTraderPaymentinout from "../api/apiTraderPaymentinout";
  export default {
    data() {
      return {
        dialogPayment: false,
        form: {
            amountpaid: null,
            paymentInOutId: null,
         
        },
       
      };
    },
    methods: {
      async addPayment() {
        try {
          if (!this.form.amountpaid) {
            this.$swal.fire("Foutmelding", "Vul het bedrag", "error");
            return;
          }
          const data ={
            paymentInOutId: this.$route.params.id,
            amountpaid: this.form.amountpaid
          }
          const response = await apiTraderPaymentinout.addPayment(data);
          console.log(response);
        
          this.$swal.fire("Yes!", "Het wijzigen is gelukt!", "success");
          this.dialogPayment = false;
          this.form.amountpaid = "";
          this.$emit("payment-updated");
        } catch (error) {
          console.error(error);
          this.$swal.fire(
            "Er is een foutmelding!",
            "Probeer het nogmaals!",
            "error"
          );
        }
      },
    },
  };
  </script>
  