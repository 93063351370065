<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-container fluid>
            <v-card>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    dark
                    v-on="on"
                    style="margin-left:10px;margin-top:10px"
                  >
                    Exporteren
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="item in exportOptions"
                    :key="item.text"
                    @click="exportData(item.type)"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-card-title>
                Handelaars
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Opzoeken"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <div class="table-container">
                <v-data-table
                :fixed-header="true"
                   height="75vh"
                  :headers="headers"
                  :items="traders"
                  :search="search"
                  disable-pagination
                  :hide-default-footer="true"
                  class="elevation-1"
                  id="my-traders"
                  cellClass="printable"
                >
                  <template v-slot:[`item.Actions`]="{ item }">
                    <v-icon small color="green" @click="openTrader(item.id)">
                      mdi-eye
                    </v-icon>
                    <v-icon small color="red" @click="deleteTrader(item.id)">
                      mdi-delete
                    </v-icon>
                    <v-menu bottom left v-if="!item.email == ''">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                          <v-icon small color="blue">
                            mdi-email
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-list-item-title>
                            <paymentReminder
                              :name="item.name"
                              :email="item.email"
                            />
                          </v-list-item-title>
                        </v-list-item>
                      
                        <v-list-item>
                          <v-list-item-title>
                            <emptyMail :name="item.name" :email="item.email" />
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import "jspdf-autotable";
import paymentReminder from "../mail/paymentReminder";
import emptyMail from "../mail/emptyMail";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import apiTrader from "../api/apiTrader";
export default {
  components: {
    paymentReminder,
    emptyMail,
  },
  data: () => ({
    exportOptions: [
      {
        text: "Export naar Excel",
        type: "excel",
        icon: "mdi-file-excel-outline",
      },
      { text: "Export naar PDF", type: "pdf", icon: "mdi-file" },
      { text: "Afdrukken", type: "print", icon: "mdi-printer" },
    ],
    traders: [],
    search: "",
    headers: [
      {
        text: "Bedrijfsnaam",
        value: "companyName",
        sortable: true,
      },
      {
        text: "Naam",
        value: "name",
        sortable: true,
      },
      {
        text: "Adres",
        value: "Adres",
        sortable: true,
      },

      {
        text: "Acties",
        value: "Actions",
        sortable: false,
      },
    ],
  }),

  async mounted() {
    apiTrader.getTraders().then((data) => {
      this.traders = data;
      console.log(this.traders);
    });
  },

  methods: {
    getName(name) {
      return name;
    },
    getEmail(email) {
      return email;
    },
    openTrader(id) {
      this.$router.push(`/Handelaar/bewerken/${id}`);
    },
    async deleteTrader(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze handelaar wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiTrader.deleteTrader(id);
        const data = await apiTrader.getTraders();
        this.traders = data;
        this.$swal.fire(
          "Verwijderd",
          "De Handelaar is succesvol verwijderd",
          "success"
        );
      }
    },
    sendReminder(trader) {
      // Logica om herinnering te sturen naar handelaar
      console.log("Stuur herinnering naar", trader.email);
    },
    sendPaymentRequest(trader) {
      // Logica om betaalverzoek te sturen naar handelaar
      console.log("Stuur betaalverzoek naar", trader.email);
    },
    sendMail(trader) {
      // Logica om betaalverzoek te sturen naar handelaar
      console.log("Stuur betaalverzoek naar", trader.email);
    },
    exportData(type) {
      if (type === "excel") {
        const data = XLSX.utils.json_to_sheet(this.traders);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, data, "data");
        XLSX.writeFile(wb, "Handelarenoverzicht.xlsx");
      } else if (type === "pdf") {
        const doc = new jsPDF('landscape');
        const tableData = [];
        this.traders.forEach((item) => {
          const rowData = [];
          this.headers.forEach((header) => {
            rowData.push(item[header.value]);
          });
          tableData.push(rowData);
        });
        const colHeaders = this.headers.map((header) => header.text);
        doc.autoTable({
          head: [colHeaders],
          body: tableData,
        });
        doc.save("Handelaren.pdf");
      } else if (type === "print") {
        const printContents = document.getElementById("my-traders").outerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
      }
    },
  },
};
</script>

<style>
.table-container {
  height: 500px; /* Hoogte van de tabelcontainer */
  overflow-y: auto; /* Scrollen toestaan */
}

.v-data-table__overflow {
  overflow: hidden !important; /* Verborgen overflow voor de tabelinhoud */
}
</style>
