
<template>
    <CustomerLogs />
  </template>
  
  <script>
  import CustomerLogs from "@/components/logs/CustomerLogs";
  export default {
    data() {
      return {
        data: [],
      };
    },
    components: {
        CustomerLogs,
    },
    metaInfo: {
      title: "Logbestanden verzonden berichten | Dashboard",
      htmlAttrs: {
        lang: "EN",
        amp: true,
      },
    },
  };
  </script>