<template>
    <Newsletter />
  </template>
  
  <script>
import Newsletter from "@/components/newsletter/Newsletter.vue";
  export default {
    data() {
      return {
        data: [],
      };
    },
    components: {
        Newsletter,
    },

  };
  </script>
  