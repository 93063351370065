<template>
  <div>
    <v-container fluid>
      <div style="display: flex; justify-content: flex-end; margin-bottom: 2px;">
        <!-- v-spacer toegevoegd om de knop naar rechts te duwen -->
        <v-spacer></v-spacer>
        <v-btn
          @click="deleteLogs()"
          rounded
          color="red"
          style="color: white;"
          >Verwijder alle logs</v-btn
        >
      </div>
      <div style="text-align: left;">
        <v-system-bar dark color="blue">
          <v-spacer></v-spacer>
        </v-system-bar>
      </div>

      <v-card>
        <v-card-title>
          Klanten logbestanden
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Opzoeken"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="logs"
          :search="search"
          disable-pagination
          :hide-default-footer="true"
          class="elevation-1"
        >
          <template v-slot:top> </template>
          <template v-slot:[`item.Actions`]="{ item }"> </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import apiMessage from "../api/apiLogs";

export default {
  data: () => ({
    search: "",
    logs: [],
    headers: [
      {
        text: "Nr",
        value: "id",
        sortable: true,
      },

      {
        text: "Klantnaam",
        value: "name",
        sortable: true,
      },
      {
        text: "ipadres",
        value: "ip",
        sortable: true,
      },

      {
        text: "Type",
        value: "eventType",
        sortable: true,
      },

      {
        text: "Website",
        value: "website",
        sortable: true,
      },
      {
        text: "Bekeken kavel",
        value: "productNumber",
        sortable: true,
      },

      {
        text: "Aangemaakt",
        value: "timestamp",
        sortable: true,
      },

      // {
      //   text: "Actions",
      //   value: "Actions",
      //   sortable: false,
      // },
    ],
  }),

  async mounted() {
   await this.fetchData();
  },
  methods: {
    async fetchData(){
    await  apiMessage.getactivitiesLog().then((data) => {
      this.logs = data;
    });
    },
    async deleteLogs() {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je de logs wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiMessage.deleteactivitiesLog();
        await this.fetchData();
        this.$swal.fire(
          "Verwijderd",
          "De logs is succesvol verwijderd",
          "success"
        );
      }
    },
  },
};
</script>
