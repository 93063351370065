<template>
  <v-container fluid style="margin-top: 2rem;">
    <h5> Instructies</h5>
    <div>
      <AddIntructions />
      <div style="margin-bottom: 5px;margin-top: 10px;">
        <v-text-field
           class="custom-search-bar"
              append-icon="mdi-magnify"
          v-model="searchQuery"
          label="Zoek op onderwerp of inhoud"
          @input="filterItems"
          solo
          flat
          hide-details

        ></v-text-field>
      </div>
      <v-container fluid style="margin-top: 50px;">
        <v-row v-if="filteredItems.length">
          <v-row justify="center">
            <v-expansion-panels accordion>
              <v-expansion-panel
                v-for="item in filteredItems"
                :key="item.id"
              >
                <v-expansion-panel-header>{{ item.subject }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <vue-editor :value="item.content" :disabled="true"></vue-editor>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            <v-alert type="info">Geen resultaten gevonden</v-alert>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>

<script>
import apiInstruction from "../api/apiIntruction";
import { VueEditor } from "vue2-editor";

import AddIntructions from "./AddIntructions.vue";
export default {
  components: {
    VueEditor,
    AddIntructions
  },
  data() {
    return {
      dialog: false,
      subject: "",
      content: "",
      description: "",
      category: "",
      searchQuery: this.$route.query.search || "",
      knowledgeItems: [],
      filteredItems: []
    };
  },
  async mounted() {
    try {
      this.knowledgeItems = await apiInstruction.getInstructions();
      this.filteredItems = this.knowledgeItems;
      this.filterItems();
    } catch (error) {
      console.error("Failed to fetch knowledge items", error);
    }
  },
  watch: {
    '$route.query.search'(newQuery) {
      this.searchQuery = newQuery;
      this.filterItems();
    }
  },
  methods: {
    resetForm() {
      this.subject = "";
      this.content = "";
      this.description = "";
      this.category = "";
    },
    cancelForm() {
      this.dialog = false;
      this.$emit("cancel");
    },
    async createKnowledgeItem() {
      try {
        const data = {
          subject: this.subject,
          content: this.content,
          category: this.category
        };
        const response = await apiInstruction.createIntruction(data);
        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.knowledgeItems = await apiInstruction.getInstructions();
        this.filterItems();
        this.resetForm();
        this.$root.$emit("knowledgeItemAdded");
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
    filterItems() {
      const query = this.searchQuery.toLowerCase();
      if (query) {
        this.filteredItems = this.knowledgeItems.filter(
          item =>
            item.subject.toLowerCase().includes(query) ||
            item.content.toLowerCase().includes(query)
        );
      } else {
        this.filteredItems = this.knowledgeItems;
      }
    },
    toggleItem(itemId) {
      this.filteredItems = this.filteredItems.map(item => {
        if (item.id === itemId) {
          item.showContent = !item.showContent;
        }
        return item;
      });
    }
  },
};
</script>

<style scoped>
.text-center {
  padding-top: 1%;
  text-align: center;
}
.content-container {
  max-height: 200px;
  overflow-y: auto;
}
.projectButton {
  margin: 10px;
}
.custom-search-bar {
  font-size: 1.3rem;
  border-radius: 8px;
  transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out;
  border: 2px solid #1976d2;
}

.custom-search-bar:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.custom-search-bar  .v-input__control {
  display: flex;
  justify-content: center;
}

.custom-search-bar .v-label {
  color: #1976d2;
  font-size: 1.2rem;
  text-align: center;
  width: 100%;
}

.custom-search-bar .v-text-field__slot {
  text-align: center;
}

.custom-search-bar input {
  font-size: 1.2rem;
  text-align: center;
}
</style>