
<template>
  <Folders />
</template>

<script>
import Folders from "@/components/instellingen/Folders";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Folders,
  },
  metaInfo: {
    title: "Inhoud  | Postzegels ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>