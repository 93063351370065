<template>
  <v-container fluid>
    <div>
      <h4>Documenten in {{ folderName }}</h4>
    </div>
    <div>
      <v-text-field
        v-model="searchTerm"
        label="Zoek kavelfolder op naam"
        outlined
        dense
      ></v-text-field>
      <h4>Documenten in {{ folderName }}</h4>
      <v-btn
        v-if="selectedImages.length > 0"
        color="red"
        class="ma-2"
        @click="handleDeleteMultipleImages"
        >Verwijder Geselecteerde Foto's</v-btn
      >
    </div>
    <v-row>
      <!-- v-for="item in folderFiles" -->
      <v-col
        v-for="item in filteredFolderFiles"
        :key="item.name"
        cols="12"
        md="6"
        lg="4"
      >
        <v-card class="folder-card" @click="handleClick(item)">
          <template v-if="item.isDirectory">
            <v-icon x-large color="yellow">mdi-folder</v-icon>
            <span class="folder-name">{{ item.name }}</span>
          </template>
          <template v-else>
            <v-checkbox
              v-if="item.ext != '.pdf'"
              v-model="selectedImages"
              :value="item.name"
              @click.stop
            ></v-checkbox>
            <img
              v-if="item.ext != '.pdf'"
              :src="imageSrc(item.loc)"
              alt="Item Afbeelding"
              height="150"
              width="350"
            />
            <span class="folder-name">{{ item.name }}</span>

            <v-btn
              v-if="item.ext != '.pdf'"
              block
              @click.stop="handleViewImage(item.loc)"
              color="primary"
              >Klik op dit om vergooten</v-btn
            >

            <v-btn
              v-if="item.ext == '.pdf'"
              block
              @click="downloadFile(item.loc)"
              color="primary"
              >Download bestand</v-btn
            >
          </template>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="600px">
      <v-card>
        <v-img :src="selectedImage" aspect-ratio="1.5"></v-img>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog = false"
            >Sluiten</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import apiFolders from "../api/apiFolders";

export default {
  props: {
    folderName: String,
    folderFiles: Array,
  },
  data() {
    return {
      selectedImages: [],
      searchTerm: "",
      files: [],
      dialog: false,
      selectedImage: "",
    };
  },

  computed: {
    filteredFolderFiles() {
      if (!this.searchTerm) return this.folderFiles;
      return this.folderFiles.filter((item) =>
        item.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
  },
  async mounted() {
    this.fetchFiles();
  },
  methods: {
    async fetchFiles() {
      try {
        this.files = [];
        const files = await apiFolders.getDirectoriesFiles(this.folderName);
        this.files = files;
      } catch (error) {
        console.error(error);
      }
    },
    async handleDeleteImage(fileNameOrArray) {
      let fileNames = Array.isArray(fileNameOrArray)
        ? fileNameOrArray
        : [fileNameOrArray];
      for (const fileName of fileNames) {
        const confirmed = await this.confirmDelete();
        if (confirmed) {
          await apiFolders.deleteFile(this.folderName, fileName);
        }
      }
      this.fetchFiles();
      this.selectedImages = [];
    },

    async handleDeleteMultipleImages() {
      if (!this.selectedImages.length) {
        return;
      }

      // Bevestiging vragen voordat meerdere afbeeldingen worden verwijderd.
      const confirmed = await this.confirmDelete();
      if (!confirmed) {
        return;
      }

      // Verwijder elke geselecteerde afbeelding.
      for (const imageName of this.selectedImages) {
        await apiFolders.deleteFile(this.folderName, imageName);
      }

      // Succesmelding na het verwijderen.
      this.$swal.fire(
        "Klaar!",
        "Alle geselecteerde afbeeldingen zijn verwijderd.",
        "success"
      );

      // Staat bijwerken.
      await this.fetchFiles();
      this.selectedImages = [];
    },

    async confirmDelete() {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze foto(s) wil verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });
      return confirmed.value;
    },

    imageSrc(loc) {
      return `https://server.postzegels.com/${loc}`;
    },
    handleClick(item) {
      if (item.isDirectory) {
        this.openSubFolder(item.name);
      }
    },
    openSubFolder(subFolderName) {
      this.$emit("openFolder", `${this.folderName}/${subFolderName}`);
    },
    handleViewImage(loc) {
      this.selectedImage = `https://server.postzegels.com/${loc}`;
      this.dialog = true;
    },
    handleClose() {
      this.dialog = false;
    },
    downloadFile(loc) {
      const path = `https://server.postzegels.com/${loc}`;
      const a = document.createElement("a");
      a.href = path;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
};
</script>

<style>
.folder-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  margin: 10px;
}
.folder-name {
  margin-top: 10px;
  font-weight: bold;
}
</style>
