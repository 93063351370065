<template>
  <GenerateReport />
</template>

<script>
import GenerateReport from "@/components/report/GenerateReport";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    GenerateReport,
  },
  metaInfo: {
    title: "Rapportage genereren | Dashboard",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>
