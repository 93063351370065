import { API } from "./api.js";

export default {
  async sendMessage(data) {
    try {
      const response = await API.post("/api/rest/v2/message/send/email", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getMessagesLog() {
    try {
      const response = await API.get(`/api/rest/v2/message/logs`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },


  async activitiesLog(data) {
    try {
      const response = await API.post("/api/rest/v2/activities/log", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getactivitiesLog() {
    try {
      const response = await API.get(`/api/rest/v2/activities/log`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteactivitiesLog() {
    try {
      const response = await API.delete(`/api/rest/v2/activities/log`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

};
