var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"padding-top":"4rem"},attrs:{"fluid":""}},[_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","placeholder":"Orderstatus invullen"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('v-textarea',{attrs:{"dense":"","outlined":"","placeholder":"Omschrijving die handig kan zijn"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addStatus()}}},[_vm._v(" Orderstatus toevoegen ")])],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Orderstatus "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Opzoeken","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"table-container"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.statussen,"search":_vm.search,"disable-pagination":"","hide-default-footer":true},scopedSlots:_vm._u([{key:`item.Actions`,fn:function({ item }){return [(
                    item.name !== 'Afgehandeld' &&
                    item.name !== 'Verzonden' &&
                    item.name !== 'wordt opgehaald' &&
                    item.name !== 'Verkocht' &&
                    item.name !== 'Nog niet verwerkt'
                  )?_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deleteState(item.id)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,true)})],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }