<template>
  <v-container fluid>
    <v-dialog v-model="dialogStatus" persistent max-width="290">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="success" v-bind="attrs" v-on="on">Status wijzigen</v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5">Status wijzigen</v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="form.status"
            :items="statussen"
            outlined
            dense
            label="Status"
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogStatus = false">
            Afsluiten
          </v-btn>
          <v-btn color="primary" @click="updateStatus()"> Wijzingen </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import apiTraderPaymentinout from "../api/apiTraderPaymentinout";
export default {
  data() {
    return {
      dialogStatus: false,
      form: {
        status: "",
      },
      statussen: [
        "Gedeeltelijk betaald",
        "Betaald",
        "Vervallen",
        "Geannuleerd",
        "In behandeling",
        "Teruggestort",
        "Verkeerd aangemaakt"
      ],
    };
  },
  methods: {
    async updateStatus() {
      try {
        if (!this.form.status) {
          this.$swal.fire("Foutmelding", "Vul het veld in", "error");
          return;
        }
        const id = this.$route.params.id;
        const response = await apiTraderPaymentinout.updatePayment(id, this.form);
        console.log(response);
        this.$swal.fire("Yes!", "Het wijzigen is gelukt!", "success");
        this.dialogStatus = false;
        this.$emit("status-updated");
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>
