<template>
  <v-container fluid style="padding-top: 4rem">
    <v-row>
      <div style="margin-left:10px;margin-right:5px">
        <v-card max-width="374" >
          <v-card-title>Status buitenlandse website bekijken</v-card-title>
          <v-card-actions>
            <v-chip class="ma-2" close color="green" outlined>
              <v-icon left>
                mdi-fire
              </v-icon>
             Online
            </v-chip>
          </v-card-actions>
        </v-card>
      </div>

      <v-card max-width="374" >
        <v-card-title>Status Nederlandse website bekijken</v-card-title>
        <v-card-actions>
          <v-chip class="ma-2" close color="green" outlined>
            <v-icon left>
              mdi-fire
            </v-icon>
           Online
          </v-chip>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      statusEN: null,
      statusNL: null,
    };
  },
  methods: {
    async checkNLWebsiteStatus() {
      try {
        const response = await axios.get('https://postzegels.com');
        this.statusNL = response.status; // Bijv. 200, 404, etc.
      } catch (error) {
        this.handleErrorResponse(error, 'NL');
      }
    },
    async checkENWebsiteStatus() {
      try {
        const response = await axios.get('https://stampscollectingcenter.com');
        this.statusEN = response.status; // Bijv. 200, 404, etc.
      } catch (error) {
        this.handleErrorResponse(error, 'EN');
      }
    },
    handleErrorResponse(error, website) {
      if (error.response) {
        this[`status${website}`] = error.response.status;
      } else if (error.request) {
        this[`status${website}`] = 'Geen respons ontvangen';
      } else {
        this[`status${website}`] = 'Fout bij het maken van het verzoek';
      }
    }
  },
  mounted() {
    this.checkNLWebsiteStatus();
    this.checkENWebsiteStatus();
  }
};
</script>

<style>
/* Stijlen (indien nod
