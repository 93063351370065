<template>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="multiTradersDialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn  rounded color="primary"  v-bind="attrs" v-on="on">
        Rapport uitdraaien
        </v-btn>
      </template>
      <v-card>
        <v-toolbar color="primary" dark>
          Handelar(en) in en uit rapport uitdraaien
          <v-spacer></v-spacer>
          <v-btn @click="resetFields()" small rounded color="warning">
            Velden leeg maken
          </v-btn>
        </v-toolbar>
  
        <v-card-text>
          <v-row>
            
            <v-col cols="12">
              <p><b>Selecteer een handelaar</b></p>
              <v-autocomplete
                dense
                outlined
                :items="traders"
                label="Handelaar selecteren"
                item-text="name"
                item-value="id"
                v-model="report.trader"
                multiple
              ></v-autocomplete>
              
            </v-col>
            <v-col cols="12">
              <label><b>Type selecteren</b></label>
              <v-autocomplete
                dense
                outlined
                :items="typen"
                label="Type selecteren"
                v-model="report.type"
                item-text="name"
                item-value="id"
                multiple
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <label><b>Betaal status(sen)</b></label>
              <v-autocomplete
                dense
                outlined
                :items="statussen"
                label="Status selecteren"
                v-model="report.status"
                item-text="name"
                item-value="id"
                multiple
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="selectedStartDate"
                    label="Startdatum"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="selectedStartDate"
                  @input="startDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="selectedEndDate"
                    label="Einddatum"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="selectedEndDate"
                  @input="endDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="generateReport()">
            Rapport uitdraaien
          </v-btn>
          <v-btn text @click="closeDialog()">Afsluiten</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </template>
  
  <script>
  import jsPDF from "jspdf";
  import "jspdf-autotable";
  import apiTrader from "../api/apiTrader";
  import apiTraderPaymentinout from "../api/apiTraderPaymentinout";
  export default {
    data() {
      return {
        traders: [],
        typen:["Binnenkomend", "Uitgaand"],
        statussen: [
        "Gedeeltelijk betaald",
        "Betaald",
        "Vervallen",
        "Geannuleerd",
        "In behandeling",
        "Teruggestort",
        "Verkeerd aangemaakt"
      ],
        multiTradersDialog: false,
        selectedStartDate: "",
        selectedEndDate: "",
        startDateMenu: false,
        endDateMenu: false,
        report: {
          status: [],
          trader: [],
          type:[],
        },
      };
    },
    mounted() {
    
      this.fetchTraders();
    },
    methods: {
      closeDialog() {
        this.multiTradersDialog = false;
      },
      
      async fetchTraders() {
        try {
          const data = await apiTrader.getTraders();
          this.traders = data;
        } catch (error) {
          console.error("Error fetching traders:", error);
        }
      },
      formatDate(dateString) {
        const options = { year: "numeric", month: "2-digit", day: "2-digit" };
        return new Date(dateString).toLocaleDateString(undefined, options);
      },
      async generateReport() {
  const data = {
    type: this.report.type,
    startDate: this.selectedStartDate,
    endDate: this.selectedEndDate,
    traders: this.report.trader,
    statuses: this.report.status,
  };
  try {
    const response = await apiTraderPaymentinout.generateReport(data);
    if (response && Array.isArray(response.filteredOrders)) {
      const orderData = response.filteredOrders.map((order) => {
        return {
          bedrijfsnaam: order.trader ? order.trader.companyName : "",  // Controleer of trader bestaat
          handelaar: order.trader ? order.trader.name : "",  // Controleer of trader bestaat
          Omschrijving: order.description,  // Beschrijving van de order
          Status: order.status,  // Status van de order
          bedrag: order.amount,  // Bedrag van de order
          type: order.type,  // Type van de order (Ingaand/Uitgaand)
          price: order.amount,  // Bedrag van de order
          createDate: order.createDate,  // Aanmaakdatum van de order
        };
      });

      // Voeg de totaalrij toe
      orderData.push({
        bedrijfsnaam: "Totaal",
        handelaar: "",
        Omschrijving: "",
        Status: "",
        bedrag: "",
        type: "",
        price: response.totalPrice,  // Totale prijs
        createDate: "",  // Leeg voor de totaalrij
      });

      const doc = new jsPDF('landscape');
      const currentDate = new Date().toLocaleDateString();

      doc.setFontSize(18);
      doc.text("Rapport status rapport met datum selectie", 14, 16);
      doc.setFontSize(12);
      doc.text(`Datum uitdraaien: ${currentDate}`, 14, 23);
      doc.text(`Totaalbedrag: €${response.totalPrice}`, 14, 30);

      doc.autoTable({
        startY: 40,
        head: [
          [
            "Bedrijfsnaam",
            "Handelaar",
            "Omschrijving",
            "Status",
            "Bedrag",
            "Type",
            "Prijs",
            "Invoerdatum",
          ],
        ],
        body: orderData.map((item) => [
          item.bedrijfsnaam,
          item.handelaar,
          item.Omschrijving,
          item.Status,
          item.bedrag,
          item.type,
          item.price,
          this.formatDate(item.createDate),
        ]),
      });

      const reportFilename = `HandelaarInOutRapport-${new Date().toLocaleDateString()}.pdf`;
      doc.save(reportFilename);
    } else {
      console.error("Received data is not properly formatted", response);
    }
  } catch (error) {
    console.error("Error generating the report:", error);
  }
},

      resetFields() {
        this.report = {
          status: [],
          trader: [],
        };
        this.selectedStartDate = "";
        this.selectedEndDate = "";
      },
    },
  };
  </script>
  
  <style scoped>
  .headline {
    font-weight: bold;
  }
  </style>
  