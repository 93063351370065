<template>
    <Betalinginformatie />
  </template>
  
  <script>
  import Betalinginformatie from "@/components/bookkeeping/Betalinginformatie";
  export default {
    data() {
      return {
        data: [],
      };
    },
    components: {
        Betalinginformatie,
    },
    metaInfo: {
      title: "Betalinginformatie",
      htmlAttrs: {
        lang: "EN",
        amp: true,
      },
    },
  };
  </script>
  