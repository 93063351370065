<template>
  <div>
    <v-container fluid>
      <v-btn small color="warning" @click="openInstructions">Klant instructies openen</v-btn>
      <AddCustomer v-if="showForm" @cancel="showForm = false" />
      <v-col
        style="display: flex; justify-content: flex-end; margin-bottom: 2px"
      >
        <v-btn color="primary" @click="showForm = true" v-if="!showForm">
          Klant toevoegen
        </v-btn>

        <div style="float: right" v-if="!showForm">
          <v-icon
            :color="showTable ? 'primary' : undefined"
            @click="
              showTable = true;
              showMap = false;
            "
            >mdi-table</v-icon
          >
          <v-icon
            :color="showMap ? 'primary' : undefined"
            @click="
              showTable = false;
              showMap = true;
            "
            >mdi-card-account-details</v-icon
          >
        </div>
      </v-col>
      <v-row>
        <v-col cols="12">
          <CustomersTable v-if="!showForm && showTable && !showMap" />
          <CustomersCard v-if="!showForm && !showTable && showMap" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CustomersTable from "../customers/CustomersTable.vue";
import CustomersCard from "../customers/CustomersCard.vue";
import AddCustomer from "../customers/AddCustomer.vue";
export default {
  components: {
    CustomersTable,
    CustomersCard,
    AddCustomer,
  },
  data: () => ({
    showForm: false,
    showTable: true,
    showMap: false,
  }),
  methods: {
    openInstructions() {
      this.$router.push('/instructies');
    }
  }
};
</script>


<style scoped>
.space {
  margin: 3px;
}
.btn {
  color: white;
}
</style>
