<template>
  <v-container fluid>
    <v-btn color="warning" @click="openInstructions"
      >Ruudrapport instructies openen</v-btn
    >
    <v-row>
      <v-col cols="8">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Selecteer datum"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dates" range no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="$refs.menu.save(dates)">
              Zoeken
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-btn small @click="printTable('invoice-table')" color="primary"
          >Uitprinten</v-btn
        >

        <v-btn small color="warning" @click="pdfExport" style="margin-left: 5px"
          >Als PDF opslaan</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          id="invoice-table"
          :headers="headers"
          :items="items"
          disable-pagination
          :hide-default-footer="true"
          class="elevation-1"
          height="75vh"
          fixed-header
          cellClass="Ruudreport"
          single-expand
          show-expand
          item-key="invoiceDate"
          :expanded.sync="expanded"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-data-table
                v-if="itemMapping[item.invoiceDate]"
                :headers="kavelHeaders"
                :items="itemMapping[item.invoiceDate]"
                disable-pagination
                id="table-x"
                :hide-default-footer="true"
                class="elevation-1"
              >
              </v-data-table>
            </td>
          </template>
          <template v-slot:[`item.invoiceAmount`]="{ value }">
            €{{ value }}
          </template>

          <template v-slot:[`item.amountPaid`]="{ value }">
            €{{ value }}
          </template>
          <template v-slot:[`item.outstandingAmount`]="{ value }">
            €{{ value }}
          </template>
          <template v-slot:[`item.netSales`]="{ value }">€{{ value }}</template>
          <template v-slot:[`item.profit`]="{ value }">€{{ value }}</template>
          <template v-slot:[`item.NF`]="{ value }">€{{ value }}</template>
          <template v-slot:[`item.discounts`]="{ value }">
            €{{ value }}
          </template>
          <template v-slot:[`item.customerCosts`]="{ value }">
            €{{ value }}
          </template>
          <template v-slot:[`item.purchaseCost`]="{ value }">
            €{{ value }}
          </template>
        </v-data-table>
      </v-col>
      <v-card
        id="totals-card"
        class="mt-5"
        v-if="items.length > 0"
        style="width: 100%"
      >
        <v-card-title class="justify-center"> Totalen </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row class="my-2">
              <v-col cols="12" md="6" class="px-4"
                >Totaal FactBedrag:
                <strong
                  >€{{ items[items.length - 1].invoiceAmount }}</strong
                ></v-col
              >
              <v-col cols="12" md="6" class="px-4"
                >Totaal Betaald:
                <strong
                  >€{{ items[items.length - 1].amountPaid }}</strong
                ></v-col
              >
            </v-row>
            <v-row class="my-2">
              <v-col cols="12" md="6" class="px-4"
                >Totaal Openstaand:
                <strong
                  >€{{ items[items.length - 1].outstandingAmount }}</strong
                ></v-col
              >
              <v-col cols="12" md="6" class="px-4"
                >Netto Verkopen:
                <strong>€{{ items[items.length - 1].netSales }}</strong></v-col
              >
            </v-row>
            <v-row class="my-2">
              <v-col cols="12" md="6" class="px-4"
                >Winst:
                <strong>€{{ items[items.length - 1].profit }}</strong></v-col
              >
              <v-col cols="12" md="6" class="px-4"
                >Winstpercentage:
                <strong
                  >{{ items[items.length - 1].profitPercentage }}%</strong
                ></v-col
              >
            </v-row>
            <v-row class="my-2">
              <v-col cols="12" md="6" class="px-4"
                >Kortingen:
                <strong>€{{ items[items.length - 1].discounts }}</strong></v-col
              >
              <v-col cols="12" md="6" class="px-4"
                >Klantenkosten:
                <strong
                  >€{{ items[items.length - 1].customerCosts }}</strong
                ></v-col
              >
            </v-row>
            <v-row class="my-2">
              <v-col cols="12" md="6" class="px-4"
                >Inkoopkosten:
                <strong
                  >€{{ items[items.length - 1].purchaseCost }}</strong
                ></v-col
              >
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import apiReport from "../api/apiReport";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { printTable } from "../../plugins/printTable";
export default {
  data: () => ({
    dates: [],
    totalData: null,
    menu: false,
    expanded: [],
    kavelHeaders: [
      { text: "Kavelnr.", value: "kavelnr", width: 100, sortable: false },
      { text: "Naam", value: "invoiceName", width: 100, sortable: false },
      {
        text: "FactBedrag",
        value: "invoiceAmount",
        align: "right",
        width: 80,
        sortable: false,
      },
      {
        text: "Betaald",
        value: "amountPaid",
        align: "right",
        width: 10,
        sortable: false,
      },
      {
        text: "Openst",
        value: "outstandingAmount",
        align: "right",
        width: 10,
        sortable: false,
      },
      {
        text: "Aantal",
        value: "quantity",
        align: "right",
        width: 10,
        sortable: false,
      },
      {
        text: "Netto Verk",
        value: "netSales",
        align: "right",
        width: 10,
        sortable: false,
      },
      {
        text: "Winst",
        value: "profit",
        align: "right",
        width: 10,
        sortable: false,
      },
      {
        text: "%winst",
        value: "profitPercentage",
        align: "right",
        width: 10,
        sortable: false,
      },
      { text: "N/F", value: "NF", align: "right", width: 80, sortable: false },
      {
        text: "Kortingen",
        value: "discounts",
        align: "right",
        width: 10,
        sortable: false,
      },
      {
        text: "Kosten Kl",
        value: "customerCosts",
        align: "right",
        width: 10,
        sortable: false,
      },
      {
        text: "inkoop",
        value: "purchaseCost",
        align: "right",
        width: 10,
        sortable: false,
      },
    ],
    headers: [
      { text: "Datum", value: "invoiceDate", width: 100, sortable: false },
      {
        text: "FactBedrag",
        value: "invoiceAmount",
        align: "right",
        width: 80,
        sortable: false,
      },
      {
        text: "Betaald",
        value: "amountPaid",
        align: "right",
        width: 10,
        sortable: false,
      },
      {
        text: "Openst",
        value: "outstandingAmount",
        align: "right",
        width: 10,
        sortable: false,
      },
      {
        text: "Aantal",
        value: "quantity",
        align: "right",
        width: 10,
        sortable: false,
      },
      {
        text: "Netto Verk",
        value: "netSales",
        align: "right",
        width: 10,
        sortable: false,
      },
      {
        text: "Winst",
        value: "profit",
        align: "right",
        width: 10,
        sortable: false,
      },
      {
        text: "%winst",
        value: "profitPercentage",
        align: "right",
        width: 10,
        sortable: false,
      },
      { text: "N/F", value: "NF", align: "right", width: 80, sortable: false },
      {
        text: "Kortingen",
        value: "discounts",
        align: "right",
        width: 10,
        sortable: false,
      },
      {
        text: "Kosten Kl",
        value: "customerCosts",
        align: "right",
        width: 10,
        sortable: false,
      },
      {
        text: "inkoop",
        value: "purchaseCost",
        align: "right",
        width: 10,
        sortable: false,
      },
    ],
    items: [],
    itemMapping: {},
  }),
  watch: {
    dates(newVal) {
      if (newVal[0] && newVal[1]) {
        this.fetchData();
      }
    },
  },
  computed: {
    dateRangeText() {
      return this.dates
        .map((date) => new Date(date).toLocaleDateString())
        .join(" ~ ");
    },
  },

  methods: {
    isValidDate(dateString) {
      const date = new Date(dateString);
      return !isNaN(date.getTime());
    },
    async fetchData() {
      if (!this.dates[0] || !this.dates[1]) {
        alert("2 datums moeten ingevuld worden");
        return;
      }
      const sortedDates = [...this.dates].map((date) => new Date(date)).sort();
      console.log(sortedDates);
      try {
        const data = await apiReport.ruudReporting({
          startDate: sortedDates[0],
          endDate: sortedDates[1],
        });
        data.profits.push({
          invoiceDate: "Total",
          NF: Number(data.totals.invoiceAmount / data.totals.quantity).toFixed(
            2
          ),
          profitPercentage: Number(
            (data.totals.profit / data.totals.amountPaid) * 100
          ).toFixed(2),
          invoiceAmount: Number(data.totals.invoiceAmount).toFixed(2),
          amountPaid: Number(data.totals.amountPaid).toFixed(2),
          outstandingAmount: Number(data.totals.outstandingAmount).toFixed(2),
          quantity: data.totals.quantity,
          netSales: Number(data.totals.netSales).toFixed(2),
          discounts: Number(data.totals.discounts).toFixed(2),
          customerCosts: Number(data.totals.customerCosts).toFixed(2),
          purchaseCost: Number(data.totals.purchaseCost).toFixed(2),
          profit: Number(data.totals.profit).toFixed(2),
        });
        this.items = data.profits;
        this.$set(this, "itemMapping", data.dailyMapping);

        console.log({ items: this.items, itemMapping: this.itemMapping });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    printTable,
    async pdfExport() {
      const doc = new jsPDF('landscape');
      const tableData = [];

      this.items.forEach((item) => {
        const rowData = [];
        this.headers.forEach((header) => {
          rowData.push(item[header.value]);
        });
        tableData.push(rowData);
      });

      const colHeaders = this.headers.map((header) => header.text);

      doc.autoTable({
        head: [colHeaders],
        body: tableData,
      });

      doc.save("dagomzet.pdf");
    },

    openInstructions() {
      this.$router.push("/instructies");
    },
  },
};
</script>
<style>
#invoice-table tbody tr:last-child {
  position: sticky;
  bottom: 0;
  background: white;
  font-weight: 900;
}
#table-x tbody tr:last-child {
  font-weight: inherit;
}
</style>
