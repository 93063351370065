<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">

      <v-btn style="margin-left:2px" small color="primary" dark v-bind="attrs" v-on="on">
        Kavel aan order bestelling toevoegen
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Kavelinkavelsatie</span>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-autocomplete
            v-model="kavel"
            :items="kavels"
            item-text="kavelnr"
            item-value="kavelnr"
            outlined
            dense
            label="Kavelnummer"
          ></v-autocomplete>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="updateOrder()"
          >Kavel aan bestelling toevoegen</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Afsluiten
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import apiKavel from "../api/apiKavel";
import apiOrders from "../api/apiOrders";
export default {
  props: {
    orderNumber: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      kavels: [],
      kavel: null, // Add the kavel property
    };
  },
  methods: {
    async fetchKavel() {
      try {
        const data = await apiKavel.getKassaKavels();
        this.kavels = data;
      } catch (error) {
        console.error("Error fetching kavels:", error);
      }
    },
    async updateOrder() {
      try {
        const response = await apiOrders.addOrderLine(
          this.orderNumber,
          this.kavel
        );
        console.log(response);
        this.$swal.fire("Yes!", "Het bijwerken is gelukt!", "success");
        this.$emit("order-updated");
        this.dialog = false;
        this.kavel = "";
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
  mounted() {
    this.fetchKavel();
  },
};
</script>
