import { API } from "./api.js";

export default {
  async createTrader(data) {
    try {
      const response = await API.post("/api/rest/v2/trader", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getTraders() {
    try {
      const response = await API.get("/api/rest/v2/trader");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteTrader(id) {
    try {
      const response = await API.delete(`/api/rest/v2/trader/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateTrader(id, form) {
    try {
      const response = await API.put(`/api/rest/v2/trader/${id}`, form);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getSingleTrader(id) {
    try {
      const response = await API.get(`/api/rest/v2/trader/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
