<template>
  <v-container fluid style="padding-top:4rem">
    <div>
      <v-row>
        <v-col cols="4">
          <v-text-field
            dense
            outlined
            placeholder="Betaalstatus invullen"
            v-model="form.name"
          ></v-text-field>
          <v-textarea
            dense
            v-model="form.description"
            outlined
            placeholder="Omschrijving die handig kan zijn"
          ></v-textarea>
          <v-btn color="primary" @click="addStatus()">
            Betaalstatus toevoegen
          </v-btn>
        </v-col>

        <v-col cols="8">
          <v-card>
            <v-card-title>
              Betaalstatus
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Opzoeken"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <div class="table-container">
              <v-data-table
                :headers="headers"
                :items="statussen"
                :search="search"
                disable-pagination
                :hide-default-footer="true"
                class="elevation-1"
              >
                <template v-slot:[`item.Actions`]="{ item }">
                  <!-- <v-icon small color="green">
                    mdi-eye
                  </v-icon> -->
                  <v-icon
                    small
                    color="red"
                    @click="deleteState(item.id)"
                    v-if="
                      item.name !== 'paid' &&
                        item.name !== 'niet betaald' &&
                        item.name !== 'verrekend'
                    "
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import apiPaymentstatus from "../api/apiPaymentstatus";
export default {
  data: () => ({
    form: {
      name: "",
      description: "",
    },
    statussen: [],
    search: "",
    headers: [
      {
        text: "Naam",
        value: "name",
        sortable: true,
      },

      {
        text: "Omschrijving",
        value: "description",
        sortable: true,
      },

      {
        text: "Acties",
        value: "Actions",
        sortable: false,
      },
    ],
  }),

  async mounted() {
    apiPaymentstatus.getStatussen().then((data) => {
      this.statussen = data;
    });
  },

  methods: {
    resetForm() {
      this.form.name = "";
      this.form.description = "";
    },

    async addStatus() {
      try {
        if (!this.form.name) {
          this.$swal.fire("Foutmelding", "Vul het veld in", "error");
          return;
        }
        const response = await apiPaymentstatus.createStatus(this.form);
        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.resetForm();
        const data = await apiPaymentstatus.getStatussen();
        this.statussen = data;
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },

    async deleteState(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze staat wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiPaymentstatus.deleteStatus(id);
        const data = await apiPaymentstatus.getStatussen();
        this.states = data;
        this.$swal.fire(
          "Verwijderd",
          "De status is succesvol verwijderd",
          "success"
        );
      }
    },
  },
};
</script>
