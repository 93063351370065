<template>
  <v-container style="padding-top: 2rem">
    <v-row>
      <!-- Formulier om kortingscodes toe te voegen -->
      <v-col cols="5">
        <v-form ref="discountForm" v-model="valid" lazy-validation>
          <v-text-field
            label="Kortingspercentage"
            v-model="discountCode.discountPercentage"
            :rules="[rules.required, rules.percentage]"
            required
            type="number"
            step="0.01"
          ></v-text-field>

          <v-menu
            ref="menuValidFrom"
            v-model="menuValidFrom"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="discountCode.validFrom"
                label="Geldig Vanaf"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="discountCode.validFrom"
              @input="menuValidFrom = false"
            ></v-date-picker>
          </v-menu>

          <v-menu
            ref="menuValidTo"
            v-model="menuValidTo"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="discountCode.validTo"
                label="Geldig Tot"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="discountCode.validTo"
              @input="menuValidTo = false"
            ></v-date-picker>
          </v-menu>

          <v-btn :disabled="!valid" color="primary" block @click="submit">
            Opslaan
          </v-btn>
        </v-form>
      </v-col>

      <!-- Tabel om de kortingscodes weer te geven -->
      <v-col cols="7">
        <v-data-table
          :headers="headers"
          :items="discountCodes"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:[`item.discountPercentage`]="{ item }">
            <span>{{ Math.round(item.discountPercentage) }}%</span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon color="red" small @click="deleteDiscount(item.id)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiDiscount from "../api/apiDiscount";

export default {
  data() {
    return {
      valid: false,
      discountCode: {
        id: null,
        discountPercentage: null,
        applicableKavelNr: null,
        validFrom: null,
        validTo: null,
      },
      discountCodes: [], // Data voor de tabel
      headers: [
        { text: "Code", value: "code" },
        { text: "Kortingspercentage", value: "discountPercentage" },
        { text: "Geldig Vanaf", value: "validFrom" },
        { text: "Geldig Tot", value: "validTo" },
        { text: "Acties", value: "actions", sortable: false },
      ],
      rules: {
        required: (value) => !!value || "Dit veld is verplicht",
        percentage: (value) =>
          (value >= 0 && value <= 100) ||
          "Percentage moet tussen 0 en 100 zijn",
      },
      menuValidFrom: false,
      menuValidTo: false,
    };
  },

  async mounted() {
    this.fetchdata();
  },
  methods: {
    async deleteDiscount(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze kortingscode wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiDiscount.deleteDiscount(id);
        await this.getDiscounts();
        this.$swal.fire(
          "Verwijderd",
          "De kortingscode is succesvol verwijderd",
          "success"
        );
      }
    },
    async fetchdata() {
      apiDiscount.getDiscounts().then((data) => {
        this.discountCodes = data;
      });
    },
    async submit() {
      if (this.$refs.discountForm.validate()) {
        try {
          const response = await apiDiscount.createDiscount(this.discountCode);
          console.log(response);
          this.discountCodes.push(response.discountCode);
          this.$swal.fire({
            title: "Yes!",
            html: `Het aanmaken is gelukt! Kortingscode is ${response.discountCode.code} <br><br>`,
            icon: "success",
            confirmButtonText: "Sluiten",
          });

          // Formulier resetten
          this.resetForm();
        } catch (error) {
          console.error(error);
          this.$swal.fire(
            "Er is een foutmelding!",
            "Probeer het nogmaals!",
            "error"
          );
        }
      }
    },

    resetForm() {
      this.discountCode = {
        id: null,
        discountPercentage: null,
        applicableKavelNr: null,
        validFrom: null,
        validTo: null,
      };
    },
    editDiscount(item) {
      this.discountCode = { ...item };
    },
  },
};
</script>
