<template>
  <div>
    <v-dialog v-model="dialogVisible" max-width="500px">
      <template v-slot:activator="{ on }">
       
          <v-btn small rounded color="primary" block  dark v-on="on"
            >Rapportage uitdraaien</v-btn
          >

      </template>
      <v-card>
          <v-toolbar color="primary" dark style="justify-content: center">
      Bestellingen
        </v-toolbar>
        <v-card-title>
         
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="generateReport">
            <v-container fluid>
              <v-row>
                
                <v-col cols="12">
                  <v-select
                    v-model="selectedName"
                    :items="orders.map((order) => order.name)"
                    label="Naam persoon"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-menu
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="selectedStartDate"
                        label="Startdatum"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="selectedStartDate"
                      @input="startDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="selectedEndDate"
                        label="Einddatum"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="selectedEndDate"
                      @input="endDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-btn color="primary" text @click="dialogVisible = false"
                >Annuleren</v-btn
              >
              <v-btn color="primary" type="submit">Rapportage genereren</v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
import apiOrders from "../api/apiOrders";

export default {
  data() {
    return {
      reportName: "",
      selectedName: "",
      orders: [],
      dialogVisible: false,
      selectedStartDate: "",
      selectedEndDate: "",
      startDateMenu: false,
      endDateMenu: false,
    };
  },

  async mounted() {
    try {
      const data = await apiOrders.getorder();
      const orders = data;
      const groupedOrders = {};

      orders.forEach((order) => {
        const email = order.email;
        if (!groupedOrders[email]) {
          groupedOrders[email] = order.name;
        }
      });

      this.orders = Object.entries(groupedOrders).map(([email, name]) => ({
        email,
        name,
      }));
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    async generateReport() {
      if (!this.reportName) {
        this.$swal.fire("Voer een rapportnaam in.", "error");

        return;
      }
      const data = {
        personName: this.selectedName,
        startDate: this.selectedStartDate,
        endDate: this.selectedEndDate,
      };
      const order = await apiOrders.getCustomerReport(data);
      const orderData = await XLSX.utils.json_to_sheet(order);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, orderData, "data");
      const reportFilename = `WebshopRapport-${new Date().toLocaleDateString()}.xlsx`;
      XLSX.writeFile(wb, `${reportFilename}.xlsx`);
    },
  },
};
</script>
