<template>
  <div>
    <v-dialog v-model="dialogVisible" max-width="500px">
      <template v-slot:activator="{ on }">
       
          <v-btn small rounded color="primary" block  dark v-on="on"
            >BoekhoudRapportage uitdraaien</v-btn
          >
      
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Rapportage Formulier</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="generateBookkeepingReport">
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                    <v-text-field
                    dense
                    v-model="reportName"
            label="Naam van het rapport"
          ></v-text-field>
                
                </v-col>
                <v-col cols="12">
                   
                  <v-autocomplete
                    dense
                    :items="betaalstatussen.flat()"
                    label="Betaalstatus selecteren"
                    item-text="name"
                    item-value="name"
                    v-model="selectedBetaalStatus"
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    dense
                    :items="orderstatussen.flat()"
                    label="Orderstatus selecteren"
                    item-text="name"
                    item-value="name"
                    v-model="selectedStatus"
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-menu
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="selectedStartDate"
                        label="Startdatum"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="selectedStartDate"
                      @input="startDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="selectedEndDate"
                        label="Einddatum"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="selectedEndDate"
                      @input="endDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-btn color="primary" text @click="dialogVisible = false"
                >Annuleren</v-btn
              >
              <v-btn color="primary" type="submit">Rapportage genereren</v-btn>
              <v-btn color="warning" @click="resetForm()">Reset</v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import apiOrderstatus from "../api/apiOrderstatus";
import apiPaymentstatus from "../api/apiPaymentstatus";
import * as XLSX from "xlsx";
import apiReport from "../api/apiReport";
export default {
  data() {
    return {
        reportName: "",
      orderstatussen: [],
      betaalstatussen: [],
      orders: [],
      selectedBetaalStatus: "",
      selectedStatus: "",
      dialogVisible: false,
      selectedStartDate: "",
      selectedEndDate: "",
      startDateMenu: false,
      endDateMenu: false,
    };
  },

  async mounted() {
    apiOrderstatus.getStatussen().then((data) => {
      this.orderstatussen = data;
    });
    apiPaymentstatus.getStatussen().then((data) => {
      this.betaalstatussen = data;
    });
  },

  methods: {
    resetFormAndCloseDialog() {
      this.selectedBetaalStatus = "";
      this.selectedStatus = "";
      this.selectedStartDate = "";
      this.selectedEndDate = "";
      this.dialogVisible = false;
    },
    resetForm() {
      this.selectedBetaalStatus = "";
      this.selectedStatus = "";
      this.selectedStartDate = "";
      this.selectedEndDate = "";
    },

    async generateBookkeepingReport() {
      if (!this.reportName) {
        this.$swal.fire("Voer een rapportnaam in.", "error");
       
        return;
      }
      const data = {
        betaalStatus: this.selectedBetaalStatus,
        status: this.selectedStatus,
        startDate: this.selectedStartDate,
        endDate: this.selectedEndDate,
      };
      this.resetFormAndCloseDialog();
      const order = await apiReport.reportWebshopOrders(data);
      const orderData = await XLSX.utils.json_to_sheet(order);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, orderData, "Data");
      XLSX.writeFile(wb, `${this.reportName}.xlsx`);
    },
  },
};


</script>
