<template>
  <v-container fluid style="padding-top: 4rem">
    <div>
      <v-row> </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              Continenten
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Continent opzoeken"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <div class="table-container">
              <v-data-table
                :headers="headers"
                :items="continents"
                :search="search"
                height="800px"
                fixed-header
                disable-pagination
                :hide-default-footer="true"
                class="elevation-1"
              >
              </v-data-table>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import apiContinent from "../api/apiContinent";
export default {
  data: () => ({
    continents: [],
    search: "",
    dialog: false,
    continent: {
      id: "",
    },
    headers: [
      {
        text: "Naam Nederlands",
        value: "name",
        sortable: true,
      },

      {
        text: "Naam Engels",
        value: "nameEng",
        sortable: true,
      },
      {
        text: "Ebaynummer",
        value: "ebayCode",
        sortable: true,
      },
    ],
  }),

  async mounted() {
    this.fetchContinents();
  },
  methods: {
    async fetchContinents() {
      const data = await apiContinent.getContinents();
      this.continents = data;
    },
  },
};
</script>
