import { API } from "./api.js";
export default {
 
  async getContinents() {
    try {
      const response = await API.get("/api/rest/v2/continent");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
 
  async editContinent(id, data) {
    try {
      const response = await API.put(`/api/rest/v2/continent/${id}`,data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

};
