<template>
  <v-container fluid>
    <div style="display: flex">
      <HandelaarBetalingenuitRapportage />
      <BetalingToevoegen @payment-added="getPaymentData" />
    </div>

    <div>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              Betalingen in en uit
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Opzoeken"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <div class="table-container">
              <v-data-table
                :headers="headers"
                :items="payments"
                :search="search"
                disable-pagination
                :hide-default-footer="true"
                class="elevation-1"
              >
                <template v-slot:[`item.status`]="{ item }">
                  <span v-if="item.status == 'Betaald'">
                    <v-chip class="ma-2" color="green" outlined>
                      {{ item.status }}
                    </v-chip>
                  </span>

                  <span v-if="item.status == 'Gedeeltelijk betaald'">
                    <v-chip class="ma-2" color="orange" outlined>
                      {{ item.status }}
                    </v-chip>
                  </span>

                  <span
                    v-if="
                      item.status != 'Gedeeltelijk betaald' &&
                      item.status != 'Betaald'
                    "
                  >
                    <v-chip class="ma-2" color="red" outlined>
                      {{ item.status }}
                    </v-chip>
                  </span>
                </template>

                <template v-slot:[`item.restamount`]="{ item }">
                  €{{ calculateOutstandingAmount(item) }}
                  <v-tooltip
                    bottom
                    color="warning"
                    v-if="
                      item.status == 'Betaald' &&
                      calculateOutstandingAmount(item) > 0
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        color="warning"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        Aandacht nodig
                      </v-btn>
                    </template>
                    <span
                      >Betaling is niet correct afgerond, bedrag moet op 0 gezet
                      worden.</span
                    >
                  </v-tooltip>
                </template>
                <template v-slot:[`item.trader.companyName`]="{ item }">
                  <span v-if="item.trader">
                    {{ item.trader.companyName }}
                  </span>
                  <span v-else> Geen bedrijf </span>
                </template>

                <template v-slot:[`item.trader.name`]="{ item }">
                  <span v-if="item.trader">
                    {{ item.trader.name }}
                  </span>
                  <span v-else> Geen naam </span>
                </template>

                <template v-slot:[`item.amount`]="{ item }">
                  €{{ item.amount }}
                </template>
                <template v-slot:[`item.createDate`]="{ item }">
                  {{ formatDate(item.createDate) }}
                </template>

                <template v-slot:[`item.Actions`]="{ item }">
                  <v-icon small color="green" @click="openPaymentInfo(item.id)">
                    mdi-eye
                  </v-icon>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import HandelaarBetalingenuitRapportage from "./HandelaarBetalingenuitRapportage.vue";
import BetalingToevoegen from "./BetalingToevoegen.vue";
import apiTraderPaymentinout from "../api/apiTraderPaymentinout";
import { format } from "date-fns";
export default {
  components: {
    BetalingToevoegen,
    HandelaarBetalingenuitRapportage,
  },
  data: () => ({
    payments: [],
    search: "",
    headers: [
      {
        text: "Bedrijf",
        value: "trader.companyName",
        sortable: true,
      },
      {
        text: "Naam",
        value: "trader.name",
        sortable: true,
      },
      {
        text: "Omschrijving",
        value: "description",
        sortable: true,
      },
      {
        text: "Openstaand bedrag",
        value: "outstandingAmount",
        sortable: true,
      },
      {
        text: "Type betaling",
        value: "type",
        sortable: true,
      },
      {
        text: "Bedrag",
        value: "amount",
        sortable: true,
      },
      {
        text: "Status",
        value: "status",
        sortable: true,
      },
      {
        text: "Aanmaakdatum",
        value: "createDate",
        sortable: true,
      },
      {
        text: "Acties",
        value: "Actions",
        sortable: false,
      },
    ],
  }),

  async mounted() {
    this.getPaymentData();
  },

  methods: {
    openInstructions() {
      this.$router.push("/instructies");
    },
    calculateOutstandingAmount(item) {
      if (!item.amount || !item.paymentHistories) {
        return 0;
      }
      const totalPaid = item.paymentHistories.reduce(
        (total, history) => total + parseFloat(history.amountpaid),
        0
      );
      return parseFloat(item.amount) - totalPaid;
    },

    openPaymentInfo(id) {
      this.$router.push(`/HandelaarBetalinginformatie/${id}`);
    },

    resetForm() {
      this.form.name = "";
      this.form.description = "";
    },

    getPaymentData() {
      apiTraderPaymentinout.getPayments().then((data) => {
        this.payments = data;
      });
    },
    formatDate(date) {
      return format(new Date(date), "dd-MM-yyyy");
    },
  },
};
</script>
