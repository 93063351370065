

import { API } from "./api.js";
export default {
 
  async updateKavelNumbers(data) {
    try {
      const response = await API.post("/api/rest/v2/changekavelnumbers",data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
 
};
