=
<template>
  <v-dialog transition="dialog-top-transition" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small rounded color="primary" block v-bind="attrs" v-on="on"
        >Handelaar (verkocht) rapport</v-btn
      >
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar color="primary" dark
          >Selectie maken voor het genereren van een rapport</v-toolbar
        >
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <label> <b> Kavel Status</b> </label>
              <v-text-field
                dense
                outlined
                v-model="report.status"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <p><b>Selecteer een handelaar </b></p>
              <v-autocomplete
                dense
                outlined
                :items="traders.flat()"
                label="Handelaar selecteren"
                item-text="name"
                item-value="id"
                v-model="report.trader"
                multiple
              ></v-autocomplete>
            </v-col>

            <v-col cols="6">
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="selectedStartDate"
                    label="Startdatum"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="selectedStartDate"
                  @input="startDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="selectedEndDate"
                    label="Einddatum"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="selectedEndDate"
                  @input="endDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="generateReport()"
            >Rapport genereren</v-btn
          >
          <v-btn text @click="dialog.value = false">Afsluiten</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import jsPDF from "jspdf";
import apiKavel from "../api/apiKavel";
import apiTrader from "../api/apiTrader";
import apiStatus from "../api/apiStatus";
export default {
  data() {
    return {
      traders: [],
      statussen: [],
      selectedStartDate: "",
      selectedEndDate: "",
      startDateMenu: false,
      endDateMenu: false,

      report: {
        trader: [],
        status: "Verkocht",
      },
      headers: [
        {
          text: "Knr",
          value: "kavelNumber",
          sortable: true,
        },

        {
          text: "OV_and",
          value: "land",
          sortable: true,
        },

        {
          text: "Emb",
          value: "embcode",
          sortable: false,
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
        },

        {
          text: "Datuminv",
          value: "createDate",
          sortable: false,
        },

        {
          text: "Inkoop",
          value: "purchasePrice",
          sortable: false,
        },

        {
          text: "PPC prijs",
          value: "price",
          sortable: false,
        },

        {
          text: "Winst",
          value: "winst",
          sortable: false,
        },

        {
          text: "Winst perc",
          value: "winstPerc",
          sortable: false,
        },
      ],
    };
  },

  mounted() {
    apiStatus.getStatus().then((data) => {
      this.statussen = data;
    });
    apiTrader.getTraders().then((data) => {
      this.traders = data;
    });
  },
  methods: {
  formatDate(dateString) {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  },
  async generateReport() {
    const data = {
      traderId: this.report.trader,
      startSoldDate: this.selectedStartDate,
      endSoldDate: this.selectedEndDate,
    };
    try {
      const response = await apiKavel.getKavelSoldReport(data);
      if (response && Array.isArray(response.kavelResponse)) {
        const kavelsData = [...response.kavelResponse];
        
        // Voeg een samenvatting toe met het totaalbedrag
        kavelsData.push({
          kavelnr: "Totaal",
          inhoudNL: "",
          embNed: "",
          landName: "",
          kenmerk: "",
          price: response.totalPrice,
          createDate: "",
        });

        console.log("kavelsData", kavelsData);

        const doc = new jsPDF("landscape");
        const currentDate = new Date().toLocaleDateString();

        // Voeg titel en datum toe aan het rapport
        doc.setFontSize(18);
        doc.text("Kavels verkocht op rapport", 14, 16);
        doc.setFontSize(12);
        doc.text(`Datum uitdraaien: ${currentDate}`, 14, 23);
        doc.text(`Totaalbedrag: €${response.totalPrice}`, 14, 30);

        // Voeg de tabel toe aan het PDF-document
        doc.autoTable({
          startY: 40,
          head: [
            [
              "Kavel",
              "Inhoud",
              "Embcode",
              "Land",
              "Status",
              "Kenmerk",
              "Prijs",
              "Verkocht op",
              "Aanmaakdatum"
            ],
          ],
          body: kavelsData.map((item) => [
            item.kavelnr,                        // Correcte veldnaam voor kavelnummer
            item.inhoudNL || "",                 // Inhoud in het Nederlands
            item.embNed || "",                   // Embcode in het Nederlands
            item.landName || "",                 // Landnaam
            item.statusName || "",               // Status naam
            item.kenmerk || "",                  // Kenmerk
            item.price ? `€${item.price}` : "",  // Prijs
            item.soldDate                      // Formatteer de datum als deze bestaat
              ? this.formatDate(item.soldDate)
              : "",
            item.createDate                      // Formatteer de datum als deze bestaat
              ? this.formatDate(item.createDate)
              : "",
          ]),
        });

        const reportFilename = `Handelaar-${new Date().toLocaleDateString()}.pdf`;
        doc.save(reportFilename);
      } else {
        console.error("Received data is not properly formatted", response);
      }
    } catch (error) {
      console.error("Error generating the report:", error);
    }
  },
}
}
</script>