<template>
  <v-dialog v-model="dialog4" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small color="warning" dark v-bind="attrs" v-on="on">
        Bedrag toevoegen
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Kavelinformatie</span>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                dense
                label="Omschrijving*"
                v-model="form.description"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                dense
                label="Bedrag*"
                required
                type="number"
                v-model="form.amount"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="addAmount()">
          Bedrag toevoegen
        </v-btn>
        <v-btn color="blue darken-1" text @click="dialog4 = false">
          Afsluiten
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import apiOrder from "../api/apiOrders";
export default {
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    dialog4: false,
    form: {
      description: "",
      amount: "",
    },
  }),
  methods: {
    async order() {
      if (this.form.description.length < 1 || this.form.amount == 0) {
        return this.$swal.fire(
          "Error",
          "Bedrag en omschrijving mogen niet leeg zijn",
          "error"
        );
      }
      console.log(
        "Bedrag" +
          this.form.amount +
          " Omschrijving " +
          this.form.description +
          " kavelnummer " +
          this.orderId
      );
    },
    reset() {
      this.form.amount = "";
      this.form.description = null;
      this.dialog4 = false;
    },
    async addAmount() {
      try {
        const data = {
          discount: this.form.amount,
          description: this.form.description,
        };
        const id = this.orderId;
        const response = await apiOrder.addDiscount(id, data);
        console.log(response);
        this.$swal.fire("Ja!", "Het aanpassen is gelukt!", "success");
        this.reset();
        this.$emit("order-updated");
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een fout opgetreden!",
          "Probeer het opnieuw!",
          "error"
        );
      }
    },
  },
};
</script>
