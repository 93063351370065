import { API } from "./api.js";

export default {
  async createAndDownloadOrder(data) {
    const response = await API.post("/api/rest/v2/kassa", data, {
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "factuur.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  },
  async pinOrder(data) {
    try {
      const response = await API.post("/api/rest/v2/kassa/pin", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async paySingleOrder(data) {
    try {
      const response = await API.post("/api/rest/v2/kassa/pin/order", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
