<template>
  <v-dialog
    transition="dialog-top-transition"
    max-width="600"
    v-model="statusReportdialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        rounded
        color="primary"
        v-bind="attrs"
        v-on="on"
        style="margin-left: 5px"
      >
        status uitdraaien
      </v-btn>
    </template>
    <template>
      <v-card>
        <v-toolbar color="primary" dark style="justify-content: center">
          Status Rapport
        </v-toolbar>
        <v-card-text>
          <p style="color: red; font-weight: bold">
            Hier kun je de status selecteren en dan op Rapport uitdraaien
            klikken.
          </p>
          <v-col cols="12">
            <label> <b> Kavel Status</b> </label>
            <v-autocomplete
              dense
              outlined
              :items="statussen.flat()"
              label="Status selecteren"
              v-model="status"
              item-text="name"
              item-value="id"
            ></v-autocomplete>
          </v-col>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="generateStatusReport()"
            >Kavelstatus rapport uidraaien</v-btn
          >
          <v-btn text @click="closeDialog">Afsluiten</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import "jspdf-autotable";
import jsPDF from "jspdf";
import apiStatus from "../api/apiStatus";
import apiKavel from "../api/apiKavel";
export default {
  data() {
    return {
      statussen: [],
      statusReportdialog: false,
      status: "",
      reportName: "",
      totalPrice: 0,
    };
  },

  mounted() {
    this.fetchKavelStatus();
  },
  methods: {
    closeDialog() {
      this.statusReportdialog = false;
    },
    resetInfo() {
      this.totalPrice = 0;
    },
    async fetchKavelStatus() {
      try {
        const data = await apiStatus.getStatus();
        this.statussen = data;
      } catch (error) {
        console.error("Error fetching statuses:", error);
      }
    },

    formatDate(dateString) {
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },

    async generateStatusReport() {
      const data = {
        status: this.status,
      };
      try {
        const response = await apiKavel.generateKavelStatusReport(data);
        if (response && Array.isArray(response.kavelResponse)) {
          const kavelsData = [...response.kavelResponse];
          kavelsData.push({
            kavelnr: "Totaal",
            inhoudNL: "",
            embNed: "",
            landName: "",
            statusName: "",
            kenmerk: "",
            price: "",
            createDate: "",
            totalprice: response.totalPrice,
          });

          console.log("kavelsData", kavelsData);

          const doc = new jsPDF("landscape");
          const currentDate = new Date().toLocaleDateString();

          // Add report title and date
          doc.setFontSize(18);
          doc.text("Status rapport", 14, 16);
          doc.setFontSize(12);
          const aantalItems = response.kavelResponse.length;
          doc.text(`Datum uitdraaien: ${currentDate}`, 14, 23);
          doc.text(`Aantal kavels: ${aantalItems}`, 14, 30);
          doc.text(`Totaalbedrag: €${response.totalPrice}`, 14, 37);

          // Date parsing function
          const parseDate = (dateString) => {
            const [day, month, year] = dateString.split("/");
            return new Date(`${year}-${month}-${day}`);
          };

          // Add the table
          doc.autoTable({
            startY: 40,
            head: [
              [
                "Kavel",
                "Inhoud",
                "Embcode",
                "Land",
                "Status",
                "Kenmerk",
                "Prijs",
                "Create Date",
              ],
            ],
            body: kavelsData.map((item) => [
              item.kavelnr,
              item.inhoud,
              item.embcode,
              item.land,
              item.status,
              item.kenmerk,
              item.prijs,
              item.createDate
                ? parseDate(item.createDate).toLocaleDateString()
                : "",
            ]),
          });

          const reportFilename = `Statusrapport-${new Date().toLocaleDateString()}.pdf`;
          doc.save(reportFilename);
        } else {
          console.error("Received data is not properly formatted", response);
        }
      } catch (error) {
        console.error("Error generating the report:", error);
      }
    },
  },
};
</script>
