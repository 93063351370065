<template>
  <v-container fluid style="padding-top: 4rem">
    <div>
      <v-row>
        <v-col cols="4">
          <v-text-field
            dense
            outlined
            placeholder="Embcode Nederlandse invullen"
            v-model="form.nameNL"
          ></v-text-field>

          <v-text-field
            dense
            outlined
            placeholder="Embcode Engelse vertaling"
            v-model="form.nameEN"
          ></v-text-field>
          <v-textarea
            dense
            v-model="form.description"
            outlined
            placeholder="Omschrijving die handig kan zijn"
          ></v-textarea>
          <v-btn color="primary" @click="addEmbcode()">
            Embcode toevoegen
          </v-btn>
        </v-col>

        <v-col cols="8">
          <v-card>
            <v-card-title>
              Embcode
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Embcode opzoeken"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <div class="table-container">
              <v-data-table
                :headers="headers"
                :items="types"
                :search="search"
                height="800px"
                fixed-header
                disable-pagination
                :hide-default-footer="true"
                class="elevation-1"
              >
              </v-data-table>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import apiType from "../api/apiEmbCode";
export default {
  data: () => ({
    form: {
      nameNL: "",
      nameEN: "",
      description: "",
    },
    types: [],
    search: "",
    headers: [
      {
        text: "Naam NL",
        value: "nameNL",
        sortable: true,
      },

      {
        text: "Naam EN",
        value: "nameEN",
        sortable: true,
      },

      // {
      //   text: "Acties",
      //   value: "Actions",
      //   sortable: false,
      // },
    ],
  }),

  async mounted() {
    apiType.getTypes().then((data) => {
      this.types = data;
    });
  },

  methods: {
    resetForm() {
      this.form.nameNL = "";
      this.form.nameEN = "";
      this.form.description = "";
    },
    async addEmbcode() {
      try {
        if (!this.form.nameNL || !this.form.nameEN) {
          this.$swal.fire(
            "Foutmelding",
            "Controleer of alle velden zijn ingevuld",
            "error"
          );
          return;
        }

        const response = await apiType.createType(this.form);

        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.resetForm();
        const data = await apiType.getTypes();
        this.types = data;
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },

    async deleteType(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze type wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiType.deleteType(id);
        const data = await apiType.getTypes();
        this.types = data;
        this.$swal.fire(
          "Verwijderd",
          "De Staat is succesvol verwijderd",
          "success"
        );
      }
    },
  },
};
</script>
