
<template>
    <EbaySettings />
  </template>
  
  <script>
  import EbaySettings from "@/components/ebay/EbaySettings";
  export default {
    data() {
      return {
        data: [],
      };
    },
    components: {
      EbaySettings,
    },
    metaInfo: {
      title: "Betaalstatus aanmaken  | Postzegels ",
      htmlAttrs: {
        lang: "EN",
        amp: true,
      },
    },
  };
  </script>