
<template>
  <Customers />
</template>

<script>
import Customers from "@/components/customers/Customers";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Customers,
  },
  metaInfo: {
 title: "Klanten overzicht",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>