import { API } from "./api.js";
export default {
  async createType(data) {
    try {
      const response = await API.post("/api/rest/v2/embcode", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getTypes() {
    try {
      const response = await API.get("/api/rest/v2/embcode");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteType(id) {
    try {
      const response = await API.delete(`/api/rest/v2/embcode/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateType(id, form) {
    try {
      const response = await API.put(`/api/rest/v2/embcode/${id}`, form);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getSingleType(id) {
    try {
      const response = await API.get(`/api/rest/v2/embcode/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
