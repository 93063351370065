<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-container fluid>
            <v-card>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    dark
                    v-on="on"
                    style="margin-left: 10px; margin-top: 10px"
                  >
                    Exporteren
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="item in exportOptions"
                    :key="item.text"
                    @click="exportData(item.type)"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-card-title>
                Klanten
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Opzoeken"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <div class="table-container">
                <v-data-table
                  :headers="headers"
                  :items="customers"
                  :search="search"
                  disable-pagination
                  :hide-default-footer="true"
                  class="elevation-1"
                  id="my-customers"
                  cellClass="printable"
                >
                  <template v-slot:[`item.Actions`]="{ item }">
                    <v-icon small color="green" @click="openCustomer(item.id)">
                      mdi-eye
                    </v-icon>
                    <v-icon small color="red" @click="deleteCustomer(item.id)">
                      mdi-delete
                    </v-icon>

                    <v-menu bottom left v-if="!item.email == ''">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                          <v-icon small color="blue"> mdi-email </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-list-item-title>
                            <paymentReminder
                              :name="item.name"
                              :email="item.email"
                            />
                          </v-list-item-title>
                        </v-list-item>
                        <!-- <v-list-item @click="sendPaymentRequest(item)">
                          <v-list-item-title>
                            Betaalverzoek sturen
                          </v-list-item-title>
                        </v-list-item> -->
                        <v-list-item>
                          <v-list-item-title>
                            <emptyMail :name="item.name" :email="item.email" />
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                  <template> </template>
                </v-data-table>
              </div>
            </v-card>
            <div class="text-center">
              <v-pagination
                v-model="page"
                class="my-4"
                :key="totalPages"
                :length="totalPages"
                @input="onPageChange"
              ></v-pagination>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import emptyMail from "../mail/emptyMail";
import paymentReminder from "../mail/paymentReminder";
import apiCustomer from "../api/apiCustomer";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";


function debounce(func, delay) {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
}

export default {
  components: {
    paymentReminder,
    emptyMail,
  },
  data: () => ({
    page: 1,
    exportOptions: [
      {
        text: "Export naar Excel",
        type: "excel",
        icon: "mdi-file-excel-outline",
      },
      { text: "Export naar PDF", type: "pdf", icon: "mdi-file" },
      { text: "Afdrukken", type: "print", icon: "mdi-printer" },
    ],

    customers: [],
    totalPages: 1,
    search: "",
    headers: [
      {
        text: "Intialen",
        value: "initials",
        sortable: true,
      },

      {
        text: "Naam",
        value: "name",
        sortable: true,
      },
      {
        text: "Email",
        value: "email",
        sortable: true,
      },

      {
        text: "adres",
        value: "adres",
        sortable: true,
      },

      {
        text: "Telefoonnummer",
        value: "phoneNumber",
        sortable: true,
      },
      {
        text: "Aanmaak Datum",
        value: "create_date",
        sortable: true,
      },

      {
        text: "Acties",
        value: "Actions",
        sortable: false,
      },
    ],
  }),

  async mounted() {
    await this.getCustomers(this.page);
    // await this.getPageNumber();
  },
  watch: {
    search(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.queryHandler();
      }
    },
  },
  methods: {
    queryHandler: debounce(function () {
      this.page = 1;
      this.getCustomers(1);
    }, 500),
    async getCustomers(pageNumber) {
      await apiCustomer.getCustomers(pageNumber, this.search).then((data) => {
        this.customers = data.customers;
        this.totalPages = data.totalPages;
      });
    },
    getName(name) {
      return name;
    },
    getEmail(email) {
      return email;
    },
    openCustomer(id) {
      this.$router.push(`/Klant/bewerken/${id}`);
    },
    async deleteCustomer(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze Klant wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiCustomer.deleteCustomer(id);
        await this.getCustomers(this.page);
        this.$swal.fire(
          "Verwijderd",
          "De Klant is succesvol verwijderd",
          "success"
        );
      }
    },
    exportData(type) {
      if (type === "excel") {
        const worksheet = XLSX.utils.json_to_sheet(this.customers);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Klantenoverzicht.xlsx");
      } else if (type === "pdf") {
        const doc = new jsPDF('landscape');
        const tableData = [];
        this.customers.forEach((item) => {
          const rowData = [];
          this.headers.forEach((header) => {
            rowData.push(item[header.value]);
          });
          tableData.push(rowData);
        });
        const colHeaders = this.headers.map((header) => header.text);
        doc.autoTable({
          head: [colHeaders],
          body: tableData,
        });
        doc.save("Klantenoverzicht.pdf");
      } else if (type === "print") {
        const printContents = document.getElementById("my-customers").outerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
      }
    },

    onPageChange(pageNumber) {
      this.page = pageNumber;
      this.getCustomers(pageNumber);
    },
  },
};
</script>
<style>
.table-container {
  height: 500px;
  overflow-y: auto;
}

.v-data-table__overflow {
  overflow: hidden !important;
}
</style>
