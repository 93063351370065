import { API } from "./api.js";

export default {
  async createShippingcostLands(data) {
    try {
      const response = await API.post(
        "/api/rest/v2/shppingcostcontroller",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getShippingcostLands() {
    try {
      const response = await API.get("/api/rest/v2/shppingcostcontroller");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async updateShippingcostLand(id, form) {
    try {
      const response = await API.put(
        `/api/rest/v2/shppingcostcontroller/${id}`,
        form
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getShippingcostLand(id) {
    try {
      const response = await API.get(
        `/api/rest/v2/shppingcostcontroller/${id}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
