<template>
    <v-row>
      <v-col cols="auto">
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-btn rounded color="primary" v-bind="attrs" v-on="on"> Betaling Invoeren</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Nieuwe in en out toevoegen</span>
            </v-card-title>
            <v-card-text>
              <v-form ref="form">
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      outlined
                      dense
                      v-model="newPayment.company"
                      label="Bedrijfsnaam"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      outlined
                      dense
                      v-model="newPayment.name"
                      label="Naam"
                      required
                    ></v-text-field>
                  </v-col>
  
                  <v-col cols="6">
                    <v-text-field
                      outlined
                      dense
                      v-model="newPayment.amount"
                      label="Bedrag"
                      prefix="Euro"
                      type="number"
                      required
                    ></v-text-field>
                  </v-col>
  
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="newPayment.status"
                      :items="statussen"
                      outlined
                      dense
                      label="Status"
                    ></v-autocomplete>
                  </v-col>
  
                  <v-col cols="6">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          v-model="newPayment.date"
                          label="Factuurdatum"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="newPayment.date"
                        :active-picker.sync="activePicker"
                        :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10)"
                        min="1950-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
  
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="newPayment.type"
                      :items="items"
                      outlined
                      dense
                      label="Type"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      outlined
                      dense
                      v-model="newPayment.description"
                      label="Omschrijving"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn color="error" @click="dialog = false">Annuleren</v-btn>
              <v-btn color="primary" @click="addPayment()">Opslaan</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </template>
  
  <script>
  import apiPaymentinout from "../api/apiPaymentinout";
  export default {
    data: () => ({
      items: ["Binnenkomend", "Uitgaand"],
      statussen: [
        "Gedeeltelijk betaald",
        "Betaald",
        "Vervallen",
        "Geannuleerd",
        "In behandeling",
        "Teruggestort",
      ],
      dialog: false,
      activePicker: null,
      menu: false,
      newPayment: {
        company: "",
        name: "",
        description: "",
        amount: "",
        status: "",
        type: "",
        date: "",
      },
    }),
  
    watch: {
      menu(val) {
        val && setTimeout(() => (this.activePicker = "YEAR"));
      },
    },
    methods: {
      resetForm() {
        this.newPayment = {
          company: "",
          name: "",
          description: "",
          amount: "",
          status: "",
          type: "",
          date: "",
        };
      },
  
      save(date) {
        this.newPayment.date = date;
        this.menu = false;
      },
  
      async addPayment() {
        try {
          const response = await apiPaymentinout.createPayment(this.newPayment);
          console.log(response);
          this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
          this.resetForm();
          this.dialog = false;
          this.$emit("payment-added");
        } catch (error) {
          console.error(error);
          this.$swal.fire("Er is een foutmelding!", "Probeer het nogmaals!", "error");
        }
      },
    },
  };
  </script>
  