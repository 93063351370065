import { API } from "./api.js";
export default {
  async createAsset(data) {
    try {
      const response = await API.post("/api/rest/v2/kavelfolder", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getDirectories() {
    try {
      const response = await API.get("/api/rest/v2/kavelfolder");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getDirectoriesFiles(folderName) {
    try {
      const response = await API.get(`/api/rest/v2/kavelfolder/${folderName}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async deleteFile(folderName, fileName) {
    try {
      const response = await API.delete(`/api/rest/v2/kavelfolder/${folderName}/${fileName}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },




};
