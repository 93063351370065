<template>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
      

        <v-icon  small color="primary" dark v-bind="attrs" v-on="on">
  mdi-eye
</v-icon>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Kavelinformatie</span>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  dense
                  label="Kavelnummer"
                  v-model="form.kavelnr"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  dense
                  label="Landnaam"
                  v-model="form.landName"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Inhoud"
                  v-model="form.inhoudNL"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Embcode"
                  v-model="form.embNed"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Handelaar"
                  v-model="form.traderName"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-textarea v-model="form.txtNed"></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Afsluiten
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

</template>

<script>
import apiKavel from "../api/apiKavel";
export default {
  props: {
    kavelNumber: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    form: {},
  }),
  methods: {
    async fetchKavel() {
      return apiKavel.getSingleKavel(this.kavelNumber).then((data) => {
        this.form = data;
      });
    },
  },
  mounted() {
    this.fetchKavel();
  },
};
</script>
