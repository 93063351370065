import { API } from "./api.js";
export default {
  async createStatus(data) {
    try {
      const response = await API.post("/api/rest/v2/status", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getStatus() {
    try {
      const response = await API.get("/api/rest/v2/status");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteStatus(id) {
    try {
      const response = await API.delete(`/api/rest/v2/status/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateStatus(id, form) {
    try {
      const response = await API.put(`/api/rest/v2/status/${id}`, form);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getSingleStatus(id) {
    try {
      const response = await API.get(`/api/rest/v2/status/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
