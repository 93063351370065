<template>
  <v-dialog
    transition="dialog-top-transition"
    max-width="600"
    v-model="kavelDateDialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        rounded
        color="primary"
        block
        v-bind="attrs"
        v-on="on"
        style="margin-left: 5px"
      >
        Kavel aanmaak rapport
      </v-btn>
    </template>
    <template v-slot:default="kavelDateDialog">
      <v-card>
        <v-toolbar color="primary" dark style="justify-content: center">
          Selectie van aanmaak kavel rapport
        </v-toolbar>
        <v-card-text>
          <p style="color: red; font-weight: bold">
            Vul alleen een "Eind Aanmaak datum" als het een periode betreft dus
            voorbeeld ik wil alle kavels die zijn aangemaakt tussen X en X
          </p>
          <v-col cols="12">
            <v-menu
              v-model="startDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="selectedStartDate"
                  label="Start Aanmaak datum"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="selectedStartDate"
                @input="startDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12">
            <v-menu
              v-model="endDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="selectedEndDate"
                  label="Eind Aanmaak datum"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="selectedEndDate"
                @input="endDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="generateDateReport()"
            >Kavel rapport genereren</v-btn
          >
          <v-btn text @click="kavelDateDialog.value = false">Afsluiten</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import apiKavel from "../api/apiKavel";
export default {
  data() {
    return {
      kavelDateDialog: false,

      selectedStartDate: "",
      selectedEndDate: "",
      startDateMenu: false,
      endDateMenu: false,
      totalPrice: 0,
    };
  },


  methods: {
    resetInfo() {
      this.reportName = "";
      this.selectedStartDate = "";
      this.selectedEndDate = "";
      this.startkavelnr = "";
      this.endkavelnr = "";
      this.totalPrice = 0;
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },

    async generateDateReport() {
      const data = {
        startDate: this.selectedStartDate,
        endDate: this.selectedEndDate,
      };

      try {
        const response = await apiKavel.generateKavelReport(data);
        if (response && Array.isArray(response.kavelResponse)) {
          const kavelsData = [...response.kavelResponse];
          kavelsData.push({
            kavelnr: "Totaal",
            inhoudNL: "",
            embNed: "",
            landName: "",
            kenmerk:"",
            statusName: "",
            price: response.totalPrice,
            createDate: "",
          });

          console.log("kavelsData", kavelsData);

          const doc = new jsPDF('landscape');
          const currentDate = new Date().toLocaleDateString();

          // Add report title and date
          doc.setFontSize(18);
          doc.text("Rapport Kavel aangemaakt", 14, 16);
          doc.setFontSize(12);
          doc.text(`Datum uitdraaien: ${currentDate}`, 14, 23);
          doc.text(`Totaalbedrag: €${response.totalPrice}`, 14, 30);

          // Add the table
          doc.autoTable({
            startY: 40,
            head: [
              [
                "Kavel",
                "Inhoud",
                "Embcode",
                "Land",
                "Status",
                "Kenmerk",
                "Prijs",
                "Create Date",
              ],
            ],
            body: kavelsData.map((item) => [
              item.kavelnr,
              item.inhoudNL,
              item.embNed,
              item.landName,
              item.statusName,
              item.kenmerk,
              item.price,
              this.formatDate(item.createDate),
            ]),
          });

          const reportFilename = `Embcode-${new Date().toLocaleDateString()}.pdf`;
          doc.save(reportFilename);
        } else {
          console.error("Received data is not properly formatted", response);
        }
      } catch (error) {
        console.error("Error generating the report:", error);
      }
    },
  },
};
</script>
