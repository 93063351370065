<template>
  <v-container fluid>
    <v-row justify="center" align="stretch">
      <v-col v-for="item in filteredItems" :key="item.kavelnr" cols="12" md="6" lg="4">
        <v-card class="mb-4 custom-card" @click="openSingleKavel(item.kavelnr)">
          <v-card-title class="primary lighten-2 white--text">
            Kavelnummer {{ item.kavelnr }}
          </v-card-title>
          <v-card-text>
            <div>
              <strong>Prijs:</strong> €{{ item.price }}
            </div>
            <div>
              <strong>Geplaatst op:</strong> {{ formatDate(item.created_at) }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiSale from "../api/apiSale";

export default {
  data: () => ({
    search: "",
    items: [],
  }),

  computed: {
    filteredItems() {
      const query = this.search.toLowerCase();
      return this.items.filter((item) => {
        return (
          item.kavelnr.toString().includes(query) ||
          item.price.toString().includes(query) ||
          item.created_at.includes(query)
        );
      });
    },
  },

  async mounted() {
    await this.getKavels();
  },

  methods: {
    formatDate(dateStr) {
      const [day, month, year] = dateStr.split("-");
      return `${day}-${month}-${year}`;
    },

    async getKavels() {
      try {
        const data = await apiSale.getkaveloftheday();
        this.items = data;
      } catch (error) {
        console.error(error);
      }
    },

    openSingleKavel(kavelnr) {
      // Implementeer de logica om een kavel te openen
      console.log("Open kavel:", kavelnr);
    },
  },
};
</script>

<style scoped>
.v-card {
  cursor: pointer;
}

.v-card-title {
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
}

.custom-card {
  height: auto;
  max-width: 100%;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.custom-card:hover {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.primary {
  background-color: #1976d2 !important;
}

.white--text {
  color: white !important;
}

.mb-4 {
  margin-bottom: 16px;
}
</style>
