import { API } from "./api.js";
export default {
  async postToEbay(data) {
    try {
      const response = await API.post("/api/rest/v2/ebay", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async deleteEbay(id) {
    try {
      const response = await API.delete(`/api/rest/v2/ebay/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
//Dit is alleen voor de ebay informatie pagina
  async getEbyInformation() {
    try {
      const response = await API.get("/api/rest/v2/ebaytext");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async UpdateEbayInformation(id, data) {
    try {
      const response = await API.put(`/api/rest/v2/ebaytext/${id}`, data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

//
  async getEbaySites() {
    try {
      const response = await API.get("/api/rest/v2/ebaysites");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getEbaySiteById(id) {
    try {
      const response = await API.get(`/api/rest/v2/ebaysites/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateEbaySite(id, status) {
    try {
      const response = await API.put(`/api/rest/v2/ebaysites/${id}`, status);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getEbayOrders() {
    try {
      const response = await API.get("/api/rest/v2/ebay/orders");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
  async updateOrderStatus(payload) {
    try {
      const response = await API.put("/api/rest/v2/ebay/orders/status/update", payload);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
