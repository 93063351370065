<template>
  <div class="payment-container">
    
    <div class="payment-card">
      <div class="payment-header">
        <h3>{{ paymentInfo.company }}</h3>
        <BetalingStatus @status-updated="getPaymentInfo" />
        <span style="float: right">
          <v-col
          cols="12"
          style="display: flex; justify-content: flex-end; gap: 10px"
        >
          <v-btn color="warning" @click="goBack()">Ga terug</v-btn>
          <v-btn color="danger" @click="deletePayment()">Verwijderen</v-btn>
         
        </v-col>
    
        </span>
        <span
          class="payment-status"
          :class="{
            paid: paymentInfo.status === 'Betaald',
            unpaid: paymentInfo.status !== 'Betaald',
          }"
        >
          {{ paymentInfo.status }}
        </span>
      </div>
      <div class="payment-body">
        <div class="payment-row" v-if="paymentInfo.trader">
          <p><strong>Bedrijfsnaam:</strong></p>
          <p>{{ paymentInfo.trader.companyName }}</p>
        </div>
        <div class="payment-row" v-if="!paymentInfo.trader">
          <p><strong>Bedrijfsnaam:</strong></p>
          <p>Geen Bedrijfsnaam</p>
        </div>
        <div class="payment-row" v-if="paymentInfo.trader">
          <p><strong>Naam:</strong></p>
          <p>{{ paymentInfo.trader.name }}</p>
        </div>
        <div class="payment-row" v-if="!paymentInfo.trader">
          <p><strong>Naam:</strong></p>
          <p>Geen naam</p>
        </div>
        <div class="payment-row">
          <p><strong>Omschrijving:</strong></p>
          <p>{{ paymentInfo.description }}</p>
        </div>
        <div class="payment-row">
          <p><strong>Bedrag:</strong></p>
          <p>€{{ paymentInfo.amount }}</p>
        </div>
        <div class="payment-row">
          <p><strong>Openstaand:</strong></p>
          <p style="color: darkgoldenrod">
            <b>€{{ outstandingAmount }}</b>
          </p>
        </div>
        <div class="payment-row">
          <p><strong>Factuurdatum:</strong></p>
          <p>
            {{
              paymentInfo.date
                ? new Date(paymentInfo.date).toLocaleDateString()
                : "Geen datum beschikbaar"
            }}
          </p>
        </div>
        <div class="payment-row">
          <p><strong>Type:</strong></p>
          <p>{{ paymentInfo.type }}</p>
        </div>
        <div class="payment-row">
          <p><strong>Aanmaakdatum:</strong></p>
          <p>{{ new Date(paymentInfo.createDate).toLocaleString() }}</p>
        </div>
      </div>

      <div class="payment-history">
        <h5><b> Betaalgeschiedenis</b></h5>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Betaald Bedrag</th>
              <th>Datum</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="history in paymentInfo.traderPaymentHistory"
              :key="history.id"
            >
              <td>{{ history.id }}</td>
              <td>€{{ history.amountpaid }}</td>
              <td>{{ new Date(history.createDate).toLocaleString() }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Actions Section -->
      <div class="action-buttons">
        <v-col
          cols="12"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <Betalingen @payment-updated="getPaymentInfo" />
          <v-btn @click="generateInvoice()" color="warning"
            >factuur genereren</v-btn
          >
        </v-col>
       
      </div>
    </div>
  </div>
</template>


<script>
import Betalingen from "./Betalingen.vue";
import BetalingStatus from "./BetalingStatus.vue";
import apiTraderPaymentinout from "../api/apiTraderPaymentinout";
export default {
  components: {
    BetalingStatus,
    Betalingen,
  },
  data() {
    return {
      paymentInfo: {},
    };
  },
  mounted() {
    this.getPaymentInfo();
  },
  computed: {
    outstandingAmount() {
      if (!this.paymentInfo.amount || !this.paymentInfo.traderPaymentHistory) {
        return 0;
      }
      const totalPaid = this.paymentInfo.traderPaymentHistory.reduce(
        (total, history) => total + parseFloat(history.amountpaid),
        0
      );
      return parseFloat(this.paymentInfo.amount) - totalPaid;
    },
  },
  methods: {
    async generateInvoice() {
      try {
        const id = this.$route.params.id;
        const response = await apiTraderPaymentinout.generateInvoice(id);
        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.dialog2 = false;
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
    getPaymentInfo() {
      apiTraderPaymentinout
        .getSinglePayment(this.$route.params.id)
        .then((data) => {
          this.paymentInfo = data;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    async deletePayment() {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze betalingen wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        const id = this.$route.params.id;
        await apiTraderPaymentinout.deletePayment(id);
        this.$router.push("Betalingenuit");
        this.$swal.fire(
          "Verwijderd",
          "De betaaltemplate is succesvol verwijderd",
          "success"
        );
      }
    },
  },
};
</script>

<style scoped>
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
}

.payment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  padding: 20px;
}

.payment-card {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
}

.payment-header {
  background: #007bff;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.payment-header h3 {
  margin: 0;
  font-size: 1.8em;
}

.payment-status {
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 1em;
  font-weight: bold;
}

.payment-status.paid {
  background: #28a745;
  color: white;
}

.payment-status.unpaid {
  background: #dc3545;
  color: white;
}

.payment-body {
  padding: 20px;
}

.payment-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #f1f1f1;
}

.payment-row p {
  margin: 0;
  font-size: 1.1em;
  line-height: 1.4;
}

.payment-history {
  padding: 20px;
  border-top: 1px solid #ddd;
}

.payment-history h4 {
  margin-bottom: 10px;
}

.payment-history table {
  width: 100%;
  border-collapse: collapse;
}

.payment-history th,
.payment-history td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.payment-history th {
  background-color: #f2f2f2;
}

.button {
  color: white;
  background-color: #007bff;
  border: none;
  padding: 15px 20px;
  text-align: center;
  font-size: 1em;
  margin: 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.button1 {
  color: white;
  background-color: red;
  border: none;
  padding: 15px 20px;
  text-align: center;
  font-size: 1em;

  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}
</style>
