<template>
  <div>
    <v-container fluid>
      <v-btn small color="warning" @click="openInstructions">Handelaar instructies openen</v-btn>
      <AddTrader v-if="showForm" @cancel="showForm = false" />
      <v-col
        style="display: flex; justify-content: flex-end; margin-bottom: 2px;"
      >
        <v-btn
          color="primary"
          style="margin-right:5px"
          @click="showForm = true"
          v-if="!showForm"
        >
          Handelaar toevoegen
        </v-btn>

        <!-- <v-btn  class="btn" color="#FF9900" @click="download()" v-if="!showForm">
            Handelaars exporteren
          </v-btn> -->

        <div style="float:right" v-if="!showForm">
          <v-icon
            :color="showTable ? 'primary' : undefined"
            @click="
              showTable = true;
              showMap = false;
            "
            >mdi-table</v-icon
          >
          <v-icon
            :color="showMap ? 'primary' : undefined"
            @click="
              showTable = false;
              showMap = true;
            "
            >mdi-card-account-details</v-icon
          >
        </div>
      </v-col>
      <v-row>
        <v-col cols="12">
          <TradersTable v-if="!showForm && showTable && !showMap" />
          <TradersCard v-if="!showForm && !showTable && showMap" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TradersTable from "../traders/TradersTable.vue";
import TradersCard from "../traders/TradersCard.vue";
import AddTrader from "../traders/AddTrader.vue";
export default {
  components: {
    TradersTable,
    TradersCard,
    AddTrader,
  },
  data: () => ({
    showForm: false,
    showTable: true,
    showMap: false,
  }),
  methods: {
    openInstructions() {
      this.$router.push('/instructies');
    }
  }
};
</script>

<style scoped>
.space {
  margin: 3px;
}
.btn {
  color: white;
}
</style>
