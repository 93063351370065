<template>
  <v-container style="padding-top: 2rem">
    <div data-v-step="1"></div>
    <CashierTour />
    
    <v-row>
      <v-col cols="8">
       
        <Products @add-to-cart="updateCart" />

      </v-col>
      <v-col cols="4">
        <div data-v-step="3">
        <Cart
          :cartItems="filteredCartItems"
          @remove-cart-item="removeCartItem"
        />
      </div>
      </v-col>
   
    </v-row>
  </v-container>
</template>

<script>
import Products from "../cashier/Products.vue";
import Cart from "../cashier/Cart.vue";
import CashierTour from "../tour/CashierTour.vue";
export default {
  components: {
    Products,
    Cart,
    CashierTour
  },
  data() {
    return {
      cartItems: [],
    };
  },

  computed: {
    filteredCartItems() {
      const uniqueItems = [];
      const uniqueIds = new Set();

      for (const cartItem of this.cartItems) {
        if (!uniqueIds.has(cartItem.kavelnr)) {
          uniqueItems.push(cartItem);
          uniqueIds.add(cartItem.kavelnr);
        }
      }

      return uniqueItems;
    },
  },
  methods: {
    updateCart(item) {
      this.cartItems.push(item);
    },
    removeCartItem(cartItem) {
      const index = this.cartItems.findIndex((item) => item.kavelnr === cartItem.kavelnr);
      if (index !== -1) {
        this.cartItems.splice(index, 1);
      }
    },
  },
};
</script>
