
<template>
  <AddLand />
</template>

<script>
import AddLand from "@/components/instellingen/AddLand";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    AddLand,
  },
  metaInfo: {
    title: "Land toevoegen  | Postzegels ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>