import { API } from "./api.js";
export default {
  async createCustomer(data) {
    try {
      const response = await API.post("/api/rest/v2/customer", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getCustomers(pageNumber, q = "") {
    try {
      const response = await API.get(
        `/api/rest/v2/customer/?page=${pageNumber}&q=${q}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteCustomer(id) {
    try {
      const response = await API.delete(`/api/rest/v2/customer/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getCustomerByName(name) {
    try {
      const response = await API.get(`/api/rest/v2/customer/search/${name}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateCustomer(id, form) {
    try {
      const response = await API.post(`/api/rest/v2/customer/${id}`, form);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async addCustomer(data) {
    try {
      const response = await API.post(`/api/rest/v2/customer`,data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getSingleCustomer(id) {
    try {
      const response = await API.get(`/api/rest/v2/customer/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
