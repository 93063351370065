<template>
  <v-container fluid>
    <div class="row">
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div>
            <div class="link-box">
              <h2>Webshop rapport</h2>
              <p class="link-box-title">Bestelgeschiednis van een persoon</p>
              <p class="link-box-subtitle">
                In Dit rapport kun je de bestellingen van een persoon uitdraaien
              </p>
              <v-chip class="ma-2" color="success" outlined>
                Bestellingen
              </v-chip>

              <WebshopReport />
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div class="link-box">
            <h2>Rapport op basis van een bestelstatus uidraaien</h2>
            <p class="link-box-title">Selecteer een status en draai uit</p>
            <p class="link-box-subtitle">
              Dit rapport kan je verschillende bestelstatussen uitdraaien
            </p>
            <v-chip class="ma-2" color="success" outlined>
              Bestellingen
            </v-chip>
            <BookkeeperReport />
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import BookkeeperReport from "../reportOrders/BookkeeperReport.vue";
import WebshopReport from "../reportOrders/WebshopReport.vue";
export default {
  components: {
    WebshopReport,
    BookkeeperReport,
  },
  data() {
    return {};
  },
  methods: {
    openInstructions() {
      this.$router.push("/instructies");
    },
  },
};
</script>

<style scoped>
.router-link {
  color: black;
  text-decoration: none;
}
.link-box {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 5px 8px 0 rgba(0, 0, 0, 0.05);
  padding: 20px;
  font-size: 16px;
  text-align: center;
  border-top: 2px solid #90caf9;

  /* min-height:100%; */
}
.link-box p {
  margin: 0px 10px 10px 0px;
}
.link-box img {
  height: 40px;
  margin-bottom: 10px;
}
.link-box-title {
  color: black;
  font-size: 16px;
}
.link-box-subtitle {
  color: rgb(63, 63, 63);
  font-size: 14px;
  margin-bottom: 10px;
}
h2 {
  font-size: 18px;
  font-weight: bold;
}
</style>
