<template>
  <v-container fluid style="padding-top: 3rem">
    <div style="margin-bottom: 5px">
      <v-toolbar color="primary" dark class="justify-center">
        <v-toolbar-title>
          <h5 class="text-center">Categorieen</h5>
        </v-toolbar-title>
      </v-toolbar>
    </div>

    <v-row>
      <v-col
        v-for="category in categories"
        :key="category.id"
        cols="12"
        sm="6"
        md="4"
      >
        <v-hover v-slot:default="{ isHovering }">
          <v-card :elevation="isHovering ? 12 : 2" class="category-card">
            <v-card-title class="fill-height align-end">{{
              category.category
            }}</v-card-title>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiInstruction from "../api/apiIntruction";

export default {
  data: () => ({
    categories: [],
  }),

  async mounted() {
    try {
      const data = await apiInstruction.getCategories();
      this.categories = data;
    } catch (error) {
      console.error("Failed to fetch categories", error);
    }
  },
};
</script>

<style scoped>
.category-card {
  transition: transform 0.3s ease-in-out;
}

.category-card:hover {
  transform: scale(1.05);
}

.v-img {
  transition: opacity 0.5s;
}

.v-img:hover {
  opacity: 0.8;
}
.justify-center {
  justify-content: center;
}
.text-center {
  text-align: center;
  width: 100%;
}
</style>
