<template>
  <v-container>
    <v-data-table max-height:150px :headers="headers" :items="sites" disable-pagination class="elevation-1" :hide-default-footer="true">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>eBay Sites</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn color="warning" rounded @click="refreshSites">Verversen</v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </template>
      <template v-slot:[`item.active`]="{ item }">
        <v-switch
          v-model="item.active"
          @change="updateStatus(item)"
          :label="item.active ? 'Actief' : 'Niet actief'"
          color="green"
        ></v-switch>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn v-if="item.shippings.length > 0" color="primary" @click="viewShippingCosts(item)">Bekijk Verzendkosten</v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Verzendkosten</span>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item v-for="shipping in selectedSite.shippings" :key="shipping.id">
              <v-list-item-content>
                <v-list-item-title><b>Gewicht: </b> {{ shipping.kg }} kg</v-list-item-title>
                <v-list-item-subtitle><b>Prijs:</b> {{ shipping.price }} {{ selectedSite.siteCurrency }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">Sluiten</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import apiEbay from "../api/apiEbay";
export default {
  data() {
    return {
      sites: [],
      headers: [
        { text: "Site ID", value: "siteCode" },
        { text: "Ebay website", value: "siteName" },
        { text: "Ebay id", value: "siteId" },
        { text: "Valuta", value: "siteCurrency" },
        { text: "Koerier", value: "shippingService" },
        { text: "Status", value: "active" },
        { text: "Verzendkosten", value: "action" },
      ],
      dialog: false,
      selectedSite: {}
    };
  },
  async mounted() {
    await this.getEbaySites();
  },
  methods: {
    getEbaySites() {
      try {
        apiEbay.getEbaySites().then((data) => {
          this.sites = data;
        });
      } catch (error) {
        this.$swal("Het ophalen is niet gelukt");
      }
    },
    async updateStatus(site) {
      try {
        const updatedSite = { ...site, active: site.active };
        const response = await apiEbay.updateEbaySite(updatedSite.id, updatedSite);
        console.log(response);
        this.$swal.fire("Yes!", "Het updaten is gelukt!", "success");
        await this.getEbaySites();
      } catch (error) {
        console.error(error);
        this.$swal.fire("Er is een foutmelding!", "Probeer het nogmaals!", "error");
      }
    },
    viewShippingCosts(item) {
      this.selectedSite = item;
      this.dialog = true;
    },
    refreshSites() {
      this.getEbaySites();
    }
  }
};
</script>

<style scoped>
.headline {
  font-size: 1.5rem;
  font-weight: bold;
}
.table-container {
  max-height: 500px; /* Stel hier de gewenste maximale hoogte in */
  overflow-y: auto; /* Voeg een scrollbalk toe als de inhoud de maximale hoogte overschrijdt */
}
</style>
