<template>
  <div>
    <v-btn color="primary" @click="startTour">Start Tour</v-btn>
    <v-tour :steps="steps" :options="options" name="product-tour"></v-tour>
  </div>
</template>

<script>
export default {
  data() {
    return {
      steps: [
        {
          target: '[data-v-step="1"]',
          content:
            "Welkom bij kassa afrekenen. Hier worden de betalingen verwerkt.",
          params: {
            placement: "bottom",
          },
        },

        {
          target: '[data-v-step="2"]',
          content: "De kavels kunnen in de winkelwagen worden gezet.",
          params: {
            placement: "bottom",
          },
        },
      ],
      options: {
        labels: {
          buttonSkip: "Skip",
          buttonPrevious: "Previous",
          buttonNext: "Volgende",
          buttonStop: "Finish",
        },
        scrollTo: { behavior: "smooth", block: "start" },
        highlight: true,
      },
    };
  },
  methods: {
    startTour() {
      this.$tours["product-tour"].start();
    },
  },
};
</script>

<style scoped>
/* Voeg hier indien nodig extra styling toe */
</style>
