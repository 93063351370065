<template>
  <v-container fluid>
    <form ref="form" @submit.prevent="submitForm">
      <v-stepper v-model="e13" vertical>
        <v-stepper-step step="1"> NAW gegevens </v-stepper-step>

        <v-stepper-content step="1">
          <v-card>
            <v-card-title> NAW gegevens </v-card-title>

            <v-row>
              <v-col cols="4">
                <label> Titel selecteren</label>
                <v-select
                  dense
                  :items="titels"
                  outlined
                  placeholder="Title selecteren"
                  v-model="form.title"
                >
                </v-select>
              </v-col>
              <v-col cols="4">
                <label> Initialen</label>
                <v-text-field
                  dense
                  outlined
                  placeholder="Initialen"
                  v-model="form.initials"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <label> Naam </label>
                <v-text-field
                  dense
                  outlined
                  placeholder="Naam van de klant"
                  v-model="form.name"
                >
                </v-text-field>
              </v-col>

              <v-col cols="4">
                <label> Telefoonnummer </label>
                <v-text-field
                  dense
                  outlined
                  placeholder="Telefoonnummer"
                  v-model="form.phoneNumber"
                >
                </v-text-field>
              </v-col>

              <v-col cols="4">
                <label> Mobiele Telefoonnummer </label>
                <v-text-field
                  dense
                  outlined
                  placeholder="Telefoonnummer"
                  v-model="form.mobilePhone"
                >
                </v-text-field>
              </v-col>

              <v-col cols="4">
                <label> Email </label>
                <v-text-field
                  dense
                  outlined
                  placeholder="Email"
                  v-model="form.email"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <label> Bankrekeningnummer </label>
                <v-text-field
                  dense
                  outlined
                  placeholder="Bankrekeningnummer"
                  v-model="form.bankNumber"
                >
                </v-text-field>
              </v-col>

              <v-col cols="4">
                <label> Vaste klant </label>
                <v-select
                  dense
                  outlined
                  placeholder="Vaste klant"
                  v-model="form.type"
                  :items="typeCustomer"
                >
                </v-select>
              </v-col>
            </v-row>
            <div style="margin-top: 5px">
              <v-btn color="primary" @click="e13 = 2"> Volgende </v-btn>
              <v-btn color="error" @click="goBack()" text> Annuleer </v-btn>
            </div>
          </v-card>
        </v-stepper-content>

        <v-stepper-step step="2"> Adres gegevens </v-stepper-step>

        <v-stepper-content step="2">
          <v-card>
            <v-row>
              <v-col cols="4">
                <label> Adres</label>

                <v-text-field
                  outlined
                  v-model="form.adres"
                  label="Adres invoeren"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <label> Huisnummer</label>

                <v-text-field
                  outlined
                  type="number"
                  v-model="form.houseNumber"
                  label="Huisnummer"
                ></v-text-field>
              </v-col>

              <v-col cols="2">
                <label> Toevoeging</label>

                <v-text-field
                  outlined
                  v-model="form.houseNumberAddition"
                  label="Toevoeging"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <label> Postcode</label>

                <v-text-field
                  outlined
                  v-model="form.postCode"
                  label="Postcode"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <label> Stad</label>

                <v-text-field
                  outlined
                  v-model="form.city"
                  label="Stad"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <label> Land</label>

                <v-select
                  outlined
                  v-model="form.land"
                  :items="countries"
                  label="Kies een land"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <label> Extra informatie *</label>
                <textarea
                  class="form-control"
                  rows="4"
                  placeholder="Omschrijving"
                  data-counter="350"
                  name="description"
                  cols="50"
                  id="description"
                  v-model="form.info"
                ></textarea>
              </v-col>
            </v-row>
            <div style="margin-top: 5px">
              <v-btn color="primary" @click="updateCustomer()">
                Klant Bijwerken
              </v-btn>

              <v-btn color="error" @click="goBack()" text> Annuleer </v-btn>
            </div>
          </v-card>
        </v-stepper-content>
      </v-stepper>
    </form>
  </v-container>
</template>

<script>
import apiCustomer from "../api/apiCustomer";
export default {
  data() {
    return {
      e13: 1,
      form: {
        title: "",
        initials: "",
        name: "",
        phoneNumber: "",
        mobilePhone: "",
        email: "",
        bankNumber: "",
        type: "",
        adres: "",
        houseNumber: "",
        houseNumberAddition: "",
        postCode: "",
        city: "",
        land: "",
        info: "",
      },
      titels: ["Dr.", "Mr.", "Ms.", "Mrs."], // Define your titles here
      typeCustomer: ["Regular", "VIP"], // Define customer types
      countries: ["Netherlands", "Germany", "France", "Belgium"], // Define your country list
    };
  },

  mounted() {
    apiCustomer.getSingleCustomer(this.$route.params.id).then((data) => {
      this.form = data;
    });
  },
  methods: {
    info() {
      this.$swal("Deze klant heeft geen kavels");
    },
    goBack() {
      if (this.$route.name !== "Klanten") {
        this.$router.push({ name: "Klanten" });
      }
    },

    async updateCustomer() {
      const id = this.$route.params.id;
      try {
        const response = await apiCustomer.updateCustomer(id, this.form);
        console.log(response);
        this.$swal.fire("Yes!", "Het bijwerken is gelukt!", "success");
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>

<style scoped>
.button {
  color: white;
  margin: 4px;
}
</style>
