<template>
  <div>
    <v-btn color="primary" @click="startTour">Start Tour</v-btn>
    <v-tour :steps="steps" :options="options" name="product-tour"></v-tour>
  </div>
</template>

<script>
export default {
  data() {
    return {
      steps: [
        {
          target: '[data-v-step="1"]',
          content:
            "Welkom bij de ebaybestellingen. Hier worden alle bestellingen uit Ebay getoond..",
          params: {
            placement: "bottom",
          },
        },
        {
          target: '[data-v-step="2"]',
          content: "Here you can manage your settings.",
          params: {
            placement: "right",
          },
        },
        // Voeg meer stappen toe zoals nodig
      ],
      options: {
        labels: {
          buttonSkip: "Skip",
          buttonPrevious: "Previous",
          buttonNext: "Volgende",
          buttonStop: "Finish",
        },
      },
    };
  },
  methods: {
    startTour() {
      this.$tours["product-tour"].start();
    },
  },
};
</script>

<style scoped>
/* Voeg hier indien nodig extra styling toe */
</style>
