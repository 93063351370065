<template>
    <ShippingCost />
  </template>
  
  <script>
import ShippingCost from "../../../components/shipping/ShippingCost.vue";
  export default {
    data() {
      return {
        data: [],
      };
    },
    components: {
        ShippingCost,
    },
    metaInfo: {
      title: "Verzendkosten",
      htmlAttrs: {
        lang: "EN",
        amp: true,
      },
    },
  };
  </script>
  