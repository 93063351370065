var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticStyle:{"display":"flex"}},[_c('HandelaarBetalingenuitRapportage'),_c('BetalingToevoegen',{on:{"payment-added":_vm.getPaymentData}})],1),_c('div',[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Betalingen in en uit "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Opzoeken","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"table-container"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.payments,"search":_vm.search,"disable-pagination":"","hide-default-footer":true},scopedSlots:_vm._u([{key:`item.status`,fn:function({ item }){return [(item.status == 'Betaald')?_c('span',[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])],1):_vm._e(),(item.status == 'Gedeeltelijk betaald')?_c('span',[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"orange","outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])],1):_vm._e(),(
                    item.status != 'Gedeeltelijk betaald' &&
                    item.status != 'Betaald'
                  )?_c('span',[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])],1):_vm._e()]}},{key:`item.restamount`,fn:function({ item }){return [_vm._v(" €"+_vm._s(_vm.calculateOutstandingAmount(item))+" "),(
                    item.status == 'Betaald' &&
                    _vm.calculateOutstandingAmount(item) > 0
                  )?_c('v-tooltip',{attrs:{"bottom":"","color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"warning","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Aandacht nodig ")])]}}],null,true)},[_c('span',[_vm._v("Betaling is niet correct afgerond, bedrag moet op 0 gezet worden.")])]):_vm._e()]}},{key:`item.trader.companyName`,fn:function({ item }){return [(item.trader)?_c('span',[_vm._v(" "+_vm._s(item.trader.companyName)+" ")]):_c('span',[_vm._v(" Geen bedrijf ")])]}},{key:`item.trader.name`,fn:function({ item }){return [(item.trader)?_c('span',[_vm._v(" "+_vm._s(item.trader.name)+" ")]):_c('span',[_vm._v(" Geen naam ")])]}},{key:`item.amount`,fn:function({ item }){return [_vm._v(" €"+_vm._s(item.amount)+" ")]}},{key:`item.createDate`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.createDate))+" ")]}},{key:`item.Actions`,fn:function({ item }){return [_c('v-icon',{attrs:{"small":"","color":"green"},on:{"click":function($event){return _vm.openPaymentInfo(item.id)}}},[_vm._v(" mdi-eye ")])]}}],null,true)})],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }