<template>
  <v-container fluid>
    <v-btn small style="margin-bottom: 2px" color="warning" @click="openInstructions"
      >Kavels instructies openen</v-btn
    >
    <div>
      <v-row>
        <v-col>
          <v-card class="pa-4">
            <v-card-title> Filter mogelijkheden: </v-card-title>
            <v-text-field
              class="custom-search-bar"
              v-model="search"
              append-icon="mdi-magnify"
              label="Zoeken op handelaar, status, land of kavelnummer"
              hide-details
            ></v-text-field>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card>
            <v-card-title>
              Kavelsbeheren
              <v-spacer></v-spacer>

              <v-spacer></v-spacer>
              <div style="text-align: right">
                <div style="margin-bottom: 2rem; margin-top: 1rem">
                  <v-btn color="primary" @click="addKavel()">
                    Kavel toevoegen
                  </v-btn>
                </div>
              </div>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="isFetching ? [] : kavels"
              :search="search"
              :loading="isFetching"
              disable-pagination
              :fixed-header="true"
              height="75vh"
              :hide-default-footer="true"
              class="elevation-1"
              id="my-kavels"
              cellClass="printable"
            >
              <template v-slot:top></template>
              <template v-slot:[`item.price`]="{ value }"
                >€{{ value }}</template
              >
              <template v-slot:[`item.Actions`]="{ item }">
                <v-icon small color="red" @click="deleteKavel(item.id)">
                  mdi-delete
                </v-icon>
                <v-icon small color="green" @click="openSingleKavel(item.id)">
                  mdi-eye
                </v-icon>
              </template>
              <template v-slot:footer>
                <v-pagination
                  v-model="page"
                  class="my-4 pb-4"
                  :length="totalPages"
                  :total-visible="7"
                  @input="onPageChange"
                ></v-pagination>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import "jspdf-autotable";
import "jspdf-autotable";
import apiKavel from "../api/apiKavel";

function debounce(func, delay) {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
}
export default {
  data: () => ({
    page: 1,
    isFetching: false,
    kavels: [],
    report: {
      inhoud: "",
      embcode: "",
      status: "",
      land: "",
    },
    field: {
      inhoud: "",
      embcode: "",
      status: "",
      land: "",
      purchasePrice: "",
      price: "",
    },
    search: "",
    items: [],

    headers: [
      {
        text: "Kavel nummer",
        value: "kavelnr",
        sortable: true,
      },
      {
        text: "Land",
        value: "land",
        sortable: true,
      },

      {
        text: "Embcode",
        value: "embcode",
        sortable: true,
      },
      {
        text: "Handelaar",
        value: "trader",
        sortable: true,
      },
      {
        text: "Status",
        value: "status",
        sortable: true,
      },

      {
        text: "Prijs",
        value: "price",
        sortable: true,
      },
      {
        text: "Actions",
        value: "Actions",
        sortable: false,
        formatter: (value) => `€${value}`,
      },
    ],
    totalPages: 1,
    nllanden: [],
    englanden: [],
    statussen: [],
    traders: [],
    embcodes: [],
    states: [],
  }),

  async mounted() {
    this.isFetching = true;
    await this.getKavels(this.page);
    // await this.getPageNumber();
  },

  computed: {
    modifiedHeaders() {
      return this.headers;
    },

    modifiedHeadersWithoutActions() {
      return this.headers.filter((header) => header.value !== "Actions");
    },
    paginatieReeks() {
      let reeks = [];
      const paginaBuffer = 2; // Aantal pagina's voor en na de huidige pagina
      const ellipses = "...";
      const startPagina = Math.max(this.page - paginaBuffer, 1);
      const eindPagina = Math.min(this.page + paginaBuffer, this.totalPages);

      if (startPagina > 1) {
        reeks.push(1); // Voeg de eerste pagina altijd toe
        if (startPagina > 2) reeks.push(ellipses); // Voeg ellipses toe als er een gat is
      }

      for (let i = startPagina; i <= eindPagina; i++) {
        reeks.push(i);
      }

      if (eindPagina < this.totalPages) {
        if (eindPagina < this.totalPages - 1) reeks.push(ellipses);
        reeks.push(this.totalPages);
      }

      return reeks;
    },
  },
  watch: {
    search(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isFetching = true;
        this.queryHandler();
      }
    },
  },
  methods: {
    openInstructions() {
      this.$router.push("/instructies");
    },
    addKavel() {
      this.$router.push("/KavelToevoegen");
    },

    queryHandler: debounce(function () {
      this.page = 1;
      this.getKavels(1);
    }, 500),
    async getKavels(pageNumber) {
      this.isFetching = true;
      await apiKavel
        .getKavels(pageNumber, this.search)
        .then((data) => {
          this.kavels = data.kavels;
          this.totalPages = data.totalPages;
        })
        .finally(() => {
          this.isFetching = false;
        });
    },

    onPageChange(pageNumber) {
      this.page = pageNumber;
      this.getKavels(pageNumber);
    },

    openSingleKavel(id) {
      this.$router.push(`/SingleKavel/${id}`);
    },

    async deleteKavel(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze kavel wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        try {
          await apiKavel.deleteKavel(id);
          await this.getKavels(this.page);
          this.$swal.fire(
            "Verwijderd",
            "De Kavel is succesvol verwijderd",
            "success"
          );
        } catch (error) {
          console.error("Fout bij het verwijderen van kavel:", error);
        }
      }
    },
  },
};
</script>

<style scoped>
.custom-search-bar {
  font-size: 1.3rem;
  border-radius: 8px;
  transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out;
  border: 2px solid #1976d2;
}

.custom-search-bar:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.custom-search-bar .v-input__control {
  display: flex;
  justify-content: center;
}

.custom-search-bar .v-label {
  color: #1976d2;
  font-size: 1.2rem;
  text-align: center;
  width: 100%;
}

.custom-search-bar .v-text-field__slot {
  text-align: center;
}

.custom-search-bar input {
  font-size: 1.2rem;
  text-align: center;
}
</style>
