<template>
  <SingleOrder />
</template>

<script>
import SingleOrder from "@/components/Webshop/SingleOrder";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    SingleOrder,
  },
  metaInfo: {
    title: "Webwinkel bestelling bekijken",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>
