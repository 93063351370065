<template>
  <v-container style="padding-top: 2rem">
    <v-row>
      <v-col>
        <v-card>
          <v-list v-if="cartItems.length > 0">
            <v-list-item v-for="cartItem in cartItems" :key="cartItem.kavelnr">
              <v-list-item-content>
                <v-list-item-title>
                  Kavelnummer <b>{{ cartItem.kavelnr }}</b>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <template v-if="cartItem.promotionPrice">
                    Bedrag € <b>{{ cartItem.promotionPrice }}</b>
                  </template>
                  <template v-else>
                    Bedrag € <b>{{ cartItem.price }}</b>
                  </template>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn small color="red" icon @click="removeCartItem(cartItem)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <div style="margin-bottom: 10px; margin-left: 15px">
            <h4>Totaal bedrag: € {{ calculateTotalAmount() }}</h4>
          </div>

          <v-dialog
            transition="dialog-top-transition"
            max-width="600"
            v-model="dialogVisible"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                style="margin-bottom: 10px; margin-left: 50px"
                color="primary"
                v-bind="attrs"
                v-on="on"
                >Afrekenen</v-btn
              >
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar color="primary" dark>Betaling verwerken</v-toolbar>
                <v-card-text>
                  <div>
                    <h5>Heeft de persoon al eerder besteld?</h5>
                    <v-radio-group v-model="personOrder">
                      <v-radio label="Ja" value="Ja" />
                      <v-radio label="Nee" value="Nee" />
                    </v-radio-group>
                    <v-autocomplete
                      dense
                      outlined
                      v-if="personOrder === 'Ja'"
                      :items="orderHistory.flat()"
                      label="Naam selecteren"
                      item-text="name"
                      item-value="name"
                      v-model="form.name"
                      @change="getPersonInfo"
                    ></v-autocomplete>
                    <span         v-if="personOrder === 'Ja'">
                    <p style="color: red; font-style: italic">
                      <b
                        >Onderstaand zijn de gegevens die bekend zijn van de
                        vorige bestellingen van deze klant. Controleer de
                        informatie voordat u de factuur aanmaakt.</b
                      >
                    </p></span>
                    <v-text-field
                      label="Adres koper"
                      v-model="selectedPerson.adres"
                      v-if="personOrder === 'Ja'"
                    >
                    </v-text-field>
                    <v-text-field
                      label="Postcode koper"
                      v-model="selectedPerson.postCode"
                      v-if="personOrder === 'Ja'"
                    >
                    </v-text-field>

                    <v-text-field
                      label="Telefoonnummer koper"
                      v-model="selectedPerson.phoneNumber"
                      v-if="personOrder === 'Ja'"
                    >
                    </v-text-field>

                    <v-text-field
                      label="Stad koper"
                      v-model="selectedPerson.city"
                      v-if="personOrder === 'Ja'"
                    >
                    </v-text-field>
                    <v-text-field
                      label="Land"
                      v-model="selectedPerson.land"
                      v-if="personOrder === 'Ja'"
                    >
                    </v-text-field>

                    <!-- Bij Nee-->

                    <v-text-field
                      label="Naam koper"
                      v-model="form.name"
                      v-if="personOrder === 'Nee'"
                    >
                    </v-text-field>

                    <v-text-field
                      label="Adres koper"
                      v-model="form.adres"
                      v-if="personOrder === 'Nee'"
                    >
                    </v-text-field>
                    <v-text-field
                      label="Postcode"
                      v-model="form.postCode"
                      v-if="personOrder === 'Nee'"
                    >
                    </v-text-field>
                    <v-text-field
                      label="Stad koper"
                      v-model="form.city"
                      v-if="personOrder === 'Nee'"
                    >
                    </v-text-field>
                    <v-text-field
                      label="Land koper"
                      v-model="form.land"
                      v-if="personOrder === 'Nee'"
                    >
                    </v-text-field>
                    <v-text-field
                      label="Telefoonnummer"
                      v-model="form.phoneNumber"
                      v-if="personOrder === 'Nee'"
                    >
                    </v-text-field>
                    <v-select
                      label="
                      Betaal optie selecteren"
                      :items="paymentOptions"
                      v-model="form.payment"
                    >
                    </v-select>
                    <v-select
                      v-if="form.payment !== 'Pin'"
                      label="Hoe wil de klant de factuur ontvangen"
                      :items="invoiceOptions"
                      v-model="form.invoiceReceived"
                    >
                    </v-select>
                    <span v-if="form.payment == 'Pin'">
                      <p style="color: red">
                        **De factuur is beschikbaar in orders als de betaling
                        succesvol is**
                      </p>
                    </span>
                    <v-text-field
                      v-if="
                        form.invoiceReceived === 'Per mail' &&
                        personOrder === 'Nee'
                      "
                      label="Voer het Mailadres"
                      v-model="form.email"
                    >
                    </v-text-field>
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn color="primary" @click="checkout()">Afronden</v-btn>
                  <v-btn text @click="dialog.value = false">Afsluiten</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiShop from "../api/apiShop.js";
import apiOrders from "../api/apiOrders.js";
export default {
  props: ["cartItems"],
  data() {
    return {
      dialogVisible: false,
      orderHistory: [],
      personOrder: "",
      invoiceOptions: ["Per mail", "Uitgeprint"],
      paymentOptions: ["Contant", "Bank overschrijving", "Pin", "Telefonische bestelling"],
      form: {
        email: "",
        payment: "",
        invoiceReceived: "",
      },
      selectedPerson: {},
    };
  },

  mounted() {
    apiOrders.getOrderHistory().then((data) => {
      this.orderHistory = data;
    });
  },
  methods: {
    async getPersonInfo() {
      if (this.form.name) {
        await apiOrders
          .getPersonInfo({ name: this.form.name })
          .then((data) => {
            this.selectedPerson = data;
          })
          .catch((error) => {
            console.error(
              "Er is een fout opgetreden bij het ophalen van persoonlijke informatie:",
              error
            );
          });
      }
    },
    calculateTotalAmount() {
      let totalAmount = 0;
      console.log(this.cartItems);
      this.cartItems.forEach((cartItem) => {
        totalAmount += parseFloat(cartItem.promotionPrice ?? cartItem.price);
      });
      return totalAmount;
    },

    async checkout() {
      try {
        if (this.cartItems.length === 0) {
      this.$swal.fire(
        "Waarschuwing!",
        "Selecteer minimaal één kavel voordat u kunt afrekenen!",
        "warning"
      );
      return; // Stop de checkout als er geen kavels zijn geselecteerd
    }

        let orderData = {};
        if (this.personOrder === "Ja") {
          orderData = {
            ...this.selectedPerson,
            cartItems: this.cartItems,
            payment: this.form.payment,
            invoiceReceived: this.form.invoiceReceived,
          };
        } else {
          orderData = {
            ...this.form,
            cartItems: this.cartItems,
          };
        }

        if (orderData.payment === "Pin") {
          await apiShop.pinOrder(orderData);
          this.$swal.fire(
            "Yes!",
            "Het bedrag is verstuurd naar de pin apparaat!",
            "success"
          );
        } else {
          await apiShop.createAndDownloadOrder(orderData);
          this.$swal.fire(
            "Yes!",
            "Uw bestelling is gelukt en de factuur is gedownload!",
            "success"
          );
        }
        this.resetFormAndCloseDialog();
        this.clearCartItems();
      } catch (error) {
        console.error("Error during the checkout process:", error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
        this.resetFormAndCloseDialog();
        this.clearCartItems();
      }
    },

    resetFormAndCloseDialog() {
      this.form.name = "";
      this.form.email = "";
      this.form.payment = "";
      this.form.invoiceReceived = "";
      this.dialogVisible = false;
    },
    removeCartItem(cartItem) {
      const index = this.cartItems.findIndex(
        (item) => item.kavelnr === cartItem.kavelnr
      );
      if (index !== -1) {
        this.cartItems.splice(index, 1);
      }
      this.$emit("remove-cart-item", cartItem);
    },
    clearCartItems() {
      this.cartItems = [];
    },
  },
};
</script>
