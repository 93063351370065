
<template>
  <MessageLog />
</template>

<script>
import MessageLog from "@/components/logs/MessageLog";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    MessageLog,
  },
  metaInfo: {
    title: "Logbestanden verzonden berichten | Dashboard",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>