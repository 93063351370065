<template>
  <v-row>
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            rounded
            color="primary"
            v-bind="attrs"
            v-on="on"
            class="ma-2"
            elevation="2"
          >
            <v-icon left>mdi-cash-plus</v-icon> Betaling Invoeren
          </v-btn>
        </template>
        <v-card elevation="3">
          <v-card-title class="headline grey--text text--darken-2">
            Nieuwe in en out toevoegen
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="form">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    dense
                    outlined
                    :items="traders"
                    label="Handelaar selecteren"
                    item-text="name"
                    item-value="id"
                    prepend-icon="mdi-account-circle"
                    v-model="newPayment.trader"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    outlined
                    dense
                    v-model="newPayment.amount"
                    label="Bedrag"
                    prefix="€"
                    type="number"
                    required
                    prepend-icon="mdi-currency-eur"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="newPayment.status"
                    :items="statussen"
                    outlined
                    dense
                    label="Status"
                    prepend-icon="mdi-information-outline"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        dense
                        v-model="newPayment.date"
                        label="Factuurdatum"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        prepend-icon="mdi-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="newPayment.date"
                      :active-picker.sync="activePicker"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substring(0, 10)
                      "
                      min="1950-01-01"
                      @change="save"
                      locale="nl"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="newPayment.type"
                    :items="items"
                    outlined
                    dense
                    label="Type"
                    prepend-icon="mdi-tag-outline"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    outlined
                    dense
                    v-model="newPayment.description"
                    label="Omschrijving"
                    rows="3"
                    auto-grow
                    prepend-icon="mdi-text-box-outline"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn color="error" @click="dialog = false" outlined>
              <v-icon left>mdi-cancel</v-icon> Annuleren
            </v-btn>
            <v-btn color="primary" @click="addPayment()" class="ml-2">
              <v-icon left>mdi-content-save</v-icon> Opslaan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import apiTrader from "../api/apiTrader";
import apiTraderPaymentinout from "../api/apiTraderPaymentinout";
export default {
  data: () => ({
    items: ["Binnenkomend", "Uitgaand"],
    statussen: [
      "Gedeeltelijk betaald",
      "Betaald",
      "Vervallen",
      "Geannuleerd",
      "In behandeling",
      "Teruggestort",
    ],
    dialog: false,
    activePicker: null,
    menu: false,
    traders: [],
    newPayment: {
      company: "",
      name: "",
      description: "",
      amount: "",
      status: "",
      type: "",
      trader: "",
      date: "",
    },
  }),

  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  mounted() {
    this.fetchTraders();
  },
  methods: {
    resetForm() {
      this.newPayment = {
        company: "",
        name: "",
        description: "",
        amount: "",
        status: "",
        type: "",
        date: "",
      };
    },

    save(date) {
      this.newPayment.date = date;
      this.menu = false;
    },
    async fetchTraders() {
      try {
        const data = await apiTrader.getTraders();
        this.traders = data;
      } catch (error) {
        console.error("Error fetching traders:", error);
      }
    },
    async addPayment() {
      if (!this.newPayment.status) {
        this.$swal.fire("Er is een foutmelding!", "Vul de status in!", "error");
        return;
      }
      try {
        const response = await apiTraderPaymentinout.createPayment(
          this.newPayment
        );
        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.resetForm();
        this.dialog = false;
        this.$emit("payment-added");
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>
