<template>
  <div>
    <v-btn color="primary" @click="dialog = true">Nieuw Kennisitem</v-btn>

    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-toolbar flat color="primary">
          <v-toolbar-title>Nieuwe instructie aanmaken</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container fluid>
          <div>
            <label>Onderwerp</label>
            <v-text-field v-model="subject" solo></v-text-field>
            <label>Categorie selecteren</label>
            <v-select
              v-model="category"
              :items="categories"
              id="category"
              solo
              item-text="category"
            ></v-select>
          </div>

          <vue-editor id="editor1" v-model="content"> </vue-editor>

          <div class="text-center">
            <v-btn
              color="primary"
              style="margin: 5px"
              @click="createKnowledgeItem()"
            >
              Aanmaken
            </v-btn>
            <v-btn
              style="color: white; background-color: #cb403d"
              @click="cancelForm"
            >
              Afsluiten
            </v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import apiInstruction from "../api/apiIntruction";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      dialog: false,
      categories: [],
      subject: "",
      content: "",
      description: "",
      category: "",
    };
  },
  async mounted() {
    try {
      const data = await apiInstruction.getCategories();
      this.categories = data;
    } catch (error) {
      console.error("Failed to fetch categories", error);
    }
  },
  methods: {
    resetForm() {
      this.subject = "";
      this.content = "";
      this.description = "";
      this.category = "";
    },
    cancelForm() {
      this.dialog = false;
      this.$emit("cancel");
    },
    async createKnowledgeItem() {
      try {
        const data = {
          subject: this.subject,
          keywords: this.tags,
          content: this.content,
          category: this.category,
        };
        const response = await apiInstruction.createIntruction(data);
        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.items = await apiInstruction.getInstructions();
        this.resetForm();
        this.$root.$emit("knowledgeItemAdded");
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>

<style scoped>
.text-center {
  padding-top: 1%;
  text-align: center;
}
#editor,
#editor1 {
  height: 350px;
  width: 100%;
}
.projectButton {
  margin: 10px;
}
</style>
