<template>
  <v-container fluid >
    <v-btn small style="margin-bottom: 10px;" color="warning" @click="openInstructions">Ebay instructies openen</v-btn>
    <template>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Betalingen informatie
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-textarea
              outlined
              label="Betalingen"
              rows="4"
              v-model="ebayinfo.Payment"
              placeholder="Schrijf hier over betalingen..."
            ></v-textarea>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            Leveringsvoorwaarden
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-textarea
              outlined
              v-model="ebayinfo.returnrefund"
              label="Leveringsvoorwaarden"
              rows="4"
              placeholder="Schrijf hier over de leveringsvoorwaarden..."
            ></v-textarea>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            Retour informatie
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-textarea
              outlined
              v-model="ebayinfo.returnrefund"
              label="Belangrijk om te weten"
              rows="4"
              placeholder="Schrijf hier belangrijke informatie..."
            ></v-textarea>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            Extra informatie
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-textarea
              outlined
              v-model="ebayinfo.important"
              label="Belangrijk om te weten"
              rows="4"
              placeholder="Schrijf hier belangrijke informatie..."
            ></v-textarea>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>

    <div style="padding-top: 1rem">
      <v-btn block color="primary" elevation="2" @click="saveEdit()">
        Wijzigingen Opslaan</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import apiEbay from "../api/apiEbay";
export default {
  data: () => ({
    ebayinfo: {
      feedback: "",
      returnrefund: "",
      important: "",
      Payment: "",
    },
  }),

  async mounted() {
    await this.fetchdata();
  },
  methods: {
    openInstructions() {
      this.$router.push('/instructies');
    },
    async fetchdata() {
      apiEbay.getEbyInformation().then((data) => {
        this.ebayinfo = data;
      });
    },
    async saveEdit() {
      try {
        const id = 1;
        const response = await apiEbay.UpdateEbayInformation(id, this.ebayinfo);
        console.log(response);
        this.$swal.fire("Yes!", "Het wijzigen is gelukt!", "success");
        this.fetchdata();
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>
