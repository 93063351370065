var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"payment-container"},[_c('div',{staticClass:"payment-card"},[_c('div',{staticClass:"payment-header"},[_c('h3',[_vm._v(_vm._s(_vm.paymentInfo.company))]),_c('span',{staticStyle:{"float":"right"}},[_c('BetalingStatus',{on:{"status-updated":_vm.getPaymentInfo}})],1),_c('span',{staticClass:"payment-status",class:{
          paid: _vm.paymentInfo.status === 'Betaald',
          unpaid: _vm.paymentInfo.status !== 'Betaald',
        }},[_vm._v(" "+_vm._s(_vm.paymentInfo.status)+" ")])]),_c('div',{staticClass:"payment-body"},[_c('div',{staticClass:"payment-row"},[_vm._m(0),_c('p',[_vm._v(_vm._s(_vm.paymentInfo.name))])]),_c('div',{staticClass:"payment-row"},[_vm._m(1),_c('p',[_vm._v(_vm._s(_vm.paymentInfo.description))])]),_c('div',{staticClass:"payment-row"},[_vm._m(2),_c('p',[_vm._v("€"+_vm._s(_vm.paymentInfo.amount))])]),_c('div',{staticClass:"payment-row"},[_vm._m(3),_c('p',{staticStyle:{"color":"darkgoldenrod"}},[_c('b',[_vm._v("€"+_vm._s(_vm.outstandingAmount))])])]),_c('div',{staticClass:"payment-row"},[_vm._m(4),_c('p',[_vm._v(" "+_vm._s(_vm.paymentInfo.date ? new Date(_vm.paymentInfo.date).toLocaleDateString() : "Geen datum beschikbaar")+" ")])]),_c('div',{staticClass:"payment-row"},[_vm._m(5),_c('p',[_vm._v(_vm._s(_vm.paymentInfo.type))])]),_c('div',{staticClass:"payment-row"},[_vm._m(6),_c('p',[_vm._v(_vm._s(new Date(_vm.paymentInfo.createDate).toLocaleString()))])])]),_c('div',{staticClass:"payment-history"},[_vm._m(7),_c('table',[_vm._m(8),_c('tbody',_vm._l((_vm.paymentInfo.paymentHistories),function(history){return _c('tr',{key:history.id},[_c('td',[_vm._v(_vm._s(history.id))]),_c('td',[_vm._v("€"+_vm._s(history.amountpaid))]),_c('td',[_vm._v(_vm._s(new Date(history.createDate).toLocaleString()))])])}),0)])]),_c('v-col',{staticStyle:{"display":"flex"},attrs:{"cols":"4"}},[_c('Betalingen',{on:{"payment-updated":_vm.getPaymentInfo}}),_c('BetalingfactuurMaken')],1),_c('button',{staticClass:"button",on:{"click":_vm.info}},[_vm._v("Instructie")]),_c('button',{staticClass:"button1",on:{"click":function($event){return _vm.goBack()}}},[_vm._v("Ga terug")]),_c('button',{staticClass:"button1",staticStyle:{"margin-left":"10px !important"},on:{"click":function($event){return _vm.deletePayment()}}},[_vm._v(" Verwijderen ")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Naam:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Beschrijving:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Bedrag:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Openstaand:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Datum:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Type:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Aanmaakdatum:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',[_c('b',[_vm._v(" Betaalgeschiedenis")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("ID")]),_c('th',[_vm._v("Betaald Bedrag")]),_c('th',[_vm._v("Datum")])])])
}]

export { render, staticRenderFns }