
<template>
  <StandardReports />
</template>

<script>
import StandardReports from "@/components/report/StandardReports";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    StandardReports,
  },
  metaInfo: {
    title: "Standaard rapportages | Dashboard",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>