<template>
  <v-container fluid>
    <v-btn color="warning" dark @click="dialog2 = true">
      Factuur genereren
    </v-btn>
    <v-dialog v-model="dialog2" max-width="750px">
      <v-card>
        <v-card-title>Factuur aanmaken zonder adresgegevens</v-card-title>
        <v-card-text>
          <v-text-field
            dense
            label="Email"
            v-model="form.email"
          ></v-text-field>
          <v-row>
            <v-col cols="6">
              <v-text-field
                dense
                label="Adres"
                v-model="form.adres"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                label="Postcode"
                v-model="form.postcode"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                label="Stad"
                v-model="form.city"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                label="Land"
                v-model="form.country"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn color="primary" style="margin-right: 10px;" @click="generateInvoice()">
            Factuur opstellen
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-btn color="warning" text @click="dialog2 = false">
            Afsluiten
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import apiPaymentinout from "../api/apiPaymentinout";
export default {
  data() {
    return {
      form: {
        adres: "",
        country: "",
        city: "",
        postcode: "",
        email: "",
      },
      dialog2: false,
    };
  },
  methods: {
    async generateInvoice() {
      try {
        if (!this.form.email.trim()) {
          this.$swal.fire("Foutmelding", "Het e-mailadres mag niet leeg zijn", "error");
          return;
        }
        
        const id = this.$route.params.id;
        const response = await apiPaymentinout.generateInvoice(id, this.form);
        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.dialog2 = false;
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>
