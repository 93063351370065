import { API } from "./api.js";
export default {
  async createDiscount(data) {
    try {
      const response = await API.post(
        "/api/rest/v2/validatediscountcode/handlediscount",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async deleteDiscount(id) {
    try {
      const response = await API.delete(`/api/rest/v2/validatediscountcode"/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getDiscounts() {
    try {
      const response = await API.get("/api/rest/v2/validatediscountcode/handlediscount");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateDiscount(id, form) {
    try {
      const response = await API.post(`/api/rest/v2/validatediscountcode/${id}`, form);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getSingleDiscount(id) {
    try {
      const response = await API.get(`/api/rest/v2/validatediscountcode/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
