<template>
  <v-container  fluid style="padding-top:4rem">
    <div>
      <v-row>
        <v-col cols="4">
          <v-text-field
            dense
            outlined
            placeholder="Inhoud Nederlands"
            v-model="form.nameNL"
          ></v-text-field>

          <v-text-field
            dense
            outlined
            placeholder="Inhoud invullen Engelse vertaling"
            v-model="form.nameEn"
          ></v-text-field>

          <v-textarea
            dense
            v-model="form.description"
            outlined
            placeholder="Omschrijving die handig kan zijn"
          ></v-textarea>
          <v-btn color="primary" @click="addState()"> Inhoud toevoegen </v-btn>
        </v-col>

        <v-col cols="8">
          <v-card>
            <v-card-title>
              Inhoud
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Inhoud opzoeken"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <div class="table-container">
              <v-data-table
                :headers="headers"
                :items="states"
                :search="search"
                disable-pagination
                :hide-default-footer="true"
                class="elevation-1"
              >
                <template v-slot:[`item.Actions`]="{ item }">
                  <v-icon small color="red" @click="deleteState(item.id)">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import apiState from "../api/apiState";
export default {
  data: () => ({
    form: {
      nameNL: "",
      nameEn: "",
      description: "",
    },
    states: [],
    search: "",
    headers: [
      {
        text: "InhoudNL",
        value: "nameNL",
        sortable: true,
      },
      {
        text: "InhoudEN",
        value: "nameEN",
        sortable: true,
      },
      {
        text: "Omschrijving",
        value: "description",
        sortable: true,
      },
      {
        text: "Acties",
        value: "Actions",
        sortable: true,
      },
    ],
  }),

  async mounted() {
    apiState.getStates().then((data) => {
      this.states = data;
    });
  },

  methods: {
    resetForm() {
      this.form.nameNL = "";
      this.form.nameEn= "";
      this.form.description = "";
    },

    async addState() {
      try {
        if (!this.form.nameNL) {
          this.$swal.fire(
            "Foutmelding",
            "De velden mogen niet leeg zijn",
            "error"
          );
          return;
        }
        const response = await apiState.createState(this.form);
        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.resetForm();
        const data = await apiState.getStates();
        this.states = data;
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },

    async deleteState(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze staat wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiState.deleteState(id);
        const data = await apiState.getStates();
        this.states = data;
        this.$swal.fire(
          "Verwijderd",
          "De Staat is succesvol verwijderd",
          "success"
        );
      }
    },
  },
};
</script>
