
<template>
  <Traders />
</template>

<script>
import Traders from "@/components/traders/Traders";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Traders,
  },
  metaInfo: {
 title: "Handerlaars overzicht",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>