<template>
  <v-container fluid>
    <v-btn small color="warning" @click="openInstructions">Standaardrapport instructies openen</v-btn>
    <h5>Kavels informatie</h5>
    <div class="row">
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div>
            <div class="link-box">
              <h2>{{ totalKavels.aantal }}</h2>
              <p class="link-box-title">Aantal Kavels</p>
              <p class="link-box-subtitle">
                Aantal kavels die in de database staan
              </p>
              <v-chip class="ma-2" color="brown" outlined> Kavels </v-chip>

              <!-- <v-btn text color="primary"> Download rapport </v-btn> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div class="link-box">
            <h2>{{ totalSoldKavels.aantal }}</h2>
            <p class="link-box-title">Aantal kavels verkocht</p>
            <p class="link-box-subtitle">
              Aantal kavels met de status verkocht.
            </p>
            <v-chip class="ma-2" color="brown" outlined> Kavels </v-chip>
            <!-- <v-btn text color="primary"> Download rapport </v-btn> -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div class="link-box">
            <h2>{{ totalNotSoldKavels.aantal }}</h2>
            <p class="link-box-title">Kavels nog niet verkocht</p>
            <p class="link-box-subtitle">
              Dit rapport toont het aantal niet verkochte kavels
            </p>
            <v-chip class="ma-2" color="brown" outlined> Kavels </v-chip>
            <!-- <v-btn text color="primary"> Download rapport </v-btn> -->
          </div>
        </div>
      </div>
    </div>
    <h5>Administratie informatie</h5>
    <div class="row">
      <!-- administratie -->
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div>
            <div class="link-box">
              <h2>{{ totalNotPaid.aantal }}</h2>
              <p class="link-box-title">Aantal Openstaande facturen</p>
              <p class="link-box-subtitle">
                Dit rapport toont het aantal facturen die nog niet zijn betaald.
              </p>
              <v-chip class="ma-2" color="success" outlined>
                Facturatie
              </v-chip>

              <!-- <v-btn text color="primary"> Download rapport </v-btn> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div class="link-box">
            <h2>{{ totalPaid.aantal }}</h2>
            <p class="link-box-title">Aantal Betaalde facturen</p>
            <p class="link-box-subtitle">
              Dit rapport toont het aantal facturen die betaald zijn.
            </p>
            <v-chip class="ma-2" color="success" outlined> Facturatie </v-chip>
            <!-- <v-btn text color="primary"> Download rapport </v-btn> -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div class="link-box">
            <h2>{{ verrekend.aantal }}</h2>
            <p class="link-box-title">Aantal verrekend facturen</p>
            <p class="link-box-subtitle">
              Dit rapport toont het aantal facturen die zijn verrekend.
            </p>
            <v-chip class="ma-2" color="success" outlined> Facturatie </v-chip>
            <!-- <v-btn text color="primary"> Download rapport </v-btn> -->
          </div>
        </div>
      </div>
    </div>
    <h5>Omzet informatie</h5>
    <div class="row">
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div class="link-box">
            <h2>€ {{ totalDayTurnovers.dayTurnover }}</h2>
            <p class="link-box-title">Dag omzet</p>
            <p class="link-box-subtitle">Dit rapport toont de dag omzet.</p>
            <v-chip class="ma-2" color="success" outlined> Facturatie </v-chip>
            <v-btn text color="primary" @click="downLoadDayTurnover()">
              Download rapport
            </v-btn>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div class="link-box">
            <h2>€ {{ totalWeekTurnovers.weekTurnover }}</h2>
            <p class="link-box-title">Week omzet</p>
            <p class="link-box-subtitle">Dit rapport toont de week omzet.</p>
            <v-chip class="ma-2" color="success" outlined> Facturatie </v-chip>
            <!-- <v-btn text color="primary"> Download rapport </v-btn> -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div class="link-box">
            <h2>€ {{ getMonthTurnOvers.monthTurnover }}</h2>
            <p class="link-box-title">Maand omzet</p>
            <p class="link-box-subtitle">Dit rapport toont de maand omzet.</p>
            <v-chip class="ma-2" color="success" outlined> Facturatie </v-chip>
            <!-- <v-btn text color="primary"> Download rapport </v-btn> -->
          </div>
        </div>
      </div>
    </div>
      <h5>Winkel informatie</h5>
      <div class="row">
        <div class="col-12 col-lg-4 mb-4">
          <div>
            <div class="link-box">
              <h2>{{ totalOrders.aantal }}</h2>
              <p class="link-box-title">Aantal webshop bestellingen</p>
              <p class="link-box-subtitle">
                Dit rapport toont de aantal webshop bestellingen.
              </p>
              <v-chip class="ma-2" color="deep-purple accent-4" outlined>
                Webshop bestellingen
              </v-chip>
              <!-- <v-btn text color="primary"> Download rapport </v-btn> -->
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-4 mb-4">
          <div>
            <div class="link-box">
              <h2>{{ totalSend.aantal }}</h2>
              <p class="link-box-title">
                Aantal verzonden webshop bestellingen
              </p>
              <p class="link-box-subtitle">
                Dit rapport toont de aantal verzonden webshop bestellingen.
              </p>
              <v-chip class="ma-2" color="deep-purple accent-4" outlined>
                Webshop bestellingen
              </v-chip>
              <!-- <v-btn text color="primary"> Download rapport </v-btn> -->
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 mb-4">
          <div>
            <div class="link-box">
              <h2>{{ totatNotSend.aantal }}</h2>
              <p class="link-box-title">
                Aantal niet verzonden webshop bestellingen
              </p>
              <p class="link-box-subtitle">
                Dit rapport toont de aantal niet verzonden webshop bestellingen.
              </p>
              <v-chip class="ma-2" color="deep-purple accent-4" outlined>
                Webshop bestellingen
              </v-chip>
              <!-- <v-btn text color="primary"> Download rapport </v-btn> -->
            </div>
          </div>
        </div>
      </div>
  </v-container>
</template>

<script>
import * as XLSX from "xlsx";
import apiReport from "../api/apiReport";

export default {
  data() {
    return {
      totalNotPaid: [],
      totalPaid: [],
      verrekend: [],
      totalOrders: [],
      totalSend: [],
      totatNotSend: [],
      totalDayTurnovers: [],
      totalWeekTurnovers: [],
      getMonthTurnOvers: [],
      totalKavels: [],
      totalSoldKavels: [],
      totalNotSoldKavels: [],
    };
  },

  mounted() {
    apiReport.totalNotPaid().then((data) => {
      this.totalNotPaid = data;
    });
    apiReport.totalPaid().then((data) => {
      this.totalPaid = data;
    });
    apiReport.verrekend().then((data) => {
      this.verrekend = data;
    });

    apiReport.totalOrders().then((data) => {
      this.totalOrders = data;
    });
    apiReport.totalSend().then((data) => {
      this.totalSend = data;
    });
    apiReport.totatNotSend().then((data) => {
      this.totatNotSend = data;
    });
    apiReport.totalDayTurnovers().then((data) => {
      this.totalDayTurnovers = data;
    });
    apiReport.totalWeekTurnovers().then((data) => {
      this.totalWeekTurnovers = data;
    });
    apiReport.getMonthTurnOvers().then((data) => {
      this.getMonthTurnOvers = data;
    });
    apiReport.getTotalKavels().then((data) => {
      this.totalKavels = data;
    });
    apiReport.getTotalKavelsVerkocht().then((data) => {
      this.totalSoldKavels = data;
    });
    apiReport.getTotalNotKavelsVerkocht().then((data) => {
      this.totalNotSoldKavels = data;
    });
  },

  methods: {
    openInstructions() {
      this.$router.push('/instructies');
    },
    async downLoadDayTurnover() {
      try {
        const response = await apiReport.downLoadDayTurnover();
        this.processResponse(response);
      } catch (error) {
        console.error("Error generating the report:", error);
        this.$swal.fire("Fout bij het genereren van het rapport.", "error");
      }
    },
    processResponse(response) {
      console.log("Received response:", response);
      if (response && response.orders) {
        this.totalPrice = response.dayTurnover;

        let ordersData = response.orders;
        if (this.selectedLand) {
          // Assuming you have a way to filter by land, although the current data does not contain land information
          ordersData = ordersData.filter(
            (order) => order.landName === this.selectedLand
          );
        }
        console.log("Filtered ordersData:", ordersData);
        if (ordersData.length > 0) {
          ordersData.push({
            id: "",
            createDate: "",
            name: "",
            adres: "",
            email: "Totaal bedrag",
            kavel: "",
            price: this.totalPrice,
            betaalStatus: "",
            status: "",
            verzenddatum: null,
          });

          const reportData = XLSX.utils.json_to_sheet(ordersData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, reportData, "Orders");
          XLSX.writeFile(wb, `Dagomzet.xlsx`);
        } else {
          this.$swal.fire(
            "Geen gegevens gevonden voor de geselecteerde criteria.",
            "info"
          );
        }
      } else {
        this.$swal.fire("Geen gegevens ontvangen van de server.", "error");
        console.log(
          "Received response does not contain the expected array.",
          response
        );
      }
    },
  },
};
</script>

<style scoped>
.router-link {
  color: black;
  text-decoration: none;
}
.link-box {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 5px 8px 0 rgba(0, 0, 0, 0.05);
  padding: 20px;
  font-size: 16px;
  text-align: center;
  border-top: 2px solid #90caf9;

  /* min-height:100%; */
}
.link-box p {
  margin: 0px 10px 10px 0px;
}
.link-box img {
  height: 40px;
  margin-bottom: 10px;
}
.link-box-title {
  color: black;
  font-size: 16px;
}
.link-box-subtitle {
  color: rgb(63, 63, 63);
  font-size: 14px;
  margin-bottom: 10px;
}
h2 {
  font-size: 18px;
  font-weight: bold;
}
</style>
