<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-container fluid>
            <v-card>
              <v-card-title>
                Traders
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Opzoeken"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-row>
                <v-col v-for="letter in letters" :key="letter" cols="auto">
                  <v-btn
                    :color="selectedLetter === letter ? 'primary' : ''"
                    @click="selectLetter(letter)"
                  >
                    {{ letter }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-container fluid>
                <v-row>
                  <v-col
                    v-for="trader in filteredTraders"
                    :key="trader.id"
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-card>
                      <v-card-title>{{ trader.name }}</v-card-title>
                      <v-card-text>
                        <div>Adres: {{ trader.Adres }}</div>
                        <div>Adres: {{ trader.postCode }}</div>
                        <div>Email: {{ trader.email }}</div>
                        <div>Telefoonnummer: {{ trader.phone }}</div>
                        <div>Bankrekening: {{ trader.bankNumber }}</div>
                      </v-card-text>
                      <v-card-actions>
                        <v-icon
                          small
                          color="green"
                          @click="openTrader(trader.id)"
                        >
                          mdi-eye
                        </v-icon>
                        <v-icon
                          small
                          color="red"
                          @click="deleteTrader(trader.id)"
                        >
                          mdi-delete
                        </v-icon>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import apiTrader from "../api/apiTrader";

export default {
  data: () => ({
    traders: [],
    search: "",
    selectedLetter: "",
    letters: [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ],
  }),

  async mounted() {
    apiTrader.getTraders().then((data) => {
      this.traders = data;
    });
  },

  computed: {
    filteredTraders() {
      if (this.selectedLetter) {
        return this.traders.filter((trader) => {
          return trader.name.charAt(0).toUpperCase() === this.selectedLetter;
        });
      }
      return this.traders;
    },
  },

  methods: {
    openTrader(id) {
      this.$router.push(`/Handelaar/bewerken/${id}`);
    },
    async deleteTrader(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze handelaar wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiTrader.deleteTrader(id);
        const data = await apiTrader.getTraders();
        this.traders = data;
        this.$swal.fire(
          "Verwijderd",
          "De handelaar is succesvol verwijderd",
          "success"
        );
      }
    },

    selectLetter(letter) {
      this.selectedLetter = letter;
    },
  },
};
</script>
