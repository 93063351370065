<template>
  <v-container fluid style="padding-top: 4rem">
    <div>
      <v-row>
        <v-dialog v-model="editDialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Land bewerken</span>
            </v-card-title>
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editForm.Ned"
                      label="Land NL*"
                      outlined
                    ></v-text-field>
                    
                    <v-text-field
                      v-model="editForm.Eng"
                      label="Land ENG*"
                      outlined
                    ></v-text-field>

                    <v-text-field
                      v-model="editForm.ebayId"
                      label="Ebay Rubriek id*"
                      outlined
                    ></v-text-field>

                    <v-autocomplete
            v-model="editForm.continentId"
            :items="continents"
            outlined
            item-text="name"
            item-value="id"
            dense
            label="Selecteer  Continent"
          ></v-autocomplete>

                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditDialog"
                >Annuleren</v-btn
              >
              <v-btn color="blue darken-1" text @click="saveEdit"
                >Opslaan</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-col cols="4">
          <v-text-field
            dense
            outlined
            placeholder="Land NL*"
            v-model="form.Ned"
          ></v-text-field>
         
          <v-text-field
            dense
            placeholder="Land ENG*"
            v-model="form.Eng"
            outlined
          ></v-text-field>
          <v-autocomplete
            v-model="form.continentId"
            :items="continents"
            outlined
             item-text="name"
            item-value="id"
            dense
            label="Selecteer  Continent"
          ></v-autocomplete>
          <v-btn color="primary" @click="addStatus()"> Land toevoegen </v-btn>
        </v-col>
        <v-col>
          <v-card>
            <v-col>
              <v-text-field
                v-model="search"
                label="Land opzoeken"
                outlined
                clearable
              ></v-text-field>
            </v-col>
            <div class="table-container">
              <v-data-table
                :headers="headers"
                :items="landen"
                :search="search"
                disable-pagination
                :hide-default-footer="true"
                class="elevation-1"
                height="900px"
                fixed-header
              >
                <template v-slot:[`item.Actions`]="{ item }">
                  <div style="display: flex;">
                    <v-btn
                    style="margin-right: 2px"
                    small
                    color="error"
                    @click="deleteLand(item.id)"
                  >
                    Verwijder
                  </v-btn>
                  <v-btn small color="primary" @click="openEditDialog(item)">
                    Bewerken
                  </v-btn>
                  </div>
            
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import apiContinent from '../api/apiContinent';
import apiLand from "../api/apiLand";
export default {
  data: () => ({
    continents:[],
    form: {
      Ned: "",
      Eng: "",
      continentId:"",
    },
    editDialog: false,
    editForm: {
      id: "",
      Ned: "",
      Eng: "",
      continentId:"",
      ebayId:"",
    },
    landen: [],
    search: "",
    headers: [
      {
        text: "NL",
        value: "Ned",
        sortable: true,
      },
      {
        text: "Ebay code",
        value: "ebayId",
        sortable: true,
      },

      {
        text: "ENG",
        value: "Eng",
        sortable: true,
      },
      {
        text: "Continent",
        value: "continent.name",
        sortable: true,
      },

      {
        text: "Acties",
        value: "Actions",
        sortable: true,
      },
    ],
  }),

  async mounted() {
    await this.fetchdata();
    this.fetchContinents();
  },
  methods: {
    async fetchdata() {
      apiLand.getLanden().then((data) => {
        this.landen = data;
      });
    },
    async fetchContinents() {
      apiContinent.getContinents().then((data) => {
        this.continents = data;
      });
    },

    
    resetForm() {
      this.form.Eng = "";
      this.form.Ned = "";
      this.form.continentId =null;
    },

    async addStatus() {
      try {
        if (!this.form.Ned || !this.form.Eng) {
          this.$swal.fire("Foutmelding", "Vul alle velden in", "error");
          return;
        }

        const response = await apiLand.createLand(this.form);
        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.fetchdata();
        this.resetForm();
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
    openEditDialog(item) {
      this.editForm.id = item.id;
      this.editForm.Ned = item.Ned;
      this.editForm.Eng = item.Eng;
      this.editForm.continentId = item.continent ? item.continent.id : "";
      this.editForm.ebayId = item.ebayId;
      this.editDialog = true;
    },

    closeEditDialog() {
      this.editDialog = false;
    },
    async saveEdit() {
      try {
        const response = await apiLand.updateLand(
          this.editForm.id,
          this.editForm
        );
        console.log(response);
        this.$swal.fire("Yes!", "Het updaten is gelukt!", "success");
        this.fetchdata();
        this.closeEditDialog();
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },

    async deleteLand(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je dit land wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiLand.deleteLand(id);
        this.fetchdata();
        this.$swal.fire(
          "Verwijderd",
          "Het land is succesvol verwijderd",
          "success"
        );
      }
    },
  },
};
</script>
