import { API } from "./api.js";
export default {
  async createIntruction(data) {
    try {
      const response = await API.post("/api/rest/v2/instruction", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getInstructions() {
    try {
      const response = await API.get("/api/rest/v2/instruction");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async updateInstruction(id, form) {
    try {
      const response = await API.put(`/api/rest/v2/instruction/${id}`, form);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getCategories() {
    try {
      const response = await API.get(`/api/rest/v2/instruction/category`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
