import { API } from "./api.js";
export default {
  async createPayment(data) {
    try {
      const response = await API.post("/api/rest/v2/paymentinout", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getPayments() {
    try {
      const response = await API.get("/api/rest/v2/paymentinout");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deletePayment(id) {
    try {
      const response = await API.delete(`/api/rest/v2/paymentinout/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updatePayment(id, form) {
    try {
      const response = await API.put(`/api/rest/v2/paymentinout/${id}`, form);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getSinglePayment(id) {
    try {
      const response = await API.get(`/api/rest/v2/paymentinout/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async generateInvoice(id, data) {
    try {
      const response = await API.post(`/api/rest/v2/paymentinout/invoice/${id}`,data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },


  async addPayment( data) {
    try {
      const response = await API.post("/api/rest/v2/paymentinout/paymenthistory",data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },



};
