
<template>
  <SingleTrader />
</template>

<script>
import SingleTrader from "@/components/traders/SingleTrader";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    SingleTrader,
  },
  metaInfo: {
 title: "Handerlaar bewerken",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>