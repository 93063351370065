import { API } from "./api.js";

export default {
  async createLand(data) {
    try {
      const response = await API.post("/api/rest/v2/land", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getLanden() {
    try {
      const response = await API.get("/api/rest/v2/land");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteLand(id) {
    try {
      const response = await API.delete(`/api/rest/v2/land/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateLand(id, form) {
    try {
      const response = await API.put(`/api/rest/v2/land/${id}`, form);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getSingleType(id) {
    try {
      const response = await API.get(`/api/rest/v2/land/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
