<template>
  <div class="payment-container">
    <div class="payment-card">
      <div class="payment-header">
        <h3>{{ paymentInfo.company }}</h3>
        <span style="float: right">
          <BetalingStatus @status-updated="getPaymentInfo" />
        </span>
        <span
          class="payment-status"
          :class="{
            paid: paymentInfo.status === 'Betaald',
            unpaid: paymentInfo.status !== 'Betaald',
          }"
        >
          {{ paymentInfo.status }}
        </span>
      </div>
      <div class="payment-body">
        <div class="payment-row">
          <p><strong>Naam:</strong></p>
          <p>{{ paymentInfo.name }}</p>
        </div>
        <div class="payment-row">
          <p><strong>Beschrijving:</strong></p>
          <p>{{ paymentInfo.description }}</p>
        </div>
        <div class="payment-row">
          <p><strong>Bedrag:</strong></p>
          <p>€{{ paymentInfo.amount }}</p>
        </div>
        <div class="payment-row">
          <p><strong>Openstaand:</strong></p>
          <p style="color: darkgoldenrod">
            <b>€{{ outstandingAmount }}</b>
          </p>
        </div>
        <div class="payment-row">
          <p><strong>Datum:</strong></p>
          <p>
            {{
              paymentInfo.date
                ? new Date(paymentInfo.date).toLocaleDateString()
                : "Geen datum beschikbaar"
            }}
          </p>
        </div>
        <div class="payment-row">
          <p><strong>Type:</strong></p>
          <p>{{ paymentInfo.type }}</p>
        </div>
        <div class="payment-row">
          <p><strong>Aanmaakdatum:</strong></p>
          <p>{{ new Date(paymentInfo.createDate).toLocaleString() }}</p>
        </div>
      </div>
      <div class="payment-history">
        <h5><b> Betaalgeschiedenis</b></h5>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Betaald Bedrag</th>
              <th>Datum</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="history in paymentInfo.paymentHistories"
              :key="history.id"
            >
              <td>{{ history.id }}</td>
              <td>€{{ history.amountpaid }}</td>
              <td>{{ new Date(history.createDate).toLocaleString() }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <v-col cols="4" style="display: flex">
        <Betalingen @payment-updated="getPaymentInfo" />
        <BetalingfactuurMaken />
      </v-col>

      <button class="button" @click="info">Instructie</button>
      <button class="button1" @click="goBack()">Ga terug</button>
      <button
        style="margin-left: 10px !important"
        class="button1"
        @click="deletePayment()"
      >
        Verwijderen
      </button>
    </div>
  </div>
</template>

<script>
import Betalingen from "./Betalingen.vue";
import BetalingfactuurMaken from "./BetalingfactuurMaken.vue";
import BetalingStatus from "./BetalingStatus.vue";
import apiPaymentinout from "../api/apiPaymentinout";
export default {
  components: {
    BetalingStatus,
    BetalingfactuurMaken,
    Betalingen,
  },
  data() {
    return {
      paymentInfo: {},
    };
  },
  mounted() {
    this.getPaymentInfo();
  },
  computed: {
    outstandingAmount() {
      if (!this.paymentInfo.amount || !this.paymentInfo.paymentHistories) {
        return 0;
      }
      const totalPaid = this.paymentInfo.paymentHistories.reduce(
        (total, history) => total + parseFloat(history.amountpaid),
        0
      );
      return parseFloat(this.paymentInfo.amount) - totalPaid;
    },
  },
  methods: {
    getPaymentInfo() {
      apiPaymentinout.getSinglePayment(this.$route.params.id).then((data) => {
        this.paymentInfo = data;
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    info() {
      this.$swal(
        "Hier kunt u de betaalinformatie bekijken en de geschiedenis van de betalingen"
      );
    },

    async deletePayment() {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze betalingen wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        const id = this.$route.params.id;
        await apiPaymentinout.deletePayment(id);
        this.$router.push("Betalingenuit");
        this.$swal.fire(
          "Verwijderd",
          "De betaaltemplate is succesvol verwijderd",
          "success"
        );
      }
    },
  },
};
</script>

<style scoped>
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
}

.payment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  padding: 20px;
}

.payment-card {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
}

.payment-header {
  background: #007bff;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.payment-header h3 {
  margin: 0;
  font-size: 1.8em;
}

.payment-status {
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 1em;
  font-weight: bold;
}

.payment-status.paid {
  background: #28a745;
  color: white;
}

.payment-status.unpaid {
  background: #dc3545;
  color: white;
}

.payment-body {
  padding: 20px;
}

.payment-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #f1f1f1;
}

.payment-row p {
  margin: 0;
  font-size: 1.1em;
  line-height: 1.4;
}

.payment-history {
  padding: 20px;
  border-top: 1px solid #ddd;
}

.payment-history h4 {
  margin-bottom: 10px;
}

.payment-history table {
  width: 100%;
  border-collapse: collapse;
}

.payment-history th,
.payment-history td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.payment-history th {
  background-color: #f2f2f2;
}

.button {
  color: white;
  background-color: #007bff;
  border: none;
  padding: 15px 20px;
  text-align: center;
  font-size: 1em;
  margin: 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.button1 {
  color: white;
  background-color: red;
  border: none;
  padding: 15px 20px;
  text-align: center;
  font-size: 1em;

  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}
</style>
