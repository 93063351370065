<template>
  <v-container>
    <ProductTour />

    <div data-v-step="1">
      <v-data-table
        :headers="headers"
        :items="orders"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Orders</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>

        <template v-slot:item.transactions="{ item }">
          <v-list dense>
            <v-list-item
              v-for="(transaction, index) in item.transactions"
              :key="index"
            >
              <v-list-item-content>
                <v-list-item-title>{{
                  transaction.itemTitle
                }}</v-list-item-title>
                <v-list-item-subtitle>
                  ID: {{ transaction.itemId }} | Quantity:
                  {{ transaction.quantityPurchased }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>

        <template v-slot:item.changestatus="{ item }">
          <v-select
            :items="orderstatussen"
            v-model="item.status"
            @change="updateOrderStatus(item)"
            dense
            outlined
          ></v-select>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import apiEbay from "../api/apiEbay";
import ProductTour from "../tour/ProductTour.vue";

export default {
  components: {
    ProductTour,
  },
  data() {
    return {
      orderstatussen: [
        "Nog niet verwerkt",
        "Verwerkt",
        "Verzonden",
        "vervallen",
        "Verkocht",
        "overig",
      ],
      orders: [],
      headers: [
        { text: "Order ID", value: "orderId" },
        { text: "Koper", value: "buyerUserID" },
        { text: "Volledigenaam", value: "shippingName" },
        { text: "Shipping Address", value: "shippingStreet" },
        { text: "Stad", value: "shippingCity" },
        { text: "Staat", value: "shippingState" },
        { text: "Bestelling", value: "transactions" },
        { text: "Order Status", value: "status" },
        { text: "Aangemaakt op ", value: "createdTime" },
        { text: "Status wijzigen ", value: "changestatus" },
      ],
    };
  },

  async mounted() {
    apiEbay.getEbayOrders().then((data) => {
      this.orders = data;
    });
  },

  methods: {
    async updateOrderStatus(order) {
      try {
        const payload = {
          orderId: order.orderId,
          status: order.status,
        };
        await apiEbay.updateOrderStatus(payload);
        this.$toast.success("Order status updated successfully");
      } catch (error) {
        console.error("Error updating order status:", error);
        this.$toast.error("Error updating order status");
      }
    },
  },
};
</script>

<style scoped>
.v-data-table {
  max-width: 100%;
}
</style>
