
<template>
    <Sales />
  </template>
  
  <script>
  import Sales from "@/components/sales/Sales";
  export default {
    data() {
      return {
        data: [],
      };
    },
    components: {
        Sales,
    },
    metaInfo: {
   title: "Koopjes vd dag",
      htmlAttrs: {
        lang: "EN",
        amp: true,
      },
    },
  };
  </script>